import React,
{
  useState,
} from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import ButtonPublish from '../../../components/Buttons/ButtonPublish';

import {
  Content,
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';
import { ContentPadder, FooterDescription } from '../../../styles/styled.layout';

// import { Container } from './styles';



const SettingWalletNoitagScreen: React.FC = () => {
  const [activeNoitag, setActiveNoitag] = useState<boolean>(false);
  const [activePinNoitag, setActivePinNoitag] = useState<boolean>(false);
  const [useCashToPay, setUseCashToPay] = useState<boolean>(false);
  const [useCreditToPay, setUseCreditToPay] = useState<boolean>(false);


  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            Configurar carteira / Noitag
          </Title>
        }
      />



      <Content>
        <ContentPadder>

          <ButtonPublish
            selected={activeNoitag}
            title={'Ativar Noitag'}
            onPress={() => {
              setActiveNoitag(!activeNoitag);
            }}
          />


          <ButtonPublish
            selected={activePinNoitag}
            title={'Ativar PIN ao fazer transação'}
            onPress={() => {
              setActivePinNoitag(!activePinNoitag);
            }}
          />

          <FooterDescription>
            Você só tem 3 chances de erro, depois disso a Noitag será bloqueada
          </FooterDescription>



          <ButtonPublish
            selected={useCashToPay}
            title={'Usar saldo ao pagar'}
            onPress={() => {
              setUseCashToPay(!useCashToPay);
            }}
          />


          <ButtonPublish
            selected={useCreditToPay}
            title={'Usar cartões ao pagar'}
            onPress={() => {
              setUseCreditToPay(!useCreditToPay);
            }}
          />

        </ContentPadder>
      </Content>

    </Screen>

  );
};



export default SettingWalletNoitagScreen;
