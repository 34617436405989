import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  SwipeableDrawer,
} from '@material-ui/core';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_DRAWER_ACTION,
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_LOGOUT_STATE,
} from '@noitada/redux-config/reducers/user.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  IconExit,
  IconFavoriteOn,
  IconHelp,
  IconHome,
  IconList,
  IconNoitag,
  IconOrderSheet,
  IconProfile,
  IconReserves,
  IconTicket,
  IconVerified,
  IconWaitList,
  IconWallet,
} from '../../components/Icons';

import RibbonLevel from '../../components/Level/RibbonLevel';
import DrawerTitle from '../../components/Titles/DrawerTitle';

import NameRoutes from '../names';

import {
  MenuContainer,
  MenuHeaderContainer,
  MenuImageContent,
  MenuProfileImage,
  MenuImageHeaderView,
  MenuHeaderInfo,
  MenuVerifiedContent,
  MenuNameText,
  IconVerifiedView,
  MenuSeparator,
  MenuInfoNameText,
  MenuListContent,
  MenuItemList,
  ItemMenuText,
  ItemMenuIcon,
  ItemMenuContainer,
} from './styled';



export interface IProps {
  open: boolean;
  onOpen?: any;
  onClose?: any;
}



const DrawerMenu: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const selectedIndex = useSelector((state: RootState) => state.app.menuSelected);

  const user = useSelector((state: RootState) => state.user.actualUser);


  const selectedColor = Colors.white;
  const normalColor = Colors.menuItem;
  const drawerTitleColor = Colors.accent;



  const routesHighlights = [
    {
      index: EDrawerMenu.HOME,
      icon: (
        <IconHome
          size={24}
          color={selectedIndex === EDrawerMenu.HOME ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.HOME),
      router: NameRoutes.GridEventsScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.FAVORITES,
      icon: (
        <IconFavoriteOn
          size={24}
          color={selectedIndex === EDrawerMenu.FAVORITES ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.FAVORITES),
      router: NameRoutes.FavoritesScreen,
      visible: true,
      enable: true,
    },
  ];


  const routesProfile = [
    {
      index: EDrawerMenu.PROFILE,
      icon: (
        <IconProfile
          size={24}
          color={selectedIndex === EDrawerMenu.PROFILE ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.PROFILE),
      router: NameRoutes.ProfileScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.WALLET,
      icon: (
        <IconWallet
          size={24}
          color={selectedIndex === EDrawerMenu.WALLET ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.WALLET),
      router: NameRoutes.WalletScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.NOITAG,
      icon: (
        <IconNoitag
          size={24}
          color={selectedIndex === EDrawerMenu.NOITAG ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.NOITAG),
      router: NameRoutes.NoitagScreen,
      visible: true,
      enable: true,
    },
  ];


  const routesSales = [
    {
      index: EDrawerMenu.TICKETS,
      icon: (
        <IconTicket
          size={24}
          color={selectedIndex === EDrawerMenu.TICKETS ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.TICKETS),
      router: NameRoutes.UserTicketsScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.LISTS_PARTICIPATE,
      icon: (
        <IconList
          size={24}
          color={selectedIndex === EDrawerMenu.LISTS_PARTICIPATE ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.LIST_PARTICIPATE),
      router: NameRoutes.UserListsScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.WAIT_LISTS,
      icon: (
        <IconWaitList
          size={24}
          color={selectedIndex === EDrawerMenu.WAIT_LISTS ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.WAIT_LISTS),
      router: NameRoutes.WaitListFutureScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.REQUESTS,
      icon: (
        <IconOrderSheet
          size={24}
          color={selectedIndex === EDrawerMenu.REQUESTS ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.REQUESTS),
      router: NameRoutes.UserOrdersScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.RESERVES,
      icon: (
        <IconReserves
          size={24}
          color={selectedIndex === EDrawerMenu.RESERVES ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.RESERVES),
      router: NameRoutes.UserReservesScreen,
      visible: true,
      enable: true,
    },
  ];


  const routesOptions = [
    {
      index: EDrawerMenu.HELP,
      icon: (
        <IconHelp
          size={24}
          color={selectedIndex === EDrawerMenu.HELP ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.HELP),
      router: NameRoutes.HelpFaqScreen,
      visible: true,
      enable: true,
    },
    {
      index: EDrawerMenu.EXIT,
      icon: (
        <IconExit
          size={24}
          color={selectedIndex === EDrawerMenu.EXIT ? selectedColor : normalColor}
        />
      ),
      name: translate(TranslateConfig.EXIT),
      router: NameRoutes.AccessScreen,
      visible: true,
      enable: true,
    },
  ];



  const handleListItemClick = (index: EDrawerMenu) => {
    dispatch({ type: APP_DRAWER_ACTION, payload: false });
    if (index !== EDrawerMenu.EXIT) {
      dispatch({ type: APP_MENU_SELECTED_ACTION, payload: index });
    }
  };


  function pushButtonCLick(route: any) {
    if (route === NameRoutes.AccessScreen) {
      dispatch({ type: USER_LOGOUT_STATE, payload: true });
      history.replace(NameRoutes.GridEventsScreen);
    }
    else {
      history.push(route);
    }
  }


  function renderItem(item: any) {
    if (!item?.visible) {
      return;
    }


    return (

      <ItemMenuContainer
        key={item.index}
        id={item.index}
        disabled={!item.enable}
        selected={selectedIndex === item.index}
        onClick={() => {
          if (item.enable) {
            pushButtonCLick(item.router);
            handleListItemClick(item.index);
          }
        }}>

        <ItemMenuIcon>
          {item.icon}
        </ItemMenuIcon>


        <ItemMenuText
          color={selectedIndex === item?.index ? selectedColor : normalColor}>
          {item.name}
        </ItemMenuText>

      </ItemMenuContainer>

    );
  };


  const renderFilteredModule = (sectionTitle: string, routes: Array<any>) => {
    if (routes.every((currentValue) => !currentValue?.visible)) {
      return;
    }


    return (

      <MenuItemList>

        <DrawerTitle
          title={sectionTitle.toUpperCase()}
          color={drawerTitleColor}
        />


        {routes?.map((item) => renderItem(item))}

      </MenuItemList>

    );
  };



  return (

    <SwipeableDrawer
      anchor={'left'}
      disableSwipeToOpen={true}
      open={props.open}
      onOpen={props.onOpen}
      onClose={props.onClose}>

      <MenuContainer>

        <MenuHeaderContainer
          colors={[Colors.profileColorTop, Colors.profileColorBottom]}>

          <MenuImageContent>

            <MenuProfileImage
              alt={'ProfileAvatar'}
              src={validateString(user?.picture)
                ? user?.picture
                : Images.placeholderAvatarCompany
              }
            />


            <MenuImageHeaderView>
              <RibbonLevel
                level={user?.userInfo?.level}
              />
            </MenuImageHeaderView>
          </MenuImageContent>



          <MenuHeaderInfo>

            <MenuVerifiedContent>
              <MenuNameText>
                {user?.username
                  ? `@${user?.username}`
                  : '-'
                }
              </MenuNameText>

              {user?.userInfo?.verified && (
                <IconVerifiedView>
                  <IconVerified
                    color={Colors.accent}
                    size={16}
                  />
                </IconVerifiedView>
              )}
            </MenuVerifiedContent>


            <MenuSeparator
              height={1.5}
              width={175}
              color={Colors.white}
            />


            <MenuInfoNameText>
              {user?.publicName}
            </MenuInfoNameText>

          </MenuHeaderInfo>

        </MenuHeaderContainer>



        <MenuListContent>

          {renderFilteredModule(translate(TranslateConfig.HIGHLIGHTS), routesHighlights)}


          {renderFilteredModule(translate(TranslateConfig.DATAS), routesProfile)}


          {renderFilteredModule(translate(TranslateConfig.SHOPS), routesSales)}


          {renderFilteredModule(translate(TranslateConfig.OPTIONS), routesOptions)}

        </MenuListContent>

      </MenuContainer>

    </SwipeableDrawer>

  );
};



export default DrawerMenu;
