import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardListContainer = styled.a`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  width: 100%;
  min-height: 190px;
  max-height: 190px;
  margin-top: 7px;
  margin-bottom: 7px;
  border-radius: ${Sizes.cardRadius}px;
`;


export const CardListStub = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 180px;
  min-width: 180px;
  max-width: 180px;
  height: 100%;
  padding: 10px;
  background-color: ${Colors.primaryDark};
`;

export const CardListTopStub = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 5px;
`;

export const CardListTopNumInvitation = styled.p`
  font-size: 12px;
  color: ${Colors.grayLight};
`;

export const CardListTopInvitationSpan = styled.span`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
`;


export const CardListTopAdmit = styled.p`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 14px;
  line-height: 17px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;



export const CardListTopLineInvite = styled.div`
  display: flex;
  background-color: ${Colors.accent};
  width: 40px;
  height: 2px;
  min-height: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
`;



export const CardListTopInfoCompany = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex: 1;
`;

export const CardListTopImageRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

export const CardListTopInfoColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const CardListTopImage = styled.img`
  width: 38px;
  height: 38px;
  margin-right: 8px;
  border-radius: 5px;
  object-fit: cover;
`;

export const CardListTopNumber = styled.p`
  margin-top: 4px;
  color: ${Colors.white};
  font-size: 15px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

export const CardListTopInvite = styled.p`
  margin-top: 2px;
  font-size: 12px;
  color: ${Colors.grayLight};
  letter-spacing: 0px;
  font-weight: bold;
`;



// List Separator

export const ViewDotsContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -20px;
  margin-right: -5px;
  width: 20px;
  height: 25px;
  transform: rotate(90deg);
  z-index: 5;
`;



export const CardListCheckContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  position: relative;
  padding: 10px;
  height: 100%;
  background-color: ${Colors.cardBackground};
`;

export const CardListCheckTitle = styled.h4`
  color: ${Colors.white};
  font-size: 21px;
  font-weight: 900;
  line-height: 28px;
`;


export const CardListChecInfoView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 10px;
`;

export const CardListDateSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 65%;
  padding-bottom: 10px;
`;

export const CardListHourSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 35%;
  padding-bottom: 10px;
`;

export const CardListCheckNameSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardListChecInfoText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;
