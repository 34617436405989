import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatExtenseDate,
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  stringCapitalize,
} from '@noitada/shared/utils/string.utils';

import {
  FormatMoney,
} from '../../../config/mask.config';

import PaymentMethods from '../PaymentMethods';

import {
  CardPayInfoContainer,
  CardPayItemContent,
  CardPayItemIDText,
  DetailsPaymentsHeader,
  DetailsPaymentsLabel,
  CardPayItemText,
  CardPayUserText,
  CardTypeTaxView,
  CardTypeTaxText,
} from './styled';



export interface IProps {
  id?: string;

  realized?: string;
  date?: string;
  payUsername?: string;

  modality?: 'ONLINE' | 'IN_PLACE';

  delivery?: number;
  taxsValue?: number;
  discount?: number;
  tip?: number;

  subTotal?: number;
  total?: number;

  method?: string;
  parcels?: number;
  last4?: string;
  cardBrand?: string;
}



const CardPayInfo: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  // Função para formatar a string da data realizada
  function getRealizedDateTimeString() {
    const realized = props?.realized;

    if (!realized) {
      return 'Data não disponível';
    }

    const time = FormatIsoDateWithPatternDayjs(realized, 'HH:mm'); // Corrigido para 'HH:mm'

    return `Realizado às ${time}`;
  }


  // Função para formatar a string da data do pagamento
  function getPaymentDateTimeString() {
    const date = props?.date;

    if (!date) {
      return 'Data não disponível';
    }

    const time = FormatIsoDateWithPatternDayjs(date, 'HH:mm'); // Corrigido para 'HH:mm'

    return `Pago às ${time}`;
  }


  // Função para formatar a data completa (dia da semana e data)
  function getFullDateString(date: string | undefined) {
    if (!date) {
      return 'Data não disponível';
    }

    const dayOfWeek = FormatIsoDateWithPatternDayjs(date, 'dddd');
    const extendedDate = FormatExtenseDate(date);

    return stringCapitalize(`${dayOfWeek}, ${extendedDate}`);
  }



  return (

    <CardPayInfoContainer>

      <CardPayItemContent>
        <CardPayItemIDText>
          ID: {props.id}
        </CardPayItemIDText>

        <CardPayItemText>
          {getRealizedDateTimeString()}
        </CardPayItemText>

        <CardPayItemText>
          {getFullDateString(props?.realized)}
        </CardPayItemText>
      </CardPayItemContent>



      <CardPayItemContent>
        <CardPayItemText>
          {getPaymentDateTimeString()}
        </CardPayItemText>

        <CardPayItemText>
          {getFullDateString(props?.date)}
        </CardPayItemText>
      </CardPayItemContent>



      {props.payUsername && (
        <CardPayItemContent>
          <CardPayUserText>
            Pago por
          </CardPayUserText>

          <CardPayItemIDText>
            @{props.payUsername}
          </CardPayItemIDText>
        </CardPayItemContent>
      )}



      {props.modality && props.modality === 'ONLINE' && (
        <DetailsPaymentsHeader>
          <DetailsPaymentsLabel>
            {translate(TranslateConfig.ACTION_PAY_APP)}
          </DetailsPaymentsLabel>
        </DetailsPaymentsHeader>
      )}


      {props.modality && props.modality === 'IN_PLACE' && (
        <DetailsPaymentsHeader>
          <DetailsPaymentsLabel>
            {translate(TranslateConfig.ACTION_PAY_PRESENTIAL)}
          </DetailsPaymentsLabel>
        </DetailsPaymentsHeader>
      )}



      <CardPayItemContent>

        {/* FIXME */}
        {false && (
          <CardTypeTaxView>
            <CardTypeTaxText>
              {translate(TranslateConfig.DELIVERY)}
            </CardTypeTaxText>

            <CardTypeTaxText>
              {FormatMoney(props?.delivery)}
            </CardTypeTaxText>
          </CardTypeTaxView>
        )}



        {/* FIXME */}
        {false && (
          <CardTypeTaxView>
            <CardTypeTaxText>
              {translate(TranslateConfig.TAX_DELIVERY)}
            </CardTypeTaxText>

            <CardTypeTaxText>
              {FormatMoney(props?.taxsValue)}
            </CardTypeTaxText>
          </CardTypeTaxView>
        )}



        <CardTypeTaxView>
          <CardTypeTaxText>
            {translate(TranslateConfig.TAXES)}
          </CardTypeTaxText>

          <CardTypeTaxText>
            {FormatMoney(props?.taxsValue)}
          </CardTypeTaxText>
        </CardTypeTaxView>



        {Boolean(props?.discount) && (
          <CardTypeTaxView>
            <CardTypeTaxText>
              {translate(TranslateConfig.DISCOUNT)}
            </CardTypeTaxText>

            <CardTypeTaxText color="#069c4e">
              {FormatMoney(props.discount)}
            </CardTypeTaxText>
          </CardTypeTaxView>
        )}



        {Boolean(props?.tip) && (
          <CardTypeTaxView>
            <CardTypeTaxText>
              {translate(TranslateConfig.TIP)}
            </CardTypeTaxText>

            <CardTypeTaxText color="#110bb8">
              {FormatMoney(props.tip)}
            </CardTypeTaxText>
          </CardTypeTaxView>
        )}



        <CardTypeTaxView>
          <CardTypeTaxText>
            {translate(TranslateConfig.SUBTOTAL)}
          </CardTypeTaxText>

          <CardTypeTaxText>
            {FormatMoney(props?.subTotal)}
          </CardTypeTaxText>
        </CardTypeTaxView>



        <CardTypeTaxView>
          <CardTypeTaxText
            color={Colors.primary}>
            {translate(TranslateConfig.TOTAL_PAY)}
          </CardTypeTaxText>

          <CardTypeTaxText
            color={Colors.primary}>
            {FormatMoney(props?.total)}
          </CardTypeTaxText>
        </CardTypeTaxView>

      </CardPayItemContent>



      <CardPayItemContent>

        <PaymentMethods
          method={props.method}
          parcels={props.parcels}
          last4={props.last4 || '****'}
          cardBrand={props.cardBrand}
        />

      </CardPayItemContent>

    </CardPayInfoContainer>

  );
};



export default CardPayInfo;
