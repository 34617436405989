import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadderHorizontal,
} from '../../../../../../styles/styled.layout';



export const EventImageView = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;



export const BannerImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  height: 180px;
  width: 100%;
`;

export const BannerBackground = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(3px);
`;

export const BannerImageOverlay = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: ${Colors.cardOverlay};
`;

export const EventImageCheckout = styled.img`
  position: absolute;
  left: 20px;
  width: 140px;
  height: 140px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const EventNameCheckout = styled.p`
  position: absolute;
  top: 20px;
  right: 20px;
  color: ${Colors.accent};
  font-size: 16px;
  font-weight: bold;
  text-align: right;
`;



export const PriceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-self: flex-start;
  position: absolute;
  right: 0;
  bottom: -20px;
  padding-top: 5px;
  padding-left: 20px;
  padding-right: 15px;
  padding-bottom: 5px;
  background-color: ${Colors.primary};
  border-top-left-radius: ${Sizes.borderModalsRadius + 15}px;
`;

export const PriceText = styled.p`
  color: ${Colors.white};
  font-size: 24px;
  font-weight: 700;
  text-align: right;
`;

export const ToPayText = styled.p`
  color: ${Colors.white};
  font-size: 12px;
  font-weight: 400;
  text-align: right;
`;


export const EventResumePadder = styled(ContentPadderHorizontal)`
  padding-bottom: 16px;
`;


export const EventInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 35px;
`;

export const EventNameText = styled.p`
  color: ${Colors.white};
  font-size: 19px;
  line-height: 24px;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`;

export const EventPlaceText = styled.p`
  color: ${Colors.textDescription};
  font-size: 14px;
  margin-top: 10px;
  
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;



export const TicketInfoView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const TicketItemContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 47px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const TicketItemView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const TickeItemNameText = styled.p`
  color: ${Colors.white};
  font-size: 17px;
  font-weight: 700;
`;

export const TickeItemTypeLotText = styled.p`
  margin-top: 2px;
  color: ${Colors.grayLight};
  font-size: 14px;
  font-weight: 700;
`;

export const TickeItemForUserText = styled.p`
  margin-top: 2px;
  color: ${Colors.gray};
  font-size: 12px;
  font-weight: 700;
`;

export const TickeItemPriceText = styled.p`
  color: ${Colors.white};
  font-size: 18px;
`;



export const TableContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
`;

export const TableView = styled.div`
  display: flex;
  flex-direction: column;
  width: 265px;
`;

export const TableColComponent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  margin-top: 3px;
  margin-bottom: 3px;
`;


export const InfoTicketText = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  font-size: 14px;
`;

export const InfoTotalText = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  font-size: 20px;
  font-weight: bold;
`;



export const PricesTicketText = styled.p`
  color: ${Colors.white};
  text-align: right;
  font-size: 14px;
`;

export const TotalContentView = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-between;
  margin-top: 6px;
  padding-top: 5px;
  border-top-color: ${Colors.menuLine};
  border-top-style: solid;
  border-top-width: 1.5px;
`;

export const PricesView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const PricesTotalText = styled.p`
  color: ${Colors.white};
  text-align: right;
  font-size: 20px;
  font-weight: bold;
`;

export const PricesParcelText = styled.p`
  margin-top: 4px;
  color: ${Colors.textDescription};
  font-size: 12px;
  text-align: right;
`;



export const TableDescriptionTicket = styled.p`
  margin-top: 20px;
  color: ${Colors.gray};
  font-size: 14px;
  text-align: center;
`;
