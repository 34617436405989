import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EEventType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  Shapes,
} from '../../Composh/web';

import {
  IconTypeParty,
  IconTypeNightClub,
  IconInformation,
  IconEventStart,
  IconWatchStart,
} from '../../Icons';

import {
  CardTicketContainer,
  UserTicketEventImage,
  UserPartView,
  PlacePartView,
  HeaderUserInfo,
  TextDate,
  TextTypeTicket,
  UserInfoContent,
  TextEventName,
  PlaceInfoContent,
  TextHour,
  TextObs,
  CardOverlay,
  TextIdTicket,
  TextIdTitle,
  DateInfoContent,
  ImagePlace,
  EventInfoView,
  EventPlaceView,
  TextPlace,
  TextIconContent,
  IconStyle,
  IconView,
  EventPlaceInfo,
  ViewDotsContent,
} from './styled';



export interface IProps {
  date: string;
  companyImage: string;
  companyName: string;
  ticketId: string;
  ticketName?: string;
  eventImage?: string;
  eventName: string;
  eventType?: EEventType;
  city: string;
  state: string;
  onPress?: any;
}



const CardTicket: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const dateEvent = FormatIsoDateWithPatternDayjs(props.date, 'DD/MM/YYYY');
  const hourEvent = FormatIsoDateWithPatternDayjs(props.date, 'HH:mm');



  function iconTypeEvent(eventType: EEventType) {
    const iconSize = 32;
    const iconColors = Colors.textDescription;

    switch (eventType) {
      case EEventType.PARTY:
        return (
          <IconTypeParty
            size={iconSize}
            color={iconColors}
          />
        );

      case EEventType.NIGHT_PARTY:
        return (
          <IconTypeNightClub
            size={iconSize}
            color={iconColors}
          />
        );

      default:
        return (
          <IconInformation
            size={iconSize}
            color={iconColors}
          />
        );
    }
  }



  return (

    <CardTicketContainer
      // activeOpacity={1}
      // onLongPress={props.onLongPress}
      // delayLongPress={500}
      onClick={props.onPress}>

      <UserTicketEventImage
        src={
          props.eventImage === null || props.eventImage === ''
            ? Images.placeholderEvent
            : props.eventImage
        }
      />


      <CardOverlay />



      <UserPartView>

        <HeaderUserInfo>

          <UserInfoContent>

            <TextIconContent>
              <IconStyle>
                <IconEventStart
                  color={Colors.accent}
                  size={15}
                />
              </IconStyle>

              <TextDate>
                {dateEvent || '-'}
              </TextDate>
            </TextIconContent>


            <TextIconContent>
              <IconStyle>
                <IconWatchStart
                  color={Colors.accent}
                  size={15}
                />
              </IconStyle>

              <TextHour>
                {hourEvent || '-'}
              </TextHour>
            </TextIconContent>

          </UserInfoContent>


          <DateInfoContent>
            <TextTypeTicket>
              {props.ticketName || '-'}
            </TextTypeTicket>
          </DateInfoContent>

        </HeaderUserInfo>



        <EventInfoView>
          <TextEventName>
            {props.eventName || '-'}
          </TextEventName>
        </EventInfoView>


        <EventPlaceView>
          <IconView>
            {iconTypeEvent(props.eventType as EEventType)}
          </IconView>

          <EventPlaceInfo>
            <TextPlace>
              {props.companyName || '-'}
            </TextPlace>

            <TextPlace>
              {props.city || '-'}, {props.state || '-'}
            </TextPlace>
          </EventPlaceInfo>
        </EventPlaceView>

      </UserPartView>



      <ViewDotsContent>
        <Shapes.LineTear
          color={Colors.actionBackground}
          tearSize={8}
          width={215}
        />
      </ViewDotsContent>



      <PlacePartView>

        <PlaceInfoContent>
          <TextIdTitle>
            {translate(TranslateConfig.ID_TICKET)}
          </TextIdTitle>

          <TextIdTicket>
            #{props.ticketId || '-'}
          </TextIdTicket>

          <TextObs>
            {translate(TranslateConfig.ACTION_MORE_DETAILS)}
          </TextObs>
        </PlaceInfoContent>


        <ImagePlace
          src={
            props.companyImage === null || props.companyImage === ''
              ? Images.placeholderAvatarUser
              : props.companyImage
          }
        />

      </PlacePartView>

    </CardTicketContainer>

  );
};



export default CardTicket;
