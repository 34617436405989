import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  UserGetReservations,
} from '@noitada/axios-config/endpoints/reserves.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import CardUserReserves from '../../../../../components/Cards/CardReserves/CardUserReserves';
import EmptyContent from '../../../../../components/Empty';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../navigation/names';

import {
  ListReserveContentFlex,
} from './styled';



const TabReserveUpcomming: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const [loading, setLoading] = useState<boolean>(false);

  const [reservesArray, setReservesArray] = useState<Array<any>>([]);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function loadReserves() {
    try {
      setLoading(true);

      const response = await UserGetReservations('FUTURE');
      if (response) {
        setReservesArray(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    const week = new Intl.DateTimeFormat('pt-BR', { weekday: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(item?.reservationAt)).slice(0, -1);
    const Capitalize_week = week[0].toUpperCase() + week.slice(1);
    const full_day = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long', timeZone: 'America/Sao_Paulo' }).format(new Date(item?.reservationAt));
    const time = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(item?.reservationAt));


    return (

      <CardUserReserves
        key={index}
        id={`${item?.id || index}`}
        date={`${Capitalize_week}, ${full_day}`}
        time={`${time}`}
        environment={validateString(item?.reservation?.environment?.environment) || '-'}
        companyPicture={item?.reservation?.company?.picture}
        companyName={item?.reservation?.company?.idShort}
        peopleNumber={item?.peopleNumber}
        onPress={() => {
          history.push({
            pathname: NameRoutes.DetailsReserveScreen.replace(
              ':reserve_id',
              item?.id,
            ),
            state: {
              item,
            },
          });
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noReserveUpcoming}
        title={translate(TranslateConfig.EMPTY_RESERVE_USER)}
      />

    );
  }



  useEffect(() => {
    loadReserves();
  }, []);



  return (

    <ListReserveContentFlex
      loading={loading}
      data={reservesArray}
      renderItem={renderItem}
      listLoadingComponent={renderLoading()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabReserveUpcomming;
