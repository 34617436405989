import styled from 'styled-components';

import {
  SubTitle,
} from '../../Composh/web';



export const LoadingSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const LoadingSubtitleText = styled(SubTitle)`
  margin-right: 5px;
`;
