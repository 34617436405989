import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Divider,
  Items,
} from '../../../components/Composh/web';



export const AboutInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

export const AboutInfoDetailsView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const AboutMessageContent = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const AboutMessageItem = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-height: 45px;
  margin-left: 5px;
  margin-right: 5px;
`;

export const AboutMessageItemText = styled.p`
  margin-left: 8px;
  color: ${Colors.white};
  font-size: 16px;
`;


export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  padding-left: 16px;
  padding-right: 16px;
`;

export const AboutLogo = styled.img`
  height: 80px;
  width: 80px;
  margin-top: 5px;
  margin-bottom: 10px;
  object-fit: contain;
`;

export const AboutVersion = styled.p`
  margin-top: 5px;
  color: ${Colors.textTitle};
  font-size: 22px;
  font-weight: bold;
`;

export const AboutAppSlogan = styled.p`
  margin-top: 7px;
  margin-bottom: 8px;
  color: ${Colors.textDescription};
  font-size: 13px;
`;

export const DividerAbout = styled(Divider)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const AboutSocialContent = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const AboutSocialGridStyled = styled(Items.Grid)`
  min-width: 89px;
  margin-top: 3px;
  margin-bottom: 3px;
`;

export const AboutFooterContent = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 120px;
  max-height: 120px;
  margin-top: 10px;
  background-color: ${Colors.black};
`;

export const AboutImageFooter = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  object-fit: cover;
`;

export const AboutCenterFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 5px;
  z-index: 1;
`;

export const AboutFooterImage = styled.img`
  width: 230px;
  height: 65px;
  object-fit: contain;
`;

export const AboutFooterSlogan = styled.p`
  margin-top: -3px;
  color: ${Colors.white};
  font-size: 19px;
  font-style: italic;
  text-align: center;
  white-space: break-spaces;
`;
