import styled,
{
  css,
} from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  size?: number;
  borderRadius?: number;
}



export const CardFlagAspectRatio = css`
  width: ${(props: IProps) => props.size || 58}px;
  aspect-ratio: 16 / 9;
`;

export const CardFlagHeight = css`
  width: 58px;
  height: 37px;
`;


export const CardFlagImage = styled.img`
  ${(props: IProps) => !props.size && CardFlagAspectRatio}
  ${(props: IProps) => props.size && CardFlagAspectRatio}

  object-fit: contain;
  border-radius: ${(props: IProps) => props.borderRadius || Sizes.cardRadius}px;
`;
