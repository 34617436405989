import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const InstallmentItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: -0.5px;
  margin-bottom: -0.5px;
  padding-top: 10px;
  padding-left: 6px;
  padding-right: 6px;
  padding-bottom: 10px;
  border-top-color: ${Colors.toolbarLine};
  border-top-style: solid;
  border-top-width: 0.5px;
  border-bottom-color: ${Colors.toolbarLine};
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
`;



export const InstallmentItemView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const InstallmentItemTitle = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
`;

export const InstallmentItemSubtitle = styled.p`
  color: ${Colors.gray};
  font-size: 13px;
`;

export const InstallmentItemClickText = styled.p`
  margin-top: 5px;
  color: ${Colors.grayLight};
  font-size: 13px;
`;



export const InstallmentPriceView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const InstallmentItemPrice = styled.p`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
  text-align: right;
`;

export const InstallmentItemMonth = styled.p`
  color: ${Colors.grayLight};
  font-size: 13px;
  text-align: right;
`;
