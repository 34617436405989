import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';

import {
  Divider,
  LinearGradient,
} from '../../components/Composh/web';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  color?: string;
  backgroundColor?: string;
}



export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  height: 100%;
  background-color: ${Colors.appBackground};
`;

export const MenuHeaderContainer = styled(LinearGradient)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  padding-left: 18px;
  padding-right: 20px;
  padding-bottom: 20px;
`;

export const MenuImageContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
`;


export const DrawerImageStyle = css`
  background-color: ${Colors.profileToolbar};
  border-color: ${Colors.menuLine};
  border-width: 1px;
`;

export const MenuProfileImage = styled.img`
  ${DrawerImageStyle};
  width: ${Sizes.imageDrawer}px;
  height: ${Sizes.imageDrawer}px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const MenuImageHeaderView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-end;
  width: ${Sizes.imageDrawer}px;
  height: ${Sizes.imageDrawer}px;
`;



export const MenuHeaderInfo = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 25px;
  margin-bottom: 5px;
`;

export const MenuVerifiedContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 7px;
`;

export const MenuNameText = styled.h2`
  color: ${Colors.white};
  font-size: 18px;
`;


export const IconVerifiedView = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  padding-bottom: 1px;
`;


export const MenuSeparator = styled(Divider)`
  margin-top: 5px;
  margin-bottom: 5px;
`;

export const MenuInfoNameText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-style: italic;
`;

export const MenuListContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-bottom: 8px;
  background-color: ${Colors.menuBackground};
`;



export const MenuItemList = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;



export const ItemMenuText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 14px;
  transition: color 0.25s ease;
  z-index: 5;
`;

export const ItemMenuIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${Sizes.menuContent}px !important;
  width: ${Sizes.menuContent}px !important;
  min-width: ${Sizes.menuContent}px !important;
  max-width: ${Sizes.menuContent}px !important;
  margin-right: 6px;
  z-index: 5;
`;


export const ItemMenuEffect = css`
  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    height: 100%;
    width: 0;
    background-color: ${Colors.secondary};
    transition: width 0.2s ease-in;
  }

  :hover {
    ${ItemMenuIcon} { 
      svg {
        fill: ${Colors.white} !important;
        color: ${Colors.white} !important;
        transition: color 0.25s ease;
        z-index: 5;
      }
    }
    
    ${ItemMenuText} {
      color: ${Colors.white} !important;
      font-weight: 700;
    }

    &:before {
      width: 100%;
      background-color: ${Colors.secondary};
    }
  }
`;

export const ItemMenuContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  width: 100%;
  height: 48px;
  padding-top: 8px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 8px;
  background-color: ${(props: IProps) => props.disabled
    ? Colors.cardBackground
    : props.selected
      ? Colors.primaryDark
      : Colors.transparent
  };
  z-index: 0;

  @media ${PlatformDevices.minLaptop} {
    ${(props: IProps) => (!props.selected && !props.disabled) && ItemMenuEffect}
  }
`;
