import NoitadaWS from '../index';



export const SearchProfiles = async (data: any) => {
  const url = '/app/search/companies';
  const response = await NoitadaWS.post(url, data);

  return response.data;
};



export const SearchEvents = async (data: any) => {
  const url = '/app/search/events';
  const response = await NoitadaWS.post(url, data);

  return response.data;
};
