import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Body,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

// import {
//   Container,
// } from './styled';



const CarnivalVotesListScreen: React.FC = () => {
  const { t: translate } = useTranslation();


  const carnivalLocalities = useSelector((state: RootState) => state.localities.selected);


  const [loading, setLoading] = useState<boolean>(false);



  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noAttractions}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_VOTES)}
      />

    );
  }


  useEffect(() => {
    setLoading(true);
    setLoading(false);
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            close
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.CARNIVAL_VOTES)}
            </Title>

            <SubTitle
              color={Colors.gray}>
              {carnivalLocalities?.city || '-'}
            </SubTitle>
          </Body>
        }
      />


      {listEmptyComponent()}

    </Screen>

  );
};



export default CarnivalVotesListScreen;
