import React from 'react';

import {
  Content,
} from '../../../../../../components/Composh/web';

import TimeContent from '../../../../../../components/Contents/TimeContent';

import {
  ContentPadder,
} from '../../../../../../styles/styled.layout';



export interface IProps {
  onPress: Function;
}



const Tab2Identity: React.FC<IProps> = (props: IProps) => {
  return (

    <Content>
      <ContentPadder>

        <TimeContent
          title={'00:05:00'}
          description={'Por favor, complete a compra dentro do prazo máximo indicado acima sem fechar esta tela.'}
        />

      </ContentPadder>
    </Content>

  );
};



export default Tab2Identity;
