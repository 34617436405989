import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  FormatCarnivalMarkerType,
  SwitchMarkerTypeImage,
} from '@noitada/shared/config/carnival.config';

import {
  Images,
} from '@noitada/shared/constants';

import {
  ECarnivalLocation,
} from '@noitada/shared/enums/Carnival/carnival-location.enum';

import MarkerBubble from '../MarkerBubble';

import {
  MarkerCarnivalMapPin,
  MarkerCarnivalMapContainer,
  MarkerMapImage,
} from './styled';



export interface IProps {
  type: any;
  pin: any;
  lat: any;
  lng: any;
  image: any;
  title: string;
  onEditPress?: any;
}



const MapCarnivalMarker: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const [showBubble, setShowBubble] = useState<boolean>(false);

  const typePin = props.type;
  const namePin = props.pin;



  if (typePin !== ECarnivalLocation.BLOC && typePin !== ECarnivalLocation.CABIN) {
    return (

      <MarkerCarnivalMapPin>

        <MarkerBubble
          visible={showBubble}
          title={props.title}
          subtitle={translate(FormatCarnivalMarkerType(typePin))}
          lat={props.lat}
          lng={props.lng}
          onPress={() => {
            setShowBubble(!showBubble);
          }}>

          <MarkerMapImage
            // alt={text}
            src={SwitchMarkerTypeImage(typePin, namePin)}
            onClick={() => {
              setShowBubble(!showBubble);
            }}
          />

        </MarkerBubble>

      </MarkerCarnivalMapPin>

    );
  }
  else {
    return (

      <MarkerCarnivalMapContainer>

        <MarkerBubble
          visible={showBubble}
          title={props.title}
          subtitle={translate(FormatCarnivalMarkerType(typePin))}
          lat={props.lat}
          lng={props.lng}
          onPress={() => {
            setShowBubble(!showBubble);
          }}>

          <MarkerMapImage
            // alt={text}
            src={props.image === '' || props.image === null || props.image === undefined
              ? Images.placeholderEvent
              : props.image
            }
            onClick={() => {
              setShowBubble(!showBubble);
            }}
          />

        </MarkerBubble>

      </MarkerCarnivalMapContainer>

    );
  }
};



export default MapCarnivalMarker;
