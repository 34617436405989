import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  color?: string;
}



export const EventDetailListView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 47px;
  padding-top: 8px;
  padding-bottom: 8px;
`;

export const EventDetailListIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-width: 35px;
  min-height: 40px;
  margin-left: 3px;
  margin-right: 12px;
`;

export const EventDetailListInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
`;

export const EventDetailListTitle = styled.p`
  color: ${(props: IProps) => props.color};
  font-size: 15px;
  font-weight: 700;
`;

export const EventDetailListSubTitle = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${(props: IProps) => props.color};
  font-size: 16px;
  font-weight: bold;
  line-height: 26px;
`;



export const PrideDetailsImage = styled.img`
  width: 30px;
  height: 20px;
  object-fit: contain;
`;


export const DisableOrderSheetText = styled.p`
  margin-top: 3px;
  margin-bottom: 8px;
  color: ${Colors.gray};
  font-size: 12px;
  white-space: pre-wrap;
`;


export const SeeCountEventText = styled.p`
  width: 100%;
  margin-top: 8px;
  color: ${Colors.gray};
  font-size: 12px;
  text-align: center;
`;
