import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const LoadingProgressStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100px;
  height: 100px;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.alertBackground};
  border-radius: ${Sizes.cardRadius}px;
`;
