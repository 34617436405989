import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/mask.config';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseInstallment,
  PaymentChooseSubtitle,
} from './styled';



export interface IProps {
  numberOfInstallments: number; // Número de parcelas
  valueOfInstallment: number; // Valor de parcelas
  onPress?: any;
}



const InstallmentChoose: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  function renderParcelText() {
    if (props.numberOfInstallments === 1) {
      return translate(TranslateConfig.AT_SIGHT);
    }

    if (props.numberOfInstallments > 1) {
      return `${props.numberOfInstallments} ${translate(TranslateConfig.INSTALLMENT_TIME)}`;
    }

    return '-';
  }



  return (

    <PaymentChooseContainer
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <PaymentInfoChooseView>

        <PaymentChooseTitle>
          {translate(TranslateConfig.INSTALLMENT)}
        </PaymentChooseTitle>


        <PaymentChooseCardNumber>
          {renderParcelText()}
        </PaymentChooseCardNumber>


        <PaymentChooseInstallment>
          {`${props.numberOfInstallments}x • ${FormatMoney(props.valueOfInstallment)}`}
        </PaymentChooseInstallment>


        <PaymentChooseSubtitle>
          {translate(TranslateConfig.ACTION_CLICK_TO_EDIT)}
        </PaymentChooseSubtitle>

      </PaymentInfoChooseView>

    </PaymentChooseContainer>

  );
};



export default InstallmentChoose;
