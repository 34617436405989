import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CreditFlag from '../CreditFlag';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseSubtitle,
  PaymentCardView,
  PaymentParcelsCardView,
} from './styled';



export interface IProps {
  method?: string;
  cardBrand?: string;
  parcels?: number;
  last4?: string | null | undefined;
}



const PaymentMethods: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const renderPaymentDetails = () => {
    switch (props.method) {
      case 'cash':
        return translate(TranslateConfig.CASH);

      case 'pix':
        return translate(TranslateConfig.PIX);

      case 'cheque':
        return translate(TranslateConfig.BANK_CHECK);

      case 'debit_elo':
      case 'debit_mastercard':
      case 'debit_visa':
      case 'credit_american':
      case 'credit_diners':
      case 'credit_hipercard':
      case 'credit_elo':
      case 'credit_mastercard':
      case 'credit_visa':
      case 'ticket_alelo':
      case 'ticket_ben':
      case 'ticket_sodexo':
      case 'ticket_ticket':
      case 'ticket_vr':
        return props.last4
          ? `**** **** **** ${props.last4}`
          : translate(TranslateConfig.EMPTY_CARD_REGISTERED);

      default:
        return 'Tipo desconhecido';
    }
  };


  function renderParcels() {
    if (props.parcels && props.parcels === 1) {
      return 'À Vista';
    }

    if (props.parcels && props.parcels > 1) {
      return `${props.parcels} Vezes`;
    }

    return 'Sem parcelamento';
  }


  function returnFlagPay() {
    return props.cardBrand || props.method;
  }


  function returnLabelPay() {
    if (props.method === 'cash') {
      return translate(TranslateConfig.CASH);
    }

    if (props.method === 'pix') {
      return translate(TranslateConfig.PIX);
    }

    if (props.method === 'cheque') {
      return translate(TranslateConfig.BANK_CHECK);
    }

    if (props.cardBrand) {
      return props.cardBrand?.toString().toUpperCase();
    }

    return '';
  }



  return (

    <PaymentChooseContainer>

      <PaymentInfoChooseView>

        <PaymentChooseTitle>
          {translate(TranslateConfig.PAYMENT_FORM)}
        </PaymentChooseTitle>


        <PaymentChooseCardNumber>
          {renderPaymentDetails()}
        </PaymentChooseCardNumber>


        <PaymentChooseSubtitle>
          {renderParcels()}
        </PaymentChooseSubtitle>

      </PaymentInfoChooseView>



      <PaymentCardView>
        <CreditFlag
          type={returnFlagPay()}
        />

        <PaymentParcelsCardView>
          {returnLabelPay()}
        </PaymentParcelsCardView>
      </PaymentCardView>

    </PaymentChooseContainer>

  );
};



export default PaymentMethods;
