import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  SearchEvents,
} from '@noitada/axios-config/endpoints/search.endpoints';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import LocationUser from '../../../../../components/Cards/CardLocation/Location';

import {
  Container,
  Content,
} from '../../../../../components/Composh/web';

import FooterChoose from '../../../../../components/Footers/FooterChoose';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../navigation/names';

import {
  ButtonText,
  ContentPadder,
  DetailsSectionView,
} from '../../../../../styles/styled.layout';

import SectionAvailable from './Sections/SectionAvailable';
import SectionDateEvent from './Sections/SectionDateEvent';
import SectionMusicalGenre from './Sections/SectionMusicalGenre';
import SectionOtherParams from './Sections/SectionOtherParams';
import SectionTicketValues from './Sections/SectionTicketValues';
import SectionTypeEvent from './Sections/SectionTypeEvent';



export interface IProps {
  location?: any;
  openLocation?: any;
}



const TabSearchEventScreen: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const searchLocality = props.location;


  const formik = useRef<FormikProps<any>>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  async function searchCompanyProfiles(values: any) {
    const payload = {
      city: searchLocality.city,
      state: searchLocality.state,
      country: searchLocality.country,

      type: values.type,
      fromDate: values.startDate,
      fromTime: values.startTime,
      toDate: values.endDate,
      toTime: values.endTime,
      minPrice: values.ticketStart,
      maxPrice: values.ticketEnd,
      availables: values.availables,
      musicGenre: values.musicalGenres,
      lgbt: values.lgbt,
    };

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await SearchEvents(payload);

      if (response) {
        history.replace({
          pathname: NameRoutes.SearchResultsScreen,
          state: {
            type: 'EVENT',
            results: response,
          },
        });
      }
      else {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, response.message || translate(TranslateConfig.ERROR_SEARCH));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={{
        type: '',
        startDate: '',
        startTime: '',
        endDate: '',
        endTime: '',
        ticketStart: '',
        ticketEnd: '',
        availables: [],
        musicalGenres: [],
        lgbt: false,
      }}
      // validationSchema={CompanyAboutValidation}
      onSubmit={(values) => {
        searchCompanyProfiles(values);
      }}>
      {({
        dirty,
        isValid,
        handleSubmit,
        // handleChange,
        handleBlur,
        values,
        errors,
        setFieldValue,
      }) => (

        <Container>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {mounted && (
            <Content>
              <ContentPadder>

                <DetailsSectionView>
                  <LocationUser
                    noMargin
                    city={searchLocality?.city}
                    state={searchLocality?.state}
                    country={searchLocality?.country}
                    onPress={() => {
                      props.openLocation(true);
                    }}
                  />
                </DetailsSectionView>



                <SectionTypeEvent
                  loading={loading || !mounted}
                  setFieldValue={setFieldValue}
                />



                <SectionDateEvent
                  loading={loading || !mounted}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />



                <SectionTicketValues
                  loading={loading || !mounted}
                  errors={errors}
                  values={values}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                />



                <SectionAvailable
                  loading={loading || !mounted}
                  setFieldValue={setFieldValue}
                />



                <SectionMusicalGenre
                  loading={loading || !mounted}
                  setFieldValue={setFieldValue}
                />



                <SectionOtherParams
                  setFieldValue={setFieldValue}
                />

              </ContentPadder>
            </Content>
          )}



          <FooterChoose
            hideCancelButton

            okDisabled={!dirty || !isValid || loading}
            okColor={!dirty || !isValid || loading
              ? ArrayColors.arrayDisabled
              : ArrayColors.arrayOk
            }
            okLabel={
              <ButtonText
                color={Colors.white}>
                {translate(TranslateConfig.ACTION_SEARCH)}
              </ButtonText>
            }
            okPress={() => {
              handleSubmit();
            }}
          />

        </Container>

      )}
    </Formik>

  );
};



export default TabSearchEventScreen;
