import React,
{
  useState,
} from 'react';

import {
  useHistory,
} from 'react-router-dom';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Content,
  Epigraph,
} from '../../../../../../components/Composh/web';

import TimeContent from '../../../../../../components/Contents/TimeContent';
import CouponChoose from '../../../../../../components/Payments/CouponChoose';
import InstallmentChoose from '../../../../../../components/Payments/InstallmentChoose';
import PaymentChoose from '../../../../../../components/Payments/PaymentChoose';

import {
  ContentPadderHorizontal,
  DetailsSectionView,
} from '../../../../../../styles/styled.layout';

import {
  ViewCountContent,
  HelpTicketText,
} from './styled';



export interface IProps {
  mainCreditCard: any;
  openCreditCardModal: any;

  installmentReceived: any;
  openInstallmentModal: any;

  onPress: any;
}



const TabChoosePayment: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();


  const installmentReceived = props.installmentReceived;
  const mainCreditCard = props.mainCreditCard;



  return (

    <Content>
      <ContentPadderHorizontal>


        <ViewCountContent>

          <TimeContent
            title={'00:05:00'}
            description={'Por favor, complete a compra dentro do prazo máximo indicado acima sem fechar esta tela.'}
          />

          <HelpTicketText>
            Os ingressos estarão disponíveis no aplicativo e serão enviados por email.
          </HelpTicketText>

        </ViewCountContent>



        <DetailsSectionView>

          <Epigraph.Section
            text={'Cupom de desconto'}
            textColor={Colors.primary}
            borderBottomColor={Colors.primary}
          />


          <CouponChoose
            type={'TICKET'}
            idCompany={'companySelected?.id'}
            onSendCoupon={(couponCode: string) => {
              // TODO
            }}
          />

        </DetailsSectionView>



        <DetailsSectionView>

          <PaymentChoose
            cardBrand={mainCreditCard?.card_brand}
            last4={mainCreditCard?.last4 ? validateString(mainCreditCard?.last4) : null}
            onPress={() => {
              props.openCreditCardModal(true);
            }}
          />

        </DetailsSectionView>



        <DetailsSectionView>

          <InstallmentChoose
            numberOfInstallments={installmentReceived?.installmentNumber}
            valueOfInstallment={installmentReceived?.installmentValue}
            onPress={() => {
              props.openInstallmentModal(true);
            }}
          />

        </DetailsSectionView>

      </ContentPadderHorizontal>
    </Content>

  );
};



export default TabChoosePayment;
