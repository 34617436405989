import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CardHomeGroupContainer,
  CardHomeGroupHeader,
  ReserveGroupTitle,
  ReserveNowInfoText,
  ReserveOpenSeeDetailText,
  ReserveOpenTexOrderText,
  ReserveSeparator,
  ReserveMoreDetaisView,
  ReserveDateView,
  ReserveOpenCloseText,
} from './styled';



export interface IProps {
  date?: string | null;
  description?: string | null;
  peopleInList?: string | number | null;

  openList?: string;
  closeList?: string;

  onPress?: any;
}



const CardWaitListsAll: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const numberPeople = Number(props.peopleInList);



  return (

    <CardHomeGroupContainer
      backgroundColor={Colors.cardBackground}
      borderRadius={Sizes.cardRadius}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <CardHomeGroupHeader>

        <ReserveGroupTitle>
          {props.date || '-'}
        </ReserveGroupTitle>


        <ReserveNowInfoText>
          {props.description || '-'}
        </ReserveNowInfoText>


        <ReserveOpenSeeDetailText>
          Pessoas na lista:
          {' '}
          {numberPeople <= 0 && (
            <ReserveOpenTexOrderText
              color={Colors.grayLight}>
              {'0'}
            </ReserveOpenTexOrderText>
          )}
          {numberPeople > 0 && (
            <ReserveOpenTexOrderText
              color={Colors.accent}>
              {props.peopleInList}
            </ReserveOpenTexOrderText>
          )}
        </ReserveOpenSeeDetailText>

      </CardHomeGroupHeader>



      <ReserveSeparator />



      <ReserveMoreDetaisView>

        <ReserveDateView>
          <ReserveOpenCloseText>
            {translate(TranslateConfig.OPEN)}: {props.openList ? `${props.openList}h` : '-'}
          </ReserveOpenCloseText>

          <ReserveOpenCloseText>
            {translate(TranslateConfig.CLOSE)}: {props.closeList ? `${props.closeList}h` : '-'}
          </ReserveOpenCloseText>
        </ReserveDateView>

      </ReserveMoreDetaisView>

    </CardHomeGroupContainer>

  );
};



export default CardWaitListsAll;
