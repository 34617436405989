import React from 'react';

import {
  Images,
} from '@noitada/shared/constants';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import ButtonAddCount from '../../../Buttons/ButtonAddCount';

import {
  CardBagBuyButton,
  BagPlaceImage,
  CardBagBuyView,
  CardBagText,
  CardBagSubtitle,
  CardBagCommentsText,
  CardBagControlsView,
  CardBagPriceText,
} from './styled';



export interface IProps {
  title?: string;
  description?: string;
  comments?: string;
  image?: string;
  price?: string;
  titleGroup?: string;
  quantity?: number;
  updateQuantity?: any;
}



const CardOrderBagBuy: React.FC<IProps> = (props: any) => {
  return (

    <CardBagBuyButton>

      <BagPlaceImage
        alt={'cover'}
        src={validateString(props.image)
          ? props.image
          : Images.placeholderAvatarCompany
        }
      />



      <CardBagBuyView>

        <CardBagText>
          {props.title || ''}
        </CardBagText>


        <CardBagSubtitle>
          {props?.titleGroup || '-'}
        </CardBagSubtitle>


        <CardBagCommentsText>
          {props.comments ? `* ${props.comments}` : '-'}
        </CardBagCommentsText>



        <CardBagControlsView>

          <ButtonAddCount
            quantity={props.quantity}
            removePress={() => {
              props.updateQuantity(-1);
            }}
            addPress={() => {
              props.updateQuantity(1);
            }}
          />


          <CardBagPriceText>
            {props.price}
          </CardBagPriceText>

        </CardBagControlsView>

      </CardBagBuyView>

    </CardBagBuyButton>

  );
};



export default CardOrderBagBuy;
