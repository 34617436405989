import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const WaitListUserWarningView = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const WaitListUserInsideView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 25px;
`;



export const WaitListPeopleText = styled.p`
  margin-top: 5px;
  margin-bottom: 13px;
  color: ${Colors.white};
  font-size: 14px;
  line-height: 18px;
  text-align: center;
`;



export const WaitListUserDateView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const WaitListUserDateText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.grayLight};
  font-size: 14px;
  line-height: 20px;
  text-align: center;
`;


export const WaitListUserTitle = styled.p`
  color: ${Colors.white};
  font-size: 20px;
  line-height: 26px;
  text-align: center;
`;


export const WaitListSubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
`;

export const WaitListSubtitleText = styled.p`
  margin-top: 2px;
  margin-right: 5px;
  color: ${Colors.gray};
  font-size: 13.5px;
  font-weight: 500;
`;


export const WaitListUserCircle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 150px;
  margin-top: 20px;
  margin-bottom: 15px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.black};
  border-radius: 50%;
`;

export const WaitListUserTextCircle = styled.p`
  color: ${Colors.white};
  font-size: 60px;
  font-weight: bold;
  text-align: center;
`;


export const WaitListUserPersonText = styled.p`
  margin-top: 5px;
  margin-bottom: 15px;
  color: ${Colors.gray};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;


export const WaitListUserExitText = styled.p`
  margin-top: 20px;
  color: ${Colors.gray};
  font-size: 16px;
  line-height: 22px;
  text-align: center;
`;
