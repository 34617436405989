import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/mask.config';

import {
  CardOrderBuyContent,
  CardOrderBuyHeader,
  CardOrderBuyIconNumber,
  CardOrderBuyNumber,
  CardOrderBuyinfoView,
  CardOrderTitle,
  CardOrderBoughtUsername,
  HistorySheetStatusText,
  CardOrderAccess,
  CardOrderPrice,
} from './styled';



export interface IProps {
  status?: string;
  saleName?: string;
  saleCategory?: string;
  quantity?: string;
  observations?: string;
  price?: string;
}



const CardReportOrderDetail: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderStatusColor() {
    if (!props.status) {
      return Colors.gray;
    }
    switch (props.status?.toUpperCase()) {
      case 'CREATED':
        return Colors.gray;

      case 'PREPARING':
        return Colors.attention;

      case 'COMPLETE':
        return Colors.sketch;

      case 'CANCELLED':
        return Colors.danger;

      case 'FINISH':
        return Colors.accept;

      case 'DECLINE':
        return Colors.danger;

      default:
        return Colors.gray;
    }
  }


  function renderStatusText() {
    if (!props.status) {
      return '-';
    }
    switch (props.status?.toUpperCase()) {
      case 'CREATED':
        return translate(TranslateConfig.ORDER_PLACED);

      case 'PREPARING':
        return translate(TranslateConfig.ORDER_PREPARING);

      case 'COMPLETE':
        return translate(TranslateConfig.ORDER_TO_DELIVER);

      case 'CANCELLED':
        return translate(TranslateConfig.CANCELED);

      case 'FINISH':
        return translate(TranslateConfig.FINISHED);

      case 'DECLINE':
        return translate(TranslateConfig.ORDER_REFUSED);
    }
  }



  return (

    <CardOrderBuyContent>

      <CardOrderBuyHeader>

        <CardOrderBuyIconNumber>
          <CardOrderBuyNumber>
            {props.quantity}
          </CardOrderBuyNumber>
        </CardOrderBuyIconNumber>



        <CardOrderBuyinfoView>

          <CardOrderTitle>
            {props.saleName}
          </CardOrderTitle>

          {props.saleCategory && (
            <CardOrderBoughtUsername>
              {props.saleCategory || '-'}
            </CardOrderBoughtUsername>
          )}

          <HistorySheetStatusText
            style={{
              color: renderStatusColor(),
            }}>
            {renderStatusText()}
          </HistorySheetStatusText>


          {props.observations &&
            <CardOrderAccess>
              {translate(TranslateConfig.OBSERVATIONS)}: {props.observations}
            </CardOrderAccess>
          }
        </CardOrderBuyinfoView>


        <CardOrderPrice>
          {props.price ? FormatMoney(parseFloat(props.price)) : '-'}
        </CardOrderPrice>

      </CardOrderBuyHeader>

    </CardOrderBuyContent>

  );
};



export default CardReportOrderDetail;
