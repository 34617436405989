import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  ValidateCoupon,
} from '@noitada/axios-config/endpoints/coupons.endpoints';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import ButtonAction from '../../Buttons/ButtonAction';

import {
  IconDelete,
} from '../../Icons';

import InputText from '../../Inputs/InputText';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseSubtitle,
  PaymentChooseImage,
  PaymentChooseSubView,
} from './styled';



export interface IProps {
  type: string;
  idCompany: string | null;
  onSendCoupon: any;
}



const CouponChoose: React.FC<IProps> = (props: IProps) => {
  const className: any = { ...props };

  const { t: translate } = useTranslation();


  const [couponCode, setCouponCode] = useState<string>('');
  const [error, setError] = useState<string>('');

  const [validatedCoupon, setValidatedCoupon] = useState<{ valid: boolean, name: string, code: string } | null>(null);

  const [loading, setLoading] = useState<boolean>(false);



  const handleValidateCoupon = async () => {
    setLoading(true);
    setError('');

    try {
      const payload = {
        type: props.type,
        idCompany: props.idCompany || '',
        code: couponCode,
      };

      const response = await ValidateCoupon(payload);

      if (!response?.data?.valid) {
        setError('Cupom indisponível');
        return;
      }

      setValidatedCoupon(response?.data);
      props.onSendCoupon(response?.data?.code);
    }
    catch (error) {
      console.error(error);
      setError('Cupom inválido');
    }
    finally {
      setLoading(false);
    }
  };


  const handleRemoveCoupon = () => {
    setValidatedCoupon(null);
    setCouponCode('');
  };



  return (

    <PaymentChooseContainer
      className={className?.className}>

      {!validatedCoupon?.valid
        ? (

          <PaymentChooseContainer>

            <PaymentInfoChooseView>

              <PaymentChooseTitle>
                {translate('Cupom de desconto')}
              </PaymentChooseTitle>


              <InputText
                disabled={loading}
                type={'TEXT'}
                placeholderText={'Código'}
                value={couponCode}
                countLimit={20}
                helpText={error}
                onChange={(value: string) => {
                  setCouponCode(value);
                  setError('');
                }}
              />

            </PaymentInfoChooseView>



            <ButtonAction
              title="Validar"

              onPress={() => {
                handleValidateCoupon();
              }}
              disabled={loading || !couponCode}
              backgroundColor={couponCode ? Colors.primary : Colors.disabled}
            />

          </PaymentChooseContainer>

        )
        : (

          <PaymentChooseContainer
            onClick={() => {
              handleRemoveCoupon();
            }}>

            {/* Exibe os dados do cupom validado */}
            <PaymentInfoChooseView>

              <PaymentChooseTitle>
                {validatedCoupon?.name || 'Cupom de deconto'}
              </PaymentChooseTitle>


              <PaymentChooseCardNumber>
                {validatedCoupon?.code || '-'}
              </PaymentChooseCardNumber>



              <PaymentChooseSubView>

                <IconDelete
                  color={Colors.danger}
                  size={14}
                />

                <PaymentChooseSubtitle>
                  Clique para remover o cupom
                </PaymentChooseSubtitle>

              </PaymentChooseSubView>

            </PaymentInfoChooseView>



            <PaymentChooseImage
              alt={'Card Logo'}
              src={Images.flagDiscount}
            />

          </PaymentChooseContainer>

        )
      }

    </PaymentChooseContainer>

  );
};



export default CouponChoose;
