import NoitadaWS from '../index';



export const GetHighlights = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/app/highlights';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCompaniesEventsMap = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/app/highlights/map';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};



export const GetCarnivalsFestivals = async (localities: any) => {
  const payload = {
    ...localities,
  } as any;

  const url = '/app/highlights/carnivals-festivals';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });

  return response.data;
};
