import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Content,
} from '../../../../../components/Composh/web';



export interface IProps {
  width?: number;
  maxWidth?: number;
  height?: number;
}



export const SeeDatesContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;

  ${(props: IProps) => props.width && `width: ${props.width}px`};
  ${(props: IProps) => props.width && `max-width: ${props.maxWidth}px`};
  ${(props: IProps) => props.height && `height: ${props.height}px`};
`;



export const SeeDatesFlat = styled(Content)`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
`;



export const SeeDatesButton = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 45px;
  min-height: 45px;
`;

export const SeeDatesText = styled.p`
  color: ${Colors.white};
  font-size: 16px;
`;
