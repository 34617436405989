import styled from 'styled-components';



export interface IProps {
  backgroundColor?: string;
}



export const MenuEpigraphSection = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 5px;
`;
