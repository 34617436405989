import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';

import {
  Items,
} from '../../../../components/Composh/web';



export const OrderContentPadder = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;



export const OrderItemComponent = styled(Items.List)`
  margin-left: 0;
  margin-bottom: 10px;
  padding-left: 0;
`;



export const CardOrderPaymentView = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
  padding-bottom: ${Sizes.paddingContainer}px;
`;
