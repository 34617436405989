import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  DetailsSectionView,
} from '../../../../styles/styled.layout';



export const WifiAndInfosContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  width: 320px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 5px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const WifiInfoSection = styled(DetailsSectionView)`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PayMinInfoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
  margin-bottom: 4px;
`;

export const PayMinInfoItemView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
`;

export const PayMinInfoIcon = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25px;
  height: 25px;
  margin-right: 10px;
`;

export const PayMinInfoTitle = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 500;
`;

export const PayMinInfoPrice = styled.p`
  color: ${Colors.accent};
  font-size: 15px;
  font-weight: bold;
  text-align: right;
`;



export const WifiCloseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
`;
