import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';



export const ProfileCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-top: 5px;
  padding-bottom: 5px;

  @media ${PlatformDevices.minMobileS} {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }

  @media ${PlatformDevices.minTablet} {
    width: 50%;
    min-width: 50%;
    max-width: 50%;
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minLaptop} {
    width: calc(100% / 3);
    min-width: calc(100% / 3);
    max-width: calc(100% / 3);
    padding-left: 4px;
    padding-right: 4px;
  }
  
  @media ${PlatformDevices.minDesktop} {
    width: 25%;
    min-width: 25%;
    max-width: 25%;
    padding-left: 4px;
    padding-right: 4px;
  }
`;

export const ProfileCardContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  width: 100%;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



export const ProfileInfoView = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding-top: 12px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 12px;
`;


export const ProfileLogoView = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`;

export const ProfileTypeView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 28px;
  margin-top: 6px;
`;

export const ProfileTypeText = styled.p`
  color: #B4B4B4;
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
`;

export const ProfileImage = styled.img`
  height: 100px;
  width: 100px;
  background-color: black;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;

export const ProfileTextsView = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 12px;
`;

export const DashProfileUserNameView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const VerifiedIconComponent = styled.div`
  display: flex;
  margin-left: 9px;
`;

export const ProfileNameText = styled.h1`
  color: ${Colors.white};
  font-size: 17px;
  font-weight: bold;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

export const ProfileUserNameText = styled.p`
  margin-bottom: 1px;
  color: ${Colors.white};
  font-size: 13px;
  font-weight: bold;
`;


export const ProfilePlaceView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 4px;
`;

export const IconMapPinStyle = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: -2px;
  margin-right: 4px;
`;

export const ProfilePlaceText = styled.p`
  color: ${Colors.textApp};
  font-size: 15px;
`;

export const ProfileOpenHoursText = styled.p`
  color: ${Colors.textApp};
  font-size: 14.5px;
`;

export const ProfileServiceText = styled.p`
  color: darkgray;
  font-size: 14px;
`;

export const ProfileFavoriteButton = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;
  padding-right: 3px;
  padding-bottom: 5px;
`;



export const ProfileActionsButtonView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;
  width: 60px;
  min-width: 60px;
`;

export const ProfileFavoriteView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  height: 45px;
`;

export const DashProfileRatingView = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-top: 2px;
  margin-bottom: 6px;
  padding-left: 7px;
  padding-right: 7px;
`;

export const DashProfileRatingIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 5px;
`;

export const ProfileRatingText = styled.p`
  color: ${Colors.accent};
  font-size: 16px;
  font-weight: 600;
`;
