import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Overlay,
} from '../../components/Composh/web';

import EmptyContent from '../../components/Empty';
import FooterChoose from '../../components/Footers/FooterChoose';
import HeaderModal from '../../components/Headers/HeaderModal';
import CreditCardDash from '../../components/Payments/CreditCardDash';

import {
  ButtonText,
} from '../../styles/styled.layout';

import {
  PaymentModalContainer,
  PaymentPadderHorizontal,
} from './styled';



export interface IProps {
  visible: boolean;
  data: any;
  onCancelPress?: any;
  onPress?: any;
}



const PaymentsModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const { t: translate } = useTranslation();


  const paymentsData = props.data && Array.isArray(props.data) && props.data?.length > 0 ? props.data : [];



  function goBack() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  };


  function renderItem(item: any, index: any) {
    return (

      <CreditCardDash
        key={index}
        current={item.main}
        cardBrand={item.card_brand}
        name={item.name}
        last4={item.last4}
        onPress={() => {
          if (props.onPress) {
            props.onPress(item);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.logoIconAppWhite}
        title={translate(TranslateConfig.EMPTY_PAYMENT)}
      />

    );
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <PaymentModalContainer>

        <HeaderModal
          title={translate(TranslateConfig.ACTION_ADD_PERSON)}
          subtitle={translate(TranslateConfig.SUBTITLES_CARDS)}
          onPress={() => {
            goBack();
          }}
        />



        <PaymentPadderHorizontal
          data={paymentsData}
          renderItem={renderItem}
          listEmptyComponent={listEmptyComponent()}
        />



        <FooterChoose
          hideCancelButton

          okColor={ArrayColors.arrayCancel}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CLOSE)}
            </ButtonText>
          }
          okPress={() => {
            goBack();
          }}
        />

      </PaymentModalContainer>

    </Overlay>

  );
};



export default PaymentsModal;
