import NoitadaWS from '../index';



export const CreateWaiters = async (checkinId: string) => {
  const url = '/app/waiters';
  const response = await NoitadaWS.post(url, { checkId: checkinId });
  return response.data;
};



export const CancelCallWaiters = async (checkinId: string) => {
  const url = '/app/waiters/cancel';
  const response = await NoitadaWS.post(url, { checkId: checkinId });
  return response.data;
};
