import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CreditFlag from '../CreditFlag';

import {
  PaymentChooseContainer,
  PaymentInfoChooseView,
  PaymentChooseTitle,
  PaymentChooseCardNumber,
  PaymentChooseSubtitle,
  PaymentCardView,
  PaymentParcelsCardView,
} from './styled';



export interface IProps {
  onPress?: any;
  noChangeCard?: boolean;
  cardBrand?: string;
  parcels?: number;
  last4?: string | null | undefined;
}



const PaymentChoose: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <PaymentChooseContainer
      disabled={props.noChangeCard}
      onClick={() => {
        if (!props.noChangeCard && props.onPress) {
          props.onPress();
        }
      }}>

      <PaymentInfoChooseView>

        <PaymentChooseTitle>
          {translate(TranslateConfig.PAYMENT_FORM)}
        </PaymentChooseTitle>


        <PaymentChooseCardNumber>
          {props.last4
            ? `**** **** **** ${props.last4}`
            : translate(TranslateConfig.EMPTY_CARD_REGISTERED)
          }
        </PaymentChooseCardNumber>


        {!props.noChangeCard && (
          <PaymentChooseSubtitle>
            {props.last4
              ? translate(TranslateConfig.ACTION_CLICK_TO_EDIT)
              : translate(TranslateConfig.ACTION_CLICK_TO_ADD)
            }
          </PaymentChooseSubtitle>
        )}


        {props.noChangeCard && props.parcels && (
          <PaymentChooseSubtitle>
            {props.parcels === 1
              ? 'À Vista'
              : `${props.parcels} Vezes`
            }
          </PaymentChooseSubtitle>
        )}

      </PaymentInfoChooseView>



      <PaymentCardView>
        <CreditFlag
          type={props.cardBrand}
        />

        <PaymentParcelsCardView>
          {props.cardBrand?.toString().toUpperCase() || ''}
        </PaymentParcelsCardView>
      </PaymentCardView>

    </PaymentChooseContainer>

  );
};



export default PaymentChoose;
