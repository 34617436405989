import styled from 'styled-components';



export interface IProps {
  size?: number;
  backgroundColor?: string;
}



export const CheckInButton = styled.div`
  position: absolute;
  bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${(props: IProps) => props.size}px;
  width: ${(props: IProps) => props.size}px;
  background-color: ${(props: IProps) => props.backgroundColor};
  padding-bottom: 2px;
  padding-left: 2px;
  border-radius: 50px;
`;
