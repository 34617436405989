import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  EEventType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  Content,
} from '../../../../../../components/Composh/web';

import {
  FormatMoney,
} from '../../../../../../config/mask.config';

import {
  EventImageView,
  BannerImageContainer,
  BannerBackground,
  BannerImageOverlay,
  EventImageCheckout,
  EventNameCheckout,
  PriceContent,
  PriceText,
  ToPayText,
  EventResumePadder,
  EventInfoView,
  EventNameText,
  EventPlaceText,
  TicketInfoView,
  TicketItemContent,
  TicketItemView,
  TickeItemNameText,
  TickeItemTypeLotText,
  TickeItemForUserText,
  TickeItemPriceText,
  TableContent,
  TableView,
  TableColComponent,
  InfoTicketText,
  InfoTotalText,
  PricesTicketText,
  TotalContentView,
  PricesView,
  PricesTotalText,
  PricesParcelText,
  TableDescriptionTicket,
} from './styled';



export interface IProps {
  onPress: Function;
}



const TabCheckout: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const eventImage = validateString('')
    ? ''
    : Images.placeholderEvent;


  function renderItem(item: any, index: number) {
    return (

      <TicketItemContent
        key={index}>

        <TicketItemView>

          <TickeItemNameText>
            Nome do Ingresso
          </TickeItemNameText>

          <TickeItemTypeLotText>
            Lote • Tipo do Ingresso
          </TickeItemTypeLotText>

          <TickeItemForUserText>
            Para: Nome público
          </TickeItemForUserText>

        </TicketItemView>



        <TickeItemPriceText>
          {FormatMoney(133500)}
        </TickeItemPriceText>

      </TicketItemContent>

    );
  }


  function renderTableRow(title: string, price: string) {
    return (

      <TableColComponent>

        <InfoTicketText>
          {title}
        </InfoTicketText>

        <PricesTicketText>
          {price}
        </PricesTicketText>

      </TableColComponent>

    );
  }


  function renderTotalTableRow(title: string, price: string) {
    return (

      <TotalContentView>

        <InfoTotalText>
          {title}
        </InfoTotalText>


        <PricesView>
          <PricesTotalText>
            {price}
          </PricesTotalText>

          <PricesParcelText>
            {true
              ? `${12}X de ${FormatMoney(25300)}`
              : 'À vista'
            }
          </PricesParcelText>
        </PricesView>

      </TotalContentView>

    );
  }


  function eventTypeText(eventType: string) {
    switch (eventType) {
      case EEventType.PARTY:
        return translate(TranslateConfig.PARTY);

      case EEventType.NIGHT_PARTY:
        return translate(TranslateConfig.NIGHT_PARTY);

      default:
        return '-';
    }
  }



  return (

    <Content>

      <EventImageView>

        <BannerImageContainer>

          <BannerBackground
            alt={'picture'}
            src={eventImage}
          />


          <BannerImageOverlay />


          <EventImageCheckout
            src={eventImage}
          />


          <EventNameCheckout>
            {eventTypeText('NIGHT_PARTY').toUpperCase()}
          </EventNameCheckout>

        </BannerImageContainer>



        <PriceContent>

          <PriceText>
            {FormatMoney(139850)}
          </PriceText>


          <ToPayText>
            A pagar
          </ToPayText>

        </PriceContent>

      </EventImageView>



      <EventResumePadder>

        <EventInfoView>

          <EventNameText>
            Nome do Evento com duas linhas para ter noção até onde vai o texto.
          </EventNameText>


          <EventPlaceText>
            Nome do local com apenas uma linha
          </EventPlaceText>

        </EventInfoView>


        <TicketInfoView>

          {[
            {},
          ].map((item: any, index: number) => renderItem(item, index))}

        </TicketInfoView>



        <TableContent>
          <TableView>

            {renderTableRow(
              'Subtotal:',
              FormatMoney(133500),
            )}


            {renderTableRow(
              'Encargos:',
              FormatMoney(1303),
            )}


            {renderTableRow(
              'Desconto:',
              `- ${FormatMoney(7000)}`,
            )}


            {renderTotalTableRow(
              'Total:',
              FormatMoney(39800),
            )}

          </TableView>
        </TableContent>


        <TableDescriptionTicket>
          Os ingressos estarão disponíveis no seu perfil
        </TableDescriptionTicket>

      </EventResumePadder>

    </Content>

  );
};



export default TabCheckout;
