import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Footer,
} from '../../../styles/styled.layout';

import Separators from '../../Separators';



export interface IButtonAdd {
  disabled?: boolean;
}



export const ItemFooterChooseStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 3px;
`;

export const ItemSeparatorVerticalFooter = styled(Separators).attrs({
  style: {
    marginTop: -11,
    marginBottom: -10,
  },
})`
`;



export const ItemDetailFooter = styled(Footer)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
`;

export const AddCartTotalButton = styled.a <IButtonAdd>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex: 1;
  position: relative;
  height: ${Sizes.buttonHeight}px;
  margin-left: 15px;
  background-color: ${(props) => props?.disabled ? Colors.disabled : Colors.buttonOk};
  border-radius: 4px;
`;

export const AddCartTotalText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
  font-weight: 600;
`;
