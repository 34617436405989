import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Switch,
} from '../../Composh/web';

import {
  PublishedContainer,
  PublishedText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  title?: string;
  onPress?: any;
}



const ButtonPublish: React.FC<IProps> = (props) => {
  const className: any = { ...props };


  return (

    <PublishedContainer
      className={className?.className}

      disabled={props.disabled}
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>

      <PublishedText>
        {props.title ? props.title : props.selected
          ? 'Publicado'
          : 'Não Publicado'
        }
      </PublishedText>


      <Switch
        hideText
        disabled={props.disabled}
        selected={props.selected}
        color={Colors.white}
        selectedColor={Colors.primary}
      // onValueChange={toggleSwitch}
      // value={switchValue}
      />

    </PublishedContainer>

  );
};



export default ButtonPublish;
