import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useHistory,
} from 'react-router-dom';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import CardEventsHome from '../../../../components/Cards/CardEvents/CardEventsHome';

import {
  Content,
} from '../../../../components/Composh/web';

import PictureEventFinished from '../../../../components/Pictures/PictureEventFinished';

import {
  MoreEventsContainer,
  MoreEventsTitle,
  MoreEventsFlatList,
} from './styled';



export interface IProps {
  event?: any;
}



const FinishedEventScreen: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const resultsReceived = [];

  const event = props.event;



  function renderCardEvent(item: any, index: number) {
    return (

      <CardEventsHome
        key={index}
        companyName={item.company?.name}
        companyImage={item.company?.picture?.link}
        eventName={item.name}
        eventImage={item.picture}
        startDate={FormatIsoDateWithPatternDayjs(item?.startAt, 'DD/MM/YYYY - HH:mm')}
        endDate={FormatIsoDateWithPatternDayjs(item?.endAt, 'DD/MM/YYYY - HH:mm')}
        place={item.address?.district}
        availables={item.availables}
        onPicturePress={() => {
          // navigation.dispatch(StackActions.push(NameRoutes.DetailsCompanyScreen, {
          //   companyDetail: item.company,
          // }));
        }}
        onPress={() => {
          // navigation.dispatch(StackActions.push('Details', {
          //   detailEvent: item,
          // }))
        }}
      />

    );
  }



  return (

    <Content>

      <PictureEventFinished
        image={event?.picture}
      />



      {resultsReceived && resultsReceived?.length > 0 && (
        <MoreEventsContainer>

          <MoreEventsTitle>
            {translate(TranslateConfig.EMPTY_EVENT_EXPLORE)}
          </MoreEventsTitle>


          <MoreEventsFlatList>
            {resultsReceived.map((item: any, index: any) => (
              renderCardEvent(item, index)
            ))}
          </MoreEventsFlatList>

        </MoreEventsContainer>
      )}

    </Content>

  );
};



export default FinishedEventScreen;
