import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetTicketsFromUser,
} from '@noitada/axios-config/endpoints/entrance-ticket.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EPeriodTickets from '@noitada/shared/enums/Event/event-tickets-period.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardTicket from '../../../../../../components/Cards/CardTicket';
import EmptyContent from '../../../../../../components/Empty';
import LoadingScreen from '../../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../../navigation/names';

import {
  ListTicketContentFlex,
} from './styled';



const TabTicketsUpcomming: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const [ticketsResponse, setTicketsResponse] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getReservesFunction = async () => {
    try {
      setLoading(true);

      const response = await GetTicketsFromUser(user.id, EPeriodTickets.FUTURE);

      if (response) {
        setTicketsResponse(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardTicket
        key={index}
        date={item?.event?.startAt}
        companyImage={item?.companyPicture}
        companyName={item?.event?.company?.name}
        ticketId={item?.shortId}
        ticketName={item?.name}
        eventImage={item?.eventPicture}
        eventName={item?.event?.name}
        eventType={item?.event?.type}
        city={item?.event?.address?.city}
        state={item?.event?.address?.state}
        onPress={() => {
          history.push({
            pathname: NameRoutes.TicketBuyDetailsScreen.replace(
              ':ticket_id',
              item?.shortId,
            ),
            state: {
              item,
              editable: true,
            },
          });
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.ticketsWait}
        title={translate(TranslateConfig.EMPTY_TICKET)}
      />

    );
  }



  useEffect(() => {
    getReservesFunction();
  }, []);



  return (

    <ListTicketContentFlex
      loading={loading}
      data={ticketsResponse}
      renderItem={renderItem}
      listLoadingComponent={renderLoading()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabTicketsUpcomming;
