import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EUserLevel,
} from '../enums';

import {
  validateString,
} from '../utils/string.utils';



export function _setLevelText(toTextLevel: string) {
  if (!validateString(toTextLevel)) {
    return 'Basic';
  }
  switch (toTextLevel.toUpperCase()) {
    case EUserLevel.BASIC:
      return 'Basic';

    case EUserLevel.PRIME:
      return 'Prime';

    case EUserLevel.PERSAS:
      return 'Persas';

    case EUserLevel.STAR:
      return 'Star';

    case EUserLevel.KING:
      return 'King';

    default:
      return '-';
  }
}


export function _setLevelImage(toImageLevel: string) {
  if (!validateString(toImageLevel)) {
    return Images.colorLevelBasic;
  }
  switch (toImageLevel.toUpperCase()) {
    case EUserLevel.BASIC:
      return Images.colorLevelBasic;

    case EUserLevel.PRIME:
      return Images.colorLevelSuper;

    case EUserLevel.PERSAS:
      return Images.colorLevelPersas;

    case EUserLevel.STAR:
      return Images.colorLevelStar;

    case EUserLevel.KING:
      return Images.colorLevelKing;

    default:
      return Images.colorLevelNone;
  }
}


export function _setLevelColor(toColorLevel: string) {
  if (!toColorLevel) {
    return Colors.basicColor;
  }
  switch (toColorLevel.toUpperCase()) {
    case EUserLevel.BASIC:
      return Colors.basicColor;

    case EUserLevel.PRIME:
      return Colors.superColor;

    case EUserLevel.PERSAS:
      return Colors.persasColor;

    case EUserLevel.STAR:
      return Colors.starColor;

    case EUserLevel.KING:
      return Colors.kingColor;

    default:
      return Colors.gray;
  }
}
