import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const CardTicketContainer = styled.a`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  margin-top: -0.5px;
  margin-bottom: -0.5px;
  padding-top: 10px;
  padding-left: 12px;
  padding-right: 12px;
  padding-bottom: 10px;
  border-top-color: ${Colors.menuLine};
  border-top-style: solid;
  border-top-width: 0.5px;
  border-bottom-color: ${Colors.menuLine};
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
`;



export const CardTicketText = styled.p`
  color: ${Colors.white};
  font-weight: bold;
  font-size: 17px;
`;



export const CardTicketAllView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const CardTicketInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CardTicketLot = styled.p`
  margin-bottom: 4px;
  color: ${Colors.textSubtitle};
  font-size: 15px;
  font-weight: 700;
`;

export const CardTicketSalesUntil = styled.p`
  margin-bottom: 6px;
  color: ${Colors.textSubtitle};
  font-size: 13px;
`;



export const CardTicketSalesPrice = styled.p`
  color: ${Colors.accent};
  font-size: 16px;
  font-weight: bold;
`;

export const CardTicketSalesType = styled.p`
  color: ${Colors.white};
  font-size: 14px;
`;



export const CardTicketTypesView = styled.div`
`;
