import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetEventByIdShort,
} from '@noitada/axios-config/endpoints/events.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  USER_SET_CURRENT_EVENT,
} from '@noitada/redux-config/reducers/event.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import CardEventsHome from '../../../components/Cards/CardEvents/CardEventsHome';

import {
  Body,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../navigation/names';

import {
  AllEventScrollFlatList,
} from './styled';



const AllCompanyEventsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const {
    company_username,
  } = useParams() as any;


  const [events, setEvents] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getEvents() {
    try {
      setLoading(true);

      const date = new Date();
      const from = date.toISOString();

      const data = await GetEventByIdShort(from, company_username);
      if (data) {
        setEvents(data);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <CardEventsHome
        key={index}
        companyName={item?.company?.name}
        companyImage={item?.company?.picture}
        eventName={item?.name}
        eventImage={item?.picture}
        startDate={FormatIsoDateWithPatternDayjs(item?.startAt, 'DD/MM/YYYY - HH:mm')}
        endDate={FormatIsoDateWithPatternDayjs(item?.endAt, 'DD/MM/YYYY - HH:mm')}
        place={item?.address?.district}
        availables={item?.availables}
        onPicturePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item?.company });
          history.push({
            pathname: NameRoutes.DetailsCompanyScreen.replace(
              ':company_username',
              item?.company?.company_username,
            ),
          });
        }}
        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_EVENT, payload: item });
          history.push({
            pathname: NameRoutes.DetailsEventScreen.replace(
              ':event_id',
              item?.idShort,
            ),
          });
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noParty}
        title={translate(TranslateConfig.EMPTY_COMPANY_EVENTS)}
      />

    );
  }



  useEffect(() => {
    getEvents();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.ALL_EVENTS)}
            </Title>

            <SubTitle>
              @{company_username}
            </SubTitle>
          </Body>
        }
      />



      <AllEventScrollFlatList
        loading={loading}
        data={events}
        renderItem={renderItem}
        listLoadingComponent={renderLoading()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Screen>

  );
};



export default AllCompanyEventsScreen;
