import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  color?: string;
}



export const FaqSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
`;

export const FaqSectionTitle = styled.p`
  margin-top: 2px;
  margin-bottom: 15px;
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 26px;
  font-weight: bold;
`;

export const FaqCategorySectionTitle = styled.p`
  margin-top: 10px;
  margin-bottom: 15px;
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 23px;
  font-weight: bold;
`;



export const FaqCategoriesContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-top: 10px;
  padding-bottom: 10px;
`;



export const FaqStyle = css`
  display: flex;
  padding-left: 2px;
  padding-right: 2px;
  border-top-color: ${Colors.menuSeparator};
  border-top-style: solid;
  border-top-width: 0.5px;
`;

export const FaqCategoryButton = styled.a`
  ${FaqStyle};
  flex-direction: column;
  justify-content: center;
  min-height: 80px;
`;

export const FaqCategoryView = styled.a`
  ${FaqStyle};
  flex-direction: row;
  align-items: center;
  min-height: 60px;
`;

export const FaqCategoryTitle = styled.p`
  margin-bottom: 5px;
  color: ${(props: IProps) => props.color || Colors.grayLight};
  font-size: 18px;
  font-weight: bold;
`;

export const FaqCategoryDescription = styled.p`
  color: ${(props: IProps) => props.color || Colors.gray};
  font-size: 15.5px;
  line-height: 21px;
`;

export const FaqCategoryBullet = styled.p`
  display: flex;
  flex: 1;
  color: ${(props: IProps) => props.color || Colors.gray};
  font-size: 18px;
  font-weight: bold;
`;
