const UserModel = {
  id: '',
  name: '',
  publicName: '',
  email: '',
  username: '',
  cpf: '',
  birthday: null,
  gender: null,
  phone: null,
  userInfo: {
    level: '',
    verified: false,
  },
  address: null,
  favorites: [],
  aboutMe: '',

  companys: [],
  info: null,
  login: '',
  oldPassword: null,
  password: '',
  picture: '',
  report: null,
  verified: false,
};



export default UserModel;
