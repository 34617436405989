import NoitadaWS from '../index';



export const GetUserAwaitCheckin = async () => {
  const url = '/app/orders-sheet/checkin-user/await-checkin';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const GetUserCurrentCheckin = async (id?: string) => {
  const url = id
    ? `/app/orders-sheet/checkin-user/checkin/${id}`
    : '/app/orders-sheet/checkin-user/checkin';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const MakeCheckin = async (companyId: string, table: string, method: string) => {
  const url = '/app/orders-sheet/checkin-user/checkin';

  const response = await NoitadaWS.post(url, {
    companyId,
    table,
    method,
  });
  return response.data;
};



export const cancelCheckin = async () => {
  const url = '/app/orders-sheet/checkin-user/giveup';

  const response = await NoitadaWS.post(url);
  if (response) {
    return true;
  }
  else {
    return false;
  }
};



export const MakeCheckout = async () => {
  const url = '/app/orders-sheet/checkin-user/checkout';

  const response = await NoitadaWS.post(url);
  return response.data;
};
