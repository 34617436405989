import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetCircuits,
  GetCarnivalMap,
} from '@noitada/axios-config/endpoints/carnival.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  CARNIVAL_ACTIONSHEET_CIRCUITS,
} from '@noitada/redux-config/reducers/carnival-festivals.store';

import {
  CircuitTypeArray,
} from '@noitada/shared/arrays/carnival.array';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  ECarnivalCircuit,
} from '@noitada/shared/enums/Carnival/carnival-circuit.enum';

import {
  ECarnivalLocation,
} from '@noitada/shared/enums/Carnival/carnival-location.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonCityCarnival from '../../../../components/Buttons/ButtonCitiesApp/CityCarnival';
import ButtonSelect from '../../../../components/Buttons/ButtonSelect';

import {
  Container,
  Shimmer,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import {
  ButtonCarnivalGuide,
  ButtonCarnivalMusic,
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import {
  IconRefresh,
} from '../../../../components/Icons';

import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import MapGoogle from '../../../../components/Maps/Map';
import MapCarnivalMarker from '../../../../components/Maps/MapCarnivalMarker';
import NameRoutes from '../../../../navigation/names';

import {
  ChangeEventTypeButton,
  ChangeEventTypeImage,
} from '../../../../styles/styled.layout';

import CarnivalActionSheet from '../ActionSheet';
import CarnivalMapChooseModal from '../Modals';

import {
  HighlightMenuSeparator,
  HighlightsSeparator,
  ShimmerMargin,
  CarnivalMapContainer,
  CarnivalMapRefreshButton,
  CarnivalMapTypeButton,
  CarnivalMapTypeImage,
} from './styled';



const TabMapCarnivalScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const period = useSelector((state: RootState) => state.carnivalFestivals.itemCarnival);
  const carnivalLocalities = useSelector((state: RootState) => state.localities.selected);


  const [permissionMap, setPermissionMap] = useState<boolean>(false);


  const [actionSheetCircuitType, setActionSheetCircuitType] = useState<any>(null);
  const allTypes: Array<any> = CircuitTypeArray.filter((e) => e.id > 1);
  const [selectedType, setSelectedType] = useState<any | null>(null);

  const [actionSheetCircuit, setActionSheetCircuit] = useState<any>(null);
  const [allCircuits, setAllCircuits] = useState<any>(null);
  const [selectedCircuit, setSelectedCircuit] = useState<any>(null);

  const [markersArray, setMarkersArray] = useState<Array<any>>([]);

  const [openTypesModal, setOpenTypesModal] = useState<boolean>(false);

  const [markerTypes, setMarkerTypes] = useState<Array<any>>([]);


  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function customMarker(item: any) {
    return (

      <MapCarnivalMarker
        key={item?.id}
        type={item?.type}
        pin={item?.pin}
        lat={item?.latitude}
        lng={item?.longitude}
        image={item?.picture}
        title={item?.description}
      />
    );
  }


  function renderLoading() {
    return (
      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />
    );
  }


  function listEmptyComponent() {
    return (
      <EmptyContent
        image={Images.noAttractions}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_LOCATIONS)}
      />
    );
  }


  function treatCarnivalMarkers(result: Array<any>) {
    const newMarkerArray: Array<any> = [];

    result?.forEach((groupResponse) => {
      groupResponse?.locations.forEach((itemMarker) => {
        newMarkerArray.push(itemMarker);
      });
    });

    setMarkersArray(newMarkerArray);
  }


  async function getAllLocations() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const result = await GetCarnivalMap({ ...carnivalLocalities, period: period?.id, circuit: selectedCircuit?.id });

      if (result) {
        treatCarnivalMarkers(result);
      }
      else {
        setMarkersArray([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setMarkersArray([]);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function getAttractionsList() {
    try {
      setLoading(true);

      const response = await GetCircuits({
        ...carnivalLocalities,
        period: period?.id,
      });

      const listResponse = response;

      if (listResponse?.length > 0) {
        const allCirc: Array<any> = [];
        listResponse?.map((item: any, index: number) => {
          allCirc.push({
            id: item?.id,
            label: item?.name,
            key: item?.key,
            value: index,
            dates: item?.dates,
            circuit: item?.circuit,
            type: item?.type,
          });
        });

        const defaultType = allTypes[0];
        setSelectedType(defaultType);
        const defaultCircuit = allCirc.find((e) => e.type === defaultType.value);
        if (defaultCircuit) {
          setSelectedCircuit(defaultCircuit);
        }
        setAllCircuits(allCirc);
      }
      else {
        setSelectedCircuit(null);
        setAllCircuits([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setSelectedCircuit(null);
      setAllCircuits([]);
      dispatch({
        type: CARNIVAL_ACTIONSHEET_CIRCUITS,
        payload: {
          show: false,
          title: '',
          data: [],
        },
      });
      const message =
        error?.hasError && error?.message && typeof error?.message === 'string'
          ? error?.message
          : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    setLoading(true);
    setPermissionMap(true);
    setLoading(false);

    getAttractionsList();
  }, []);


  useEffect(() => {
    if (selectedCircuit?.id) {
      getAllLocations();
    }
  }, [selectedCircuit]);

  useEffect(() => {
    if (selectedType && allCircuits) {
      const defaultCircuit = allCircuits.find((e) => e.type === selectedType.value);
      if (defaultCircuit) {
        setSelectedCircuit(defaultCircuit);
      }
    }
  }, [selectedType]);

  return (

    <Container>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <ButtonCityCarnival />
          }
          rightIcon={
            <ToolbarGroup>

              <ButtonCarnivalMusic
                color={Colors.white}
              />

              <ButtonCarnivalGuide
                color={Colors.white}
              />

              <ButtonMore
                color={Colors.white}
              />

            </ToolbarGroup >
          }
          middleToolbar={
            <HighlightMenuSeparator>

              <HighlightsSeparator>

                <ShimmerMargin
                  height={40}
                  width={140}
                  opacity={Sizes.shimmerOpacity}
                  borderRadius={Sizes.cardRadius}
                  visible={!loading}>

                  <ButtonSelect
                    activeOpacity={Sizes.CardActiveOpacity}
                    disabled={loading || allTypes?.length <= 0}
                    title={translate(selectedType?.label) || '-'}
                    subtitle={translate(TranslateConfig.TYPE)}
                    onPress={() => {
                      if (allTypes?.length > 0) {
                        setActionSheetCircuitType({
                          title: `${translate(TranslateConfig.TYPE)}: ${allTypes?.length}`,
                          data: allTypes,
                        });
                      }
                    }}
                  />

                </ShimmerMargin>



                <Shimmer
                  height={40}
                  width={140}
                  opacity={Sizes.shimmerOpacity}
                  borderRadius={Sizes.cardRadius}
                  visible={!loading}>

                  <ButtonSelect
                    activeOpacity={Sizes.CardActiveOpacity}
                    disabled={loading || allCircuits?.filter((e) => e.type === selectedType.value)?.length <= 0}
                    title={selectedCircuit?.label || '-'}
                    subtitle={selectedType?.value === ECarnivalCircuit.CIRCUIT ? translate(TranslateConfig.CIRCUIT) : translate(TranslateConfig.DISTRICTS)}
                    onPress={() => {
                      if (allCircuits?.filter((e) => e.type === selectedType.value)?.length > 0) {
                        setActionSheetCircuit({
                          title: `${translate(TranslateConfig.CIRCUIT)}: ${allCircuits?.filter((e) => e.type === selectedType.value)?.length}`,
                          data: allCircuits?.filter((e) => e.type === selectedType.value),
                        });
                      }
                    }}
                  />

                </Shimmer>

              </HighlightsSeparator>


              <ChangeEventTypeButton
                onClick={() => {
                  history.replace(NameRoutes.GridEventsScreen);
                }}>
                <ChangeEventTypeImage
                  alt={'change'}
                  src={Images.noitadaHomeImageButton}
                />
              </ChangeEventTypeButton>

            </HighlightMenuSeparator >
          }
          noSubToolbarPadding
        />



        {loading && (
          renderLoading()
        )}



        {
          !loading && !permissionMap && (
            listEmptyComponent()
          )
        }



        {
          !loading && permissionMap && (
            <CarnivalMapContainer>

              <MapGoogle>
                {markersArray.filter((e) => {
                  if (!markerTypes[0]) return true;
                  if (markerTypes.includes(e?.type)) {
                    return true;
                  }
                  else {
                    return false;
                  }
                },
                ).map((pin) => customMarker(pin))}
              </MapGoogle>



              <CarnivalMapRefreshButton
                onClick={() => {
                  getAttractionsList();
                }}>
                <IconRefresh
                  size={26}
                  color={Colors.black}
                />
              </CarnivalMapRefreshButton>



              <CarnivalMapTypeButton
                onClick={() => {
                  setOpenTypesModal(true);
                }}>
                <CarnivalMapTypeImage
                  src={Images.menuMarkerCarnival}
                />
              </CarnivalMapTypeButton>

            </CarnivalMapContainer>
          )
        }

      </Container>



      <CarnivalActionSheet
        visible={actionSheetCircuit}
        title={actionSheetCircuit?.title}
        data={actionSheetCircuit?.data}
        onClose={() => {
          setActionSheetCircuit(null);
        }}
        onPress={(item) => {
          setSelectedCircuit(item);
          setActionSheetCircuit(null);
        }}
      />



      <CarnivalActionSheet
        visible={actionSheetCircuitType}
        title={actionSheetCircuitType?.title}
        data={actionSheetCircuitType?.data}
        onClose={() => {
          setActionSheetCircuitType(null);
        }}
        onPress={(item) => {
          setSelectedType(item);
          setActionSheetCircuitType(null);
        }}
      />



      <CarnivalMapChooseModal
        visible={openTypesModal}
        selectTypes={markerTypes}
        onItemPress={(itemPress) => {
          if (itemPress) {
            const markerTypesArray = markerTypes;
            if (markerTypesArray.includes(itemPress)) {
              setMarkerTypes((prev) => prev.filter((f) => f !== itemPress));
            }
            else {
              setMarkerTypes((prev) => [...prev, itemPress]);
            }
          }
        }}
        onCheckAllPress={() => {
          setMarkerTypes(Object.values(ECarnivalLocation));
        }}
        onUncheckAllPress={() => {
          setMarkerTypes([]);
        }}
        onCancelPress={() => {
          setOpenTypesModal(false);
        }}
      />

    </Container>

  );
};



export default TabMapCarnivalScreen;
