import NoitadaWS from '../index';



export const GetUserCreditCard = async () => {
  const url = '/conta-global/users/credit-card';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const AddPersonReserve = async (data) => {
  const url = '/conta-global/find/found-or-create-user';
  const response = await NoitadaWS.post(url, data);

  return response.data;
};



export const FindUser = async (username) => {
  const url = `/conta-global/find/find-username/${username}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
