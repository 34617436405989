import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserCreditCard,
} from '@noitada/axios-config/endpoints/conta-global.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  ActionSheet,
} from '../../../../../components/Composh/web';

import HeaderModal from '../../../../../components/Headers/HeaderModal';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../navigation/names';

import {
  CloseOrderContent,
  CloseOrderContainer,
  CloseOrderButton,
  ListImageContainer,
  ListItemImage,
  ListCompanyImage,
  CloseOrderView,
  CloseOrderLabel,
  CloseOrderSubTitle,
} from './styled';



export interface IProps {
  visible?: boolean;
  company: any;
  onOpen?: any;
  onClose?: any;
}



const CloseOrderActionSheet: React.FC<IProps> = (props: IProps) => {
  if (!props.visible) {
    return null;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const payOnlineAvailable = props.company?.company_params?.canSalesOnline;


  const [mounted, setMounted] = useState<boolean>(false);
  const [havePayments, setHavePayments] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getPaymentsType() {
    try {
      const response = await GetUserCreditCard();
      const responseData = response;

      if (!responseData || responseData?.length === 0) {
        return;
      }

      setHavePayments(true);
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setMounted(true);
    }
  }


  function renderSubTitlePay(): string {
    if (!payOnlineAvailable) {
      return 'Pagamento online indisponível';
    }


    if (!havePayments) {
      return translate(TranslateConfig.HELP_PAY_UNAVAILABLE);
    }


    if (havePayments) {
      return translate(TranslateConfig.PAY_BY_APP);
    }

    return '-';
  }



  useEffect(() => {
    getPaymentsType();
  }, []);



  return (

    <ActionSheet
      visible={props.visible}
      overlayColor={Colors.menuOverlay}
      backgroundColor={Colors.cardBackground}
      // customStyles={ActionSheetStyle}
      onOpen={props.onOpen}
      onClose={props.onClose}
      height={220}
      closeOnDragDown
      closeOnPressMask>


      {!mounted && (
        <LoadingScreen
          type={'SCREEN'}
          color={Colors.primary}
        />
      )}



      {mounted && (
        <CloseOrderContent>

          <HeaderModal
            transparent
            heigth={35}
            title={translate(TranslateConfig.PAYMENT_OPTIONS)}
            onPress={() => {
              props.onClose();
            }}
          />


          <CloseOrderContainer>

            <CloseOrderButton
              disabled={!havePayments || !payOnlineAvailable}
              onClick={() => {
                if (!havePayments || !payOnlineAvailable) {
                  return;
                }

                history.push(NameRoutes.OrderSheetPayAppScreen, {
                  payApp: true,
                });
                props.onClose();
              }}>


              <ListImageContainer>
                <ListItemImage
                  alt={'cover'}
                  src={Images.ordersheetPayApp}
                />
              </ListImageContainer>


              <CloseOrderView>
                <CloseOrderLabel>
                  {translate(TranslateConfig.ACTION_PAY_APP)}
                </CloseOrderLabel>

                <CloseOrderSubTitle>
                  {renderSubTitlePay()}
                </CloseOrderSubTitle>
              </CloseOrderView>

            </CloseOrderButton>



            <CloseOrderButton
              onClick={() => {
                history.push(NameRoutes.OrderSheetPayAppScreen, {
                  payApp: false,
                });
                props.onClose();
              }}>


              <ListImageContainer>
                <ListCompanyImage
                  alt={'cover'}
                  src={props.company?.picture === '' || props.company?.picture === null || props.company?.picture === undefined
                    ? Images.placeholderAvatarCompany
                    : props.company?.picture
                  }
                />
              </ListImageContainer>


              <CloseOrderView>
                <CloseOrderLabel>
                  {translate(TranslateConfig.PAY_PRESENTIAL)}
                </CloseOrderLabel>

                <CloseOrderSubTitle>
                  {`${translate(TranslateConfig.PAY_TO)} ${props.company?.name}`}
                </CloseOrderSubTitle>
              </CloseOrderView>

            </CloseOrderButton>

          </CloseOrderContainer>

        </CloseOrderContent>
      )}

    </ActionSheet>

  );
};



export default CloseOrderActionSheet;
