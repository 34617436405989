import styled from 'styled-components';



export const TravelHeaderCloseIcon = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35px;
  height: 35px;
  margin-left: 10px;
`;
