import NoitadaWS from '../index';



export const GetHelpFaq = async (app: string) => {
  const url = `/all-project/help/${app}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
