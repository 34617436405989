import React from 'react';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import BadgeNotConfirmed from '../../../Badges/BadgeNotConfirmed';

import {
  CarnivalArtistContainer,
  CarnivalEventsContent,
  CarnivalEventsInfo,
  CardCarnivalHour,
  CardCarnivalDate,
  EventNameArtirst,
  CarnivalArtistPerson,
} from './styled';



export interface IProps {
  confirmed?: boolean;
  name?: any;
  date?: any;
  hour?: any;
  picture?: any;
}



const CardCarnivalAccordionArtist: React.FC<IProps> = (props: IProps) => {
  const ImageCardBackground = props.picture || Images.logoNoitadaCarnivalIcon;
  const dateFormatted = FormatIsoDateWithPatternDayjs(props.date || '', 'DD/MM/YYYY');

  const confirmedItem = !props.confirmed;



  function generateBackgroundColor() {
    return Colors.carnivalPurple;
  }



  return (

    <CarnivalArtistContainer>
      <CarnivalEventsContent
        backgroundColor={generateBackgroundColor()}
        borderRadius={Sizes.cardCarnivalRadius}>


        {confirmedItem && (
          <BadgeNotConfirmed />
        )}


        <CarnivalEventsInfo>

          <CardCarnivalHour>
            {props.hour !== null && props.hour !== '' ? props.hour : '  -  '}
          </CardCarnivalHour>


          <CardCarnivalDate>
            {dateFormatted}
          </CardCarnivalDate>


          <EventNameArtirst>
            {props.name || '-'}
          </EventNameArtirst>

        </CarnivalEventsInfo>



        <CarnivalArtistPerson
          alt={'attraction'}
          src={ImageCardBackground}
        />

      </CarnivalEventsContent>
    </CarnivalArtistContainer>

  );
};



export default CardCarnivalAccordionArtist;
