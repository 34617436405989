import React,
{
  useState,
} from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import CardBuyTicket from '../../../../../../components/Cards/CardBuyTicket';

import {
  Content,
} from '../../../../../../components/Composh/web';

import {
  ViewCountContent,
  DashLeftInfoView,
  DashProfileRatingText,
} from './styled';



export interface IProps {
  tickets: any;
  selectedId?: string;
  onPress: Function;
}



const TabListEntrances: React.FC<IProps> = (props: IProps) => {
  const { tickets } = props;


  const [typeTicket, setTypeTicket] = useState('FULL');



  function renderItem(item: any) {
    return (

      <CardBuyTicket
        name={item?.name}
        lot={''}
        // lot={item?.lots.find((l) =>
        //   moment().isAfter(l.saleStartDate) &&
        //   moment().isBefore(l.saleEndDate) &&
        //   l.quantity > 0)?.turn
        // }
        type={item?.type}
        endSale={FormatIsoDateWithPatternDayjs(item?.saleEndDate, 'DD/MM/YYYY HH:mm')}
        price={''}
        // price={item?.lots.find((l) =>
        //   moment().isAfter(l.saleStartDate) &&
        //   moment().isBefore(l.saleEndDate) &&
        //   l.quantity > 0)?.price
        // }
        onPress={() => {
          props.onPress(item?.id);
        }}
      />

    );
  }



  return (

    <Content>

      <ViewCountContent>

        <DashLeftInfoView
          backgroundColor={typeTicket === 'FULL' ? Colors.primary : Colors.cardBackground}
          onClick={() => {
            setTypeTicket('FULL');
          }}>

          <DashProfileRatingText>
            Ingressos inteiros
          </DashProfileRatingText>

        </DashLeftInfoView>


        <DashLeftInfoView
          backgroundColor={typeTicket === 'HALF' ? Colors.primary : Colors.cardBackground}
          onClick={() => {
            setTypeTicket('HALF');
          }}>

          <DashProfileRatingText>
            Ingressos meia-entrada
          </DashProfileRatingText>

        </DashLeftInfoView>

      </ViewCountContent>



      {tickets.map((ticket) => renderItem(ticket))}

    </Content>

  );
};



export default TabListEntrances;
