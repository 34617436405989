import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  TouchableTickets,
  TicketsPriceInfoView,
  TicketsMoreInfoTitle,
  SeeTicketsView,
  TicketsMoreInfoText,
  SeeTicketsText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  haveLists?: boolean;

  onPress?: any;
}



const DetailsSeeLists: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  function renderDetailsSubTitle() {
    if (props.haveLists) {
      return translate(TranslateConfig.EVENT_LIST_AVAILABLES);
    }

    return translate(TranslateConfig.EMPTY_LIST_IN_EVENT);
  }



  return (

    <TouchableTickets
      // disabled={props.disabled}
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <TicketsPriceInfoView>
        <TicketsMoreInfoTitle>
          {translate(TranslateConfig.LISTS)}
        </TicketsMoreInfoTitle>


        <TicketsMoreInfoText>
          {renderDetailsSubTitle()}
        </TicketsMoreInfoText>
      </TicketsPriceInfoView>


      <SeeTicketsView
        style={{
          opacity: props.disabled
            ? 0.5
            : 1,
        }}>
        <SeeTicketsText>
          {translate(TranslateConfig.ACTION_LISTS_SEE)}
        </SeeTicketsText>
      </SeeTicketsView>

    </TouchableTickets>

  );
};



export default DetailsSeeLists;
