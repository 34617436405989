import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Card,
} from '../../../Composh/web';



export interface IProps {
  color?: string;
}



export const CardHomeGroupContainer = styled(Card.View)`
  display: flex;
  flex-direction: row;
  margin-top: 7px;
  margin-bottom: 7px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
`;

export const CardHomeGroupHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ReserveGroupTitle = styled.h1`
  color: ${Colors.primary};
  font-size: 18px;
  font-weight: bold;
`;

export const ReserveNowInfoText = styled.p`
  margin-bottom: 2px;
  color: ${Colors.grayLight};
  font-size: 13px;
  line-height: 17px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

export const ReserveOpenSeeDetailText = styled.p`
  margin-top: 4px;
  color: ${Colors.white};
  font-size: 12px;
  line-height: 16px;
`;

export const ReserveOpenTexOrderText = styled.span`
  color: ${(props: IProps) => props.color || Colors.white};
  font-weight: bold;
`;


export const ReserveSeparator = styled.div`
  display: flex;
  flex-direction: column;
  width: 1px;
  height: 100%;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${Colors.gray};
`;



export const ReserveMoreDetaisView = styled.div`
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 100%;
  padding-left: 10px;
`;

export const ReserveDateView = styled.div`
  display: flex;
  flex-direction: column;
`;


export const ReserveOpenCloseText = styled.p`
  margin-top: 3px;
  margin-bottom: 3px;
  color: ${Colors.white};
  font-size: 12px;
  font-style: italic;
  line-height: 16px;
  text-align: center;
`;
