import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import WarningItemContent from '../../../../../components/Contents/WarningItem';

import {
  IconCompanyManageOff,
  IconCompanyManageOn,
} from '../../../../../components/Icons';

import {
  manageCompanyWhatsApp,
} from '../../../../../config/linking.config';

import {
  SubtitleText,
} from '../../../../../styles/styled.layout';

import {
  DetailsCompanyHeaderContainer,
  DetailsCompanyInfoHeader,
  DetailsCompanyTitle,
  DetailsAbout,
  DetailsCompanyHeader,
  PlaceLogo,
  CompanyInfoHomeView,
  CompanyManageButton,
  CompanyManageIcon,
  CompanyManageInfo,
  CompanyManageText,
  CompanyManageSubText,
  ProfileServiceText,
  ProfileUpdateCompanyText,
  SloganText,
} from './styled';



export interface IProps {
  hasManager?: boolean;
  name: string;
  idShort: string;
  picture?: string;
  slogan?: string;
  params: any;
  updatedAt: string;
  onManagerPress?: any;
}



const SectionInformations: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const companyParams = props.params;
  const hasUserManager = props.hasManager;
  const sizeUserManager = 27;



  function renderParamsAvailable() {
    if (!companyParams) {
      return '-';
    }

    const translations: Array<string> = [];

    if (companyParams?.orderSheet) {
      translations.push(translate(TranslateConfig.ORDERSHEET));
    }

    if (companyParams?.removalItem) {
      translations.push(translate(TranslateConfig.REMOVAL_ITEM));
    }

    if (companyParams?.delivery) {
      translations.push(translate(TranslateConfig.DELIVERY));
    }

    return translations.length > 0 ? translations.join(' • ') : '-';
  }



  return (

    <DetailsCompanyHeaderContainer>

      {!hasUserManager && (
        <WarningItemContent
          text={translate(TranslateConfig.HELP_PROFILE_VERIFY_UNMANAGE)}
          subtext={translate(TranslateConfig.ACTION_PROFILE_MANAGE)}
          backgroundColor={Colors.buttonSolid}
          color={Colors.primary}
          onPress={() => {
            manageCompanyWhatsApp(props?.idShort, props?.name);
          }}
        />
      )}



      <DetailsCompanyInfoHeader>
        <DetailsCompanyTitle>
          {validateString(props?.name) || '-'}
        </DetailsCompanyTitle>

        <SubtitleText>
          {translate(TranslateConfig.PUBLIC_ID)}: {validateString(props?.idShort) || '-'}
        </SubtitleText>
      </DetailsCompanyInfoHeader>



      <DetailsAbout>

        <DetailsCompanyHeader>
          <PlaceLogo
            alt={'place_logo'}
            src={props?.picture === '' || props?.picture === null || props?.picture === undefined
              ? Images.placeholderAvatarCompany
              : props?.picture
            }
          />


          <CompanyInfoHomeView>

            <CompanyManageButton
              onClick={() => {
                props.onManagerPress();
              }}>

              <CompanyManageIcon>
                {!hasUserManager
                  ? (
                    <IconCompanyManageOff
                      color={Colors.textApp}
                      size={sizeUserManager}
                    />
                  )
                  : (
                    <IconCompanyManageOn
                      color={Colors.textApp}
                      size={sizeUserManager}
                    />
                  )
                }
              </CompanyManageIcon>


              <CompanyManageInfo>
                <CompanyManageText>
                  {!hasUserManager
                    ? translate(TranslateConfig.PROFILE_UNMANAGE)
                    : translate(TranslateConfig.PROFILE_MANAGE)
                  }
                </CompanyManageText>


                <CompanyManageSubText>
                  {translate(TranslateConfig.ACTION_MORE_DETAILS)}
                </CompanyManageSubText>
              </CompanyManageInfo>

            </CompanyManageButton>



            <ProfileServiceText>
              {renderParamsAvailable()}
            </ProfileServiceText>



            <ProfileUpdateCompanyText>
              {translate(TranslateConfig.UPDATED_AT)}: {FormatIsoDateWithPatternDayjs(props.updatedAt, 'DD/MM/YYYY') || '-'}
            </ProfileUpdateCompanyText>

          </CompanyInfoHomeView>

        </DetailsCompanyHeader>



        {validateString(props?.slogan) && (
          <SloganText>
            {props?.slogan}
          </SloganText>
        )}

      </DetailsAbout>

    </DetailsCompanyHeaderContainer>

  );
};



export default SectionInformations;
