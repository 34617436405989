import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardBagBuyButton = styled.a`
  display: flex;
  flex-direction: row;
  overflow: hidden;
  align-items: center;
  margin-top: 6px;
  margin-bottom: 6px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const BagPlaceImage = styled.img`
  width: 85px;
  height: 100%;
  object-fit: cover;
`;



export const CardBagBuyView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
  padding-top: 5px;
  padding-right: 10px;
  padding-bottom: 5px;
`;


export const CardBagText = styled.h1`
  color: ${Colors.white};
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

export const CardBagSubtitle = styled.h2`
  margin-top: 2px;
  margin-bottom: 6px;
  color: ${Colors.grayLight};
  font-size: 14px;
  line-height: 18px;
  
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

export const CardBagCommentsText = styled.h3`
  margin-bottom: 2px;
  color: ${Colors.gray};
  font-size: 13px;
  line-height: 17px;
  
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;


export const CardBagControlsView = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
`;

export const CardBagPriceText = styled.p`
  color: ${Colors.buttonOkLight};
  font-size: 20px;
  height: 25px;
  text-align: right;
  font-weight: bold;
`;
