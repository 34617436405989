import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  disabled?: boolean;
}



export const PublishedContainer = styled.a`
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 15px;
  padding-left: 3px;
  padding-right: 3px;
  opacity: ${(props: IProps) => props.disabled ? Sizes.opacityOff : Sizes.opacityOn};
`;

export const PublishedText = styled.p`
  display: flex;
  flex: 1;
  margin-right: 10px;
  color: ${Colors.white};
  font-size: 16px;
`;
