import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetUserAwaitCheckin,
  MakeCheckin,
  cancelCheckin,
} from '@noitada/axios-config/endpoints/order-sheet-checkin.endpoints';

import CheckinRequestModel from '@noitada/axios-config/models/Checkin/checkin-request.model';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CHECKIN,
  USER_SET_WIFI_MODAL,
} from '@noitada/redux-config/reducers/comanda.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  Colors,
} from '@noitada/shared/constants';

import EOrderSheet from '@noitada/shared/enums/Orders/order-sheet.enum';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import AlertMessage from '../../../components/Alerts';

import {
  Container,
  Screen,
} from '../../../components/Composh/web';

import {
  ButtonBack,
  ButtonHelp,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconFlashOff,
  IconFlashOn,
  IconNoitada,
} from '../../../components/Icons';

import QrCodeComponent from '../../../components/QrCode';
import ModalFinished from '../../../modals/ModalFinished';

import NameRoutes from '../../../navigation/names';

import CheckInModal from '../Modals/CheckIn';
import WaitCheckinModal from '../Modals/WaitCheckin';

import {
  CameraQrStyled,
  QrReaderContainer,
  BodyQr,
  QrContainerTitle,
  QrContainerSubtitle,
  QrScannerSettingsView,
  ControlsReaderView,
  QRControlButton,
  QRManualCheckInButton,
  QRManualCheckInText,
  QrCodeContainer,
  QrCodeTitle,
  QrCodeSubtitle,
  QrCodeOpenCloseText,
  UserQrCodeContainer,
  UserQrCodeOrientation,
} from './styled';



const QrCodeScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const user = useSelector((state: RootState) => state.user.actualUser);


  const cameraMode: 'environment' | 'user' = 'environment';
  // const flashNativeMode = RNCamera.Constants.FlashMode.off;

  const [flashlight, setFlashlight] = useState<boolean>(false);

  const [openCheckinManual, setOpenCheckinManual] = useState<boolean>(false);

  const [openQrCode, setOpenQrCode] = useState<boolean>(false);

  const [waitingCheckin, setWaitingCheckin] = useState<boolean>(false);
  const [checkinDone, setCheckinDone] = useState<boolean>(false);

  const [showTryAgainModal, setShowTryAgainModal] = useState<string | null>(null);

  const [autoRefresh, setAutoRefresh] = useState(null as any);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function onDonePress() {
    history.replace(NameRoutes.OrderSheetScreen);
  }


  function toggleTorch() {
    if (flashlight === false) {
      setFlashlight(true);
    }
    else {
      setFlashlight(false);
    }
  }


  async function requestCheckIn(values: typeof CheckinRequestModel, methodUser: string) {
    const payloadIdShort = values.idShort?.toUpperCase();
    const payloadTable = values.tableEntrance;
    const payloadMethod = methodUser;

    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await MakeCheckin(payloadIdShort, payloadTable, payloadMethod);

      if (response) {
        setWaitingCheckin(true);
      }
      else {
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_CHECKIN_ASK));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);

      setShowTryAgainModal(message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function cancelCheckInFunction() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await cancelCheckin();

      if (response) {
        setWaitingCheckin(false);
        clearInterval(autoRefresh);
        showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_CHECKIN_CANCEL));
      }
      else {
        showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_CHECKIN_CANCEL));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  async function VerifyOrderRequest() {
    try {
      const response = await GetUserAwaitCheckin();
      const responseData = response?.data;

      if (responseData?.status !== EOrderSheet.REQUEST) {
        showSnackBarProps(Colors.danger, 'Não foi possivel fazer o Check-in');
        setWaitingCheckin(false);
        return;
      }

      setWaitingCheckin(true);
    }
    catch (error: any) {
      console.error(error);
      setWaitingCheckin(false);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
  }


  async function getAwaitCheckin() {
    if (waitingCheckin) {
      const refresh = setInterval(async () => {
        try {
          const response = await GetUserAwaitCheckin();
          const responseData = response?.data;

          if (responseData?.status === EOrderSheet.REQUEST) {
            return;
          }

          if (responseData?.status === EOrderSheet.REFUSED) {
            setWaitingCheckin(false);
            clearInterval(refresh);
            showSnackBarProps(Colors.danger, translate(TranslateConfig.HELP_ORDERSHEET_RECUSED));
            return;
          }

          if (responseData?.status === EOrderSheet.APPROVED) {
            dispatch({ type: USER_SET_CURRENT_COMPANY, payload: responseData?.company });
            dispatch({ type: USER_SET_CHECKIN, payload: responseData });
            setWaitingCheckin(false);
            setCheckinDone(true);

            if (responseData?.company?.company_about?.wifiName && responseData?.company?.company_about?.wifiPassword) {
              dispatch({ type: USER_SET_WIFI_MODAL, payload: true });
            }

            clearInterval(refresh);
            return;
          }

          showSnackBarProps(Colors.attention, translate(TranslateConfig.HELP_ORDERSHEET_NOT_FOUND));
          setWaitingCheckin(false);
          clearInterval(refresh);
        }
        catch (error: any) {
          console.error(error);
          setWaitingCheckin(false);
          clearInterval(refresh);

          const getMessageError = treatErrorAndReturnMessage(error);
          const messageError = translate(getMessageError);
          const colorError = treatErrorAndReturnColor(error);
          showSnackBarProps(colorError, messageError);
        }
      }, 10000);

      setAutoRefresh(refresh);
    }
  };


  function getPartsOfString(url: string) {
    const regexUrl = /number=(\d+)/;
    const resultNumber = regexUrl.exec(url);

    if (resultNumber) {
      // Retorna o valor
      return resultNumber[1];
    }
    else {
      return null;
    }
  }


  function extractID(url: string) {
    const regexUrl = /([a-zA-Z]+)\//;
    const resultShortId = regexUrl.exec(url);

    if (resultShortId) {
      // Retorna o valor
      return resultShortId[1];
    }
    else {
      return null;
    }
  }


  const handleScan = async (scanData: any) => {
    if (openQrCode) {
      return;
    }

    try {
      if (scanData && scanData !== '') {
        const textScanned: string = String(scanData?.text).toUpperCase();

        // Url format: https://noitada.app/IDSHORT/menu?number=1
        if (textScanned.includes('Noitada'.toUpperCase()) || textScanned.includes('/menu?number='.toUpperCase())) {
          const idShortRead = extractID(textScanned);
          const numberCheckin = getPartsOfString(textScanned);

          const payload = {
            idShort: idShortRead,
            tableEntrance: numberCheckin,
          } as typeof CheckinRequestModel;

          requestCheckIn(payload, 'QR');
        }
      }
      else {
        console.log('No Qr Code');
      }
    }
    catch {
      console.error('Error Qr Code');
    };
  };


  function renderUrlQr() {
    let usernameLink = '';

    if (user?.username) {
      usernameLink = user?.username;
    }

    const linkToReturn = `${Vars().noitadaAppLink}/?user=${usernameLink}`;
    return linkToReturn;
  }



  useEffect(() => {
    getAwaitCheckin();
  }, [waitingCheckin]);


  useEffect(() => {
    VerifyOrderRequest();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <CameraQrStyled
        constraints={{ facingMode: cameraMode }}
        scanDelay={500}
        onResult={handleScan}
      // chooseDeviceId={()=>selected}
      />



      <Container>

        <HeaderScreen
          transparent
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          rightIcon={
            <ButtonHelp
              color={Colors.white}
              onPress={() => {
                // TODO
              }}
            />
          }
        />


        <QrReaderContainer>

          <BodyQr>
            <IconNoitada
              color={Colors.white}
              size={44}
            />
          </BodyQr>


          <QrContainerTitle>
            {translate(TranslateConfig.QR_READER)}
          </QrContainerTitle>


          <QrContainerSubtitle>
            {translate(TranslateConfig.HELP_QR)}
          </QrContainerSubtitle>



          <QrScannerSettingsView>

            <ControlsReaderView>

              <QRControlButton
                // activeOpacity={Sizes.CardActiveOpacity}
                // disabled={menuOpened}
                flashlight={flashlight}
                onClick={() => {
                  toggleTorch();
                }}>
                {flashlight
                  ? (
                    <IconFlashOff
                      size={24}
                      color={Colors.white}
                    />
                  )
                  : (
                    <IconFlashOn
                      size={24}
                      color={Colors.white}
                    />
                  )
                }
              </QRControlButton>

            </ControlsReaderView>



            <QRManualCheckInButton
              // activeOpacity={Sizes.ButtonActiveOpacity}
              onClick={() => {
                setOpenCheckinManual(true);
              }}>
              <QRManualCheckInText>
                {translate(TranslateConfig.ACTION_CHECKIN_MANUAL)}
              </QRManualCheckInText>
            </QRManualCheckInButton>

          </QrScannerSettingsView>

        </QrReaderContainer>



        <QrCodeContainer
          distanceView={openQrCode ? 0 : -385}
          onClick={() => {
            setOpenQrCode(!openQrCode);
          }}>


          <QrCodeTitle>
            {translate(TranslateConfig.QR_MY_CODE)}
          </QrCodeTitle>


          <QrCodeSubtitle>
            @{user?.username || '-'}
          </QrCodeSubtitle>


          <QrCodeOpenCloseText>
            {openQrCode
              ? translate(TranslateConfig.ACTION_CLICK_CLOSE)
              : translate(TranslateConfig.ACTION_CLICK_OPEN)
            }
          </QrCodeOpenCloseText>


          <UserQrCodeContainer>
            <QrCodeComponent
              size={270}
              value={renderUrlQr()}
            />
          </UserQrCodeContainer>


          <UserQrCodeOrientation>
            {translate(TranslateConfig.HELP_CHEKIN_HOW_TO)}
          </UserQrCodeOrientation>

        </QrCodeContainer>

      </Container>



      <AlertMessage
        visible={Boolean(showTryAgainModal)}
        title={translate(TranslateConfig.OOPS)}
        description={translate(TranslateConfig.TRY_AGAIN)}
        text={showTryAgainModal || ''}
        okText={translate(TranslateConfig.ACTION_CLOSE)}
        okPress={() => {
          setShowTryAgainModal(null);
        }}
      />



      <CheckInModal
        visible={openCheckinManual}
        onCancelPress={() => {
          setOpenCheckinManual(false);
        }}
        onPress={(values: typeof CheckinRequestModel) => {
          if (values.idShort) {
            requestCheckIn(values, 'REQUEST');
          }
        }}
      />


      <WaitCheckinModal
        visible={waitingCheckin}
        onPress={(status: boolean) => {
          if (status) {
            cancelCheckInFunction();
          }
        }}
      />


      <ModalFinished
        visible={checkinDone}
        title={translate(TranslateConfig.SUCCESS_CHECKIN_ASK)}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          onDonePress();
        }}
      />

    </Screen>

  );
};



export default QrCodeScreen;
