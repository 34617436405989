import NoitadaWS from '../index';



export const GetTicketsByEvent = async (eventId: string) => {
  const url = `/app/events-ticket/${eventId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const ChangeTicketUser = async (eventId: string, username: string) => {
  const url = `/app/events-ticket/${eventId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const CancelTicketUser = async (ticketId: string) => {
  const url = `/app/events-ticket/${ticketId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const GetTicketsFromUser = async (userId: string, period: string) => {
  const url = `/app/events-ticket/user/${userId}?period=${period}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
