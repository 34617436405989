import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EInventoryItemStatus,
} from '@noitada/shared/enums';

import {
  InventoryTableStateView,
  InventoryTableStateIcon,
  InventoryTableStateText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  selected?: boolean;
  size?: number;
  state: EInventoryItemStatus | null;
  onPress?: any;
}



const BadgeSoldOut: React.FC<IProps> = (props: IProps) => {
  const stateReceived = props.state as any;


  function renderStateItemText(stateItem: string) {
    if (stateItem === EInventoryItemStatus.AVAILABLE) {
      return 'Disponível';
    }

    if (stateItem === EInventoryItemStatus.SOLD_OUT) {
      return 'Esgotado';
    }

    return '-';
  }



  function renderStateItemColor(stateItem: string) {
    if (!props.selected) {
      return Colors.gray;
    }

    if (stateItem === EInventoryItemStatus.AVAILABLE) {
      return Colors.accept;
    }

    if (stateItem === EInventoryItemStatus.SOLD_OUT) {
      return Colors.danger;
    }

    return Colors.gray;
  }



  return (

    <InventoryTableStateView
      disabled={props.disabled}
      color={renderStateItemColor(stateReceived)}
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>

      <InventoryTableStateIcon
        size={props.size}
        color={renderStateItemColor(stateReceived)}
      />


      <InventoryTableStateText
        size={props.size}
        color={renderStateItemColor(stateReceived)}>
        {renderStateItemText(stateReceived)}
      </InventoryTableStateText>

    </InventoryTableStateView>

  );
};



export default BadgeSoldOut;
