import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_ADD_SACOLA,
} from '@noitada/redux-config/reducers/order-bag.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EInventoryItemStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import BadgeSoldOut from '../../../../components/Badges/BadgeSoldOut';

import {
  Button,
} from '../../../../components/Composh/web';

import FooterAddCart from '../../../../components/Footers/FooterAddCart';
import HeaderModal from '../../../../components/Headers/HeaderModal';

import {
  IconItemAlcoholYes,
  IconItemGlutenNo,
  IconItemLactoseNo,
  IconItemOrganicYes,
  IconItemSpicyYes,
  IconItemVeganYes,
  IconItemVegetarianYes,
} from '../../../../components/Icons';

import InputText from '../../../../components/Inputs/InputText';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import NameRoutes from '../../../../navigation/names';

import {
  ItemDetailOverlay,
  OrderModalView,
  ItemInfoContent,
  ItemOrderViewImage,
  ItemOrderImage,
  ItemTypesOrderView,
  ItemTypesOrderIcon,
  ItemTypesViewOrderLabel,
  ItemTypesOrderLabel,
  ItemAllDetailsView,
  ItemGroupsExtrasContent,
  ItemTitleModal,
  ItemDescriptionText,
  ItemBadgeView,
  ItemBadgeComp,
  ItemBadgeText,
  ItemPricesFooterContent,
  ItemMapView,
  PriceGroupContent,
  OrderButton,
  ItemGroupDescriptionView,
  ItemGroupNameText,
  ItemGroupQuantityText,
  ItemGroupCodeText,
  ItemPriceView,
  ItemPriceText,
} from './styled';



export interface IProps {
  visible?: boolean;
  item?: any;
  idCompany?: any;
  selectProduct?: any;
  onCancelPress?: any;
  onSelectProduct?: any;
}



const MenuOrderDetails: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const history = useHistory();
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const obrigatoryArray: Array<any> = props.item?.group && Array.isArray(props.item?.group) && props.item?.group?.length > 0
    ? props.item?.group
    : [];

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const checkinUser: any = useSelector((state: RootState) => state.comanda.checkin);


  const [comments, setComments] = useState<string>('');



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function enableButtonBag() {
    if (checkinUser && checkinUser.status === 'APPROVED' && checkinUser.company.id === props.idCompany) {
      return true;
    }
    else {
      return false;
    }
  }


  async function processOrder(itemGroup: any, quantity: number) {
    dispatch({ type: USER_ADD_SACOLA, payload: { itemGroup, itemSale: { ...props.item, comments }, quantity: quantity } });
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_ADD_ITEM_BAG));
    props.onCancelPress();
  }


  async function AddItemOrOpenLoginScreen(item: any, quantity: number) {
    // FIXME: Não permitir itens de outros perfis, apenas do próprio local que fez checkin
    if (availableUser && checkinUser !== null) {
      await processOrder(item, quantity);
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  function renderItemBadge(label: string, backgroundColor: string, textColor: string) {
    return (

      <ItemBadgeComp
        backgroundColor={backgroundColor}>
        <ItemBadgeText
          color={textColor}>
          {label}
        </ItemBadgeText>
      </ItemBadgeComp>

    );
  }


  function renderObrigatoryItem(itemGroup: any, index: number) {
    const itemAvailableToSale = itemGroup?.inventoryStatus || EInventoryItemStatus.AVAILABLE;


    return (

      <ItemMapView
        key={index}
        disabled={itemAvailableToSale === EInventoryItemStatus.SOLD_OUT}
        onClick={() => {
          if (itemAvailableToSale === EInventoryItemStatus.SOLD_OUT) {
            return;
          }

          if (enableButtonBag()) {
            props?.onSelectProduct(itemGroup);
          }
        }}>

        <PriceGroupContent>

          {availableUser && (
            <OrderButton>
              <Button.Circle
                margin={false}
                active={props.selectProduct === itemGroup}
                size={14}
                activeColor={Colors.buttonOk}
                deactiveColor={Colors.textSubtitle}
              />
            </OrderButton>
          )}



          <ItemGroupDescriptionView>

            <ItemGroupNameText>
              {validateString(itemGroup?.name) || '-'}
            </ItemGroupNameText>


            <ItemGroupQuantityText>
              {`${translate(TranslateConfig.PERSON_TO)}: ${validateString(itemGroup?.quantity) || '-'} ${translate(TranslateConfig.PERSONS)}`}
            </ItemGroupQuantityText>


            <ItemGroupCodeText>
              {validateString(itemGroup?.sale_id)
                ? `${translate(TranslateConfig.CODE_ABREV)}: ${validateString(itemGroup?.code) || '-'}`
                : '-'
              }
            </ItemGroupCodeText>

          </ItemGroupDescriptionView>

        </PriceGroupContent>



        <ItemPriceView>

          <ItemPriceText>
            {itemGroup?.price > 0
              ? FormatMoney(itemGroup?.price)
              : '-'
            }
          </ItemPriceText>



          <BadgeSoldOut
            selected
            state={itemAvailableToSale}
            size={12}
          />

        </ItemPriceView>

      </ItemMapView>

    );
  }


  function renderFieldsItem(icon: any, title: string) {
    return (

      <ItemTypesOrderIcon>

        {icon}


        <ItemTypesViewOrderLabel>
          <ItemTypesOrderLabel>
            {title}
          </ItemTypesOrderLabel>
        </ItemTypesViewOrderLabel>

      </ItemTypesOrderIcon>

    );
  }



  return (

    <ItemDetailOverlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <OrderModalView>

        <HeaderModal
          heigth={47}
          title={translate(TranslateConfig.DETAILS_ITEM)}
          onPress={() => {
            if (props.onCancelPress) {
              props.onCancelPress();
            };
          }}
        />



        <ItemInfoContent>

          <ItemOrderViewImage>
            <ItemOrderImage
              alt={'Image'}
              src={validateString(props.item?.picture)
                ? props.item?.picture
                : Images.backgroundFooterAbout
              }
            />
          </ItemOrderViewImage>



          {(props.item?.alcoholic || props.item?.vegetarian || props.item?.vegan || props.item?.organic || props.item?.no_lactose || props.item?.no_glutem) && (
            <ItemTypesOrderView>

              {props.item?.alcoholic && renderFieldsItem(
                <IconItemAlcoholYes
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.ALCOHOLIC),
              )}


              {props.item?.spicy && renderFieldsItem(
                <IconItemSpicyYes
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.SPICY),
              )}


              {props.item?.vegetarian && renderFieldsItem(
                <IconItemVegetarianYes
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.VEGETARIAN),
              )}


              {props.item?.vegan && renderFieldsItem(
                <IconItemVeganYes
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.VEGAN),
              )}


              {props.item?.organic && renderFieldsItem(
                <IconItemOrganicYes
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.ORGANIC),
              )}


              {props.item?.no_lactose && renderFieldsItem(
                <IconItemLactoseNo
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.NO_LACTOSE),
              )}


              {props.item?.no_glutem && renderFieldsItem(
                <IconItemGlutenNo
                  color={Colors.white}
                  size={20}
                />,
                translate(TranslateConfig.NO_GLUTEN),
              )}

            </ItemTypesOrderView>
          )}



          {(props.item?.type === 'COMBO' || props.item?.double) && (
            <ItemBadgeView>

              {props.item?.type === 'COMBO' && renderItemBadge(
                translate(TranslateConfig.COMBO),
                Colors.attentionLight,
                Colors.black,
              )}

              {props.item?.double && renderItemBadge(
                translate(TranslateConfig.ITEM_DOUBLE),
                Colors.primaryDark,
                Colors.white,
              )}

            </ItemBadgeView>
          )}



          <ItemAllDetailsView>

            <ItemGroupsExtrasContent>

              <ItemTitleModal>
                {validateString(props.item?.name) || '-'}
              </ItemTitleModal>



              <ItemDescriptionText>
                {validateString(props.item?.description) || '-'}
              </ItemDescriptionText>



              <ItemPricesFooterContent>
                {obrigatoryArray?.map((itemGroup: any, index: number) => (
                  renderObrigatoryItem(itemGroup, index)
                ))}
              </ItemPricesFooterContent>

            </ItemGroupsExtrasContent>



            {availableUser && (
              <InputText
                type={'TEXT'}
                labelText={translate(TranslateConfig.COMMENTS)}
                placeholderText={translate(TranslateConfig.OPTIONAL)}
                countLimit={256}
                value={comments}
                onChange={(rawText: string) => {
                  setComments(rawText);
                }}
              />
            )}

          </ItemAllDetailsView>

        </ItemInfoContent>



        {availableUser && (
          <FooterAddCart
            selectItem={props?.selectProduct}
            onPress={(item: any, quantity: number) => {
              AddItemOrOpenLoginScreen(item, quantity);
            }}
          />
        )}

      </OrderModalView>

    </ItemDetailOverlay>

  );
};



export default MenuOrderDetails;
