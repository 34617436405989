import NoitadaWS from '../index';



export const GetMenuSheet = async (companyID: string) => {
  const url = `/app/menu-sheet/${companyID}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
