import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  eventMusicGenreArray,
} from '@noitada/shared/arrays/music-genre.array';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ChipApp from '../../../../../../components/Chip';

import {
  Epigraph,
  Input,
} from '../../../../../../components/Composh/web';

import {
  ChipView,
  DetailsSectionView,
} from '../../../../../../styles/styled.layout';



export interface IProps {
  loading?: boolean;
  setFieldValue: any;
}



const SectionMusicalGenre: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const loading = props.loading;
  const setFieldValue = props.setFieldValue;

  const [genres, setGenres] = useState<Array<any>>([]);



  function genresEvent(itemGenre: string) {
    const oldGenres = [...genres];
    const index = oldGenres.indexOf(itemGenre);
    if (index !== -1) {
      oldGenres.splice(index, 1);
    }
    else {
      oldGenres.push(itemGenre);
    }
    setGenres(oldGenres);
    setFieldValue('musicalGenres', oldGenres);
  }


  function renderChipItem(item: any, index: number) {
    const verifyItem = genres.some((valueItem) => valueItem === item.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={translate(item.label)}
        onPress={() => {
          genresEvent(item.value);
        }}
      />

    );
  }



  return (

    <DetailsSectionView>

      <Epigraph.Section
        text={translate(TranslateConfig.EVENT_MUSICAL_GENRES)}
        textColor={Colors.primary}
        borderBottomColor={Colors.primary}
      />


      <Input.View
        noShadow
        backgroundColor={Colors.transparent}
        countColor={Colors.textDescription}
        countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>
        <ChipView>

          {eventMusicGenreArray.map((item: any, index: number) => (
            renderChipItem(item, index)
          ))}

        </ChipView>
      </Input.View>

    </DetailsSectionView>

  );
};



export default SectionMusicalGenre;
