import NoitadaWS from '../index';



export const GetEntranceListWithUser = async (payload: any) => {
  const url = '/app/events-list/show-all-list';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};



export const CheckUserInList = async (userId: string, listId: string) => {
  const url = `/app/events-list/checkuser/${listId}/${userId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const EntranceListParticipate = async (listId: string, userId: string) => {
  const url = `/app/events-list/add-person/${listId}/${userId}`;

  const response = await NoitadaWS.put(url);
  return response.data;
};



export const EntranceListLeave = async (listId: string, userId: string) => {
  const url = `/app/events-list/remove-person/${listId}/${userId}`;

  const response = await NoitadaWS.delete(url);
  return response.data;
};



export const EntranceListInvite = async (listId: string, usersId: string[]) => {
  const url = `/app/events-list/changeinvitations/${listId}`;

  const response = await NoitadaWS.put(url, {
    users: usersId,
  });
  return response.data;
};



export const GetGuestsEventList = async (eventId: string, userId: string) => {
  const url = `/app/events-list/guests/${userId}/${eventId}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const GetDatesGroupReserves = async (period: string) => {
  const url = `/app/events-list/user?period=${period}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
