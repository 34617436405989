import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  color?: string;
}



export const CardPayInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


export const CardPayItemContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const CardPayItemIDText = styled.p`
  color: ${Colors.white};
  font-size: 17px;
  font-weight: 700;
  line-height: 21px;
`;



export const DetailsPaymentsHeader = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const DetailsPaymentsLabel = styled.p`
  display: flex;
  flex: 1;
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 17px;
`;



export const CardPayItemText = styled.p`
  color: #B4B4B4;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;
`;

export const CardPayUserText = styled.p`
  color: #B4B4B4;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
`;


export const CardTypeTaxView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const CardTypeTaxText = styled.p`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 16px;
  font-weight: 400;
`;
