import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CashlessOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding-top: 20px;
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
`;

export const CashlessOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;



export const CashlessItemContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 140px;
  margin-top: 10px;
  margin-bottom: 20px;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 15px;
  border-color: ${Colors.white};
  border-style: dashed;
  border-width: 4px;
  border-radius: ${Sizes.cardRadius}px;
`;

export const CashlessItemImage = styled.img`
  width: 73px;
  height: 73px;
  object-fit: contain;
`;

export const CashlessItemText = styled.p`
  margin-top: 13px;
  color: ${Colors.white};
  font-size: 15px;
  text-align: center;
`;



export const CashlessFooterOptionsText = styled.p`
  color: ${Colors.gray};
  font-size: 12px;
  text-align: center;
`;

export const CashlessFooterHelp = styled.a`
  margin-top: 10px;
  color: ${Colors.accent};
  font-size: 13px;
  font-weight: bold;
  text-align: center;
`;
