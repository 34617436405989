import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  DetailsCompanyContainer,
  DetailsCompanyContent,
  CompanyDetailsButton,
  DetailsAboutPlaceLogo,
  CompanyTitle,
  CompanyInformationButton,
} from './styled';



export interface IProps {
  name: string;
  image: string;
  onPress?: any;
};



const DetailsCardCompany: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  return (

    <DetailsCompanyContainer>

      <DetailsCompanyContent>

        <CompanyDetailsButton
          onClick={() => {
            if (props.onPress) {
              props.onPress();
            }
          }}>

          <DetailsAboutPlaceLogo
            src={validateString(props.image)
              ? props.image
              : Images.placeholderAvatarCompany
            }
          />


          <CompanyTitle>
            {props.name || '-'}
          </CompanyTitle>


          <CompanyInformationButton>
            {translate(TranslateConfig.ACTION_MORE_DETAILS).toUpperCase()}
          </CompanyInformationButton>

        </CompanyDetailsButton>

      </DetailsCompanyContent>

    </DetailsCompanyContainer>

  );
};



export default DetailsCardCompany;
