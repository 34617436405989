import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export const CleanBagButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 105px;
  margin-right: 7px;
`;

export const BagTotalTitle = styled.p`
  font-size: 13px;
  color: white;
`;

export const BagTotalText = styled.p`
  margin-top: -2px;
  color: white;
  font-size: 19px;
  font-weight: bold;
`;



export const HeaderBagView = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 13px;
  padding-right: 13px;
  padding-bottom: 15px;
`;



export const BagPlaceMinOrderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 40px;
  margin-top: 10px;
  border-top-color: ${Colors.toolbarLine};
  border-top-style: solid;
  border-top-width: 0.5px;
  border-bottom-color: ${Colors.toolbarLine};
  border-bottom-style: solid;
  border-bottom-width: 0.5px;
`;

export const BagPlaceMinOrderText = styled.p`
  color: ${Colors.textApp};
  font-size: 13.5px;
`;
