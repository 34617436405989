import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  PlatformDevices,
} from '@noitada/shared/utils/platforms.utils';

const rowTextSize = 14;



export interface IProps {
  disabled?: boolean;
  size?: number;
  color?: string;
}



export const InventoryTableStateView = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 28px;
  min-height: 28px;
  max-height: 28px;
  margin-top: 2px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 2px;
  padding-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 4px;
  background-color: ${Colors.white};
  border-color: ${(props: IProps) => props.color || Colors.gray};
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  opacity: ${(props: IProps) => props.disabled ? 0.5 : 1};

  @media ${PlatformDevices.minMobileL} {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const InventoryTableStateIcon = styled.div`
  display: flex;
  flex-direction: column;
  width: ${(props: IProps) => (props.size || rowTextSize) - 6}px;
  height:${(props: IProps) => (props.size || rowTextSize) - 6}px;
  margin-right: 6px;
  background-color: ${(props: IProps) => props.color || Colors.gray};
  border-radius: 50%;
`;

export const InventoryTableStateText = styled.p`
  color: ${(props: IProps) => props.color || Colors.gray};
  font-size: ${(props: IProps) => (props.size || rowTextSize) - 2}px;
  font-weight: bold;
`;
