import React from 'react';

import {
  LoginScreen,
  LoginViewContainer,
  LoginScreenMobileContainer,
} from './styled';



export interface IProps {
  backgroundImage?: string;
  backgroundColor?: string;
  children?: any;
}



const LoginContainer: React.FC<IProps> = (props) => {
  return (

    <LoginScreen
      backgroundColor={props.backgroundColor}
      backgroundImage={props.backgroundImage}>

      <LoginViewContainer>
        <LoginScreenMobileContainer>

          {props.children}

        </LoginScreenMobileContainer>
      </LoginViewContainer>

    </LoginScreen>

  );
};



export default LoginContainer;
