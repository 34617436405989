import NoitadaWS from '../index';



export const GetAllCompanies = async (payload: any) => {
  const url = '/app/companies';

  const response = await NoitadaWS.get(url, {
    params: payload,
  });
  return response.data;
};



export const GetCompany = async (companyShortIdUsernameUsername: string) => {
  const url = `/app/companies/${companyShortIdUsernameUsername}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
