import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetCarnivalCabins,
} from '@noitada/axios-config/endpoints/carnival.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  CARNIVAL_ACTIONSHEET_CIRCUITS,
  CARNIVAL_SELECTED_CIRCUITS,
} from '@noitada/redux-config/reducers/carnival-festivals.store';

import {
  CircuitTypeArray,
} from '@noitada/shared/arrays/carnival.array';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  ECarnivalCircuit,
} from '@noitada/shared/enums/Carnival/carnival-circuit.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  convertToDateHours,
  FormatIsoDateWithPatternDayjs,
  isValidDate,
} from '@noitada/shared/utils/date.utils';

import ButtonCityCarnival from '../../../../components/Buttons/ButtonCitiesApp/CityCarnival';
import ButtonSelect from '../../../../components/Buttons/ButtonSelect';
import CardCarnivalAccordionArtist from '../../../../components/Cards/CardCarnival/CardCarnivalAccordionArtist';
import CardCarnivalAccordionStage from '../../../../components/Cards/CardCarnival/CardCarnivalAccordionStage';
import CardCarnivalLounge from '../../../../components/Cards/CardCarnival/CardCarnivalLounge';

import {
  Container,
  Shimmer,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import CalendarHorizontal from '../../../../components/Composh/web/CalendarHorizontal';

import {
  ButtonCarnivalGuide,
  ButtonCarnivalMusic,
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../navigation/names';

import {
  ChangeEventTypeButton,
  ChangeEventTypeImage,
} from '../../../../styles/styled.layout';

import CarnivalActionSheet from '../ActionSheet';

import {
  HighlightMenuSeparator,
  HighlightsSeparator,
  ShimmerMargin,
  CabinCarnivalFlatList,
  CabinCarnivalAccordion,
  CabinCarnivalContentAccordion,
} from './styled';



const TabCabinsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const carnivalLocalities = useSelector((state: RootState) => state.localities.selected);

  const periodCarnival = useSelector((state: RootState) => state.carnivalFestivals.itemCarnival);
  const selectedCircuit = useSelector((state: RootState) => state.carnivalFestivals.selectedCircuit);


  const [actionSheetCircuitType, setActionSheetCircuitType] = useState<any>(null);
  const allTypes: Array<any> = CircuitTypeArray.filter((e) => e.id > 1);
  const [selectedType, setSelectedType] = useState<any | null>(null);

  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [allCircuits, setAllCircuits] = useState<Array<any>>([]);

  const [cabinsEvents, setCabinsEvents] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setupDistricts(responseAttractions: Array<any>, defaultCabin: any) {
    if (defaultCabin?.cabins?.length < 0 || responseAttractions?.length < 0) {
      throw { hasError: true, message: 'Parametros inválidos' };
    }

    if (defaultCabin) {
      if (responseAttractions) {
        const circuitFilter = responseAttractions?.find((itemCircuit: any) => String(itemCircuit?.key).toUpperCase() === String(defaultCabin?.key).toUpperCase());

        if (circuitFilter?.cabins?.length > 0) {
          setCabinsEvents(defaultCabin?.cabins);
        }
      }
    }
  }


  function renderCabinStageHeader(item: any, expanded: boolean, changeExpand: any) {
    return (

      <CardCarnivalAccordionStage
        expanded={expanded}
        canExpanded={item?.schedules?.length > 0}
        title={item?.name}
        date={FormatIsoDateWithPatternDayjs(item?.date_selected, 'DD/MM/YYYY')}
        description={item?.description}
        onPress={() => {
          changeExpand();
        }}
      />

    );
  }


  function renderCabinStageContent(item: any) {
    if (item?.schedules?.length <= 0 || item?.schedules === null || item?.schedules === undefined) {
      return null;
    }

    if (item?.schedules?.length > 0) {
      return (
        <CabinCarnivalContentAccordion>

          {item.schedules.map((itemSales: any, index: number) => {
            return (
              <CardCarnivalAccordionArtist
                key={index}
                confirmed={itemSales?.confirmed}
                name={itemSales?.name}
                date={itemSales?.date}
                hour={itemSales?.hour}
                picture={itemSales?.picture}
              />
            );
          })}

        </CabinCarnivalContentAccordion>
      );
    }
  }


  function renderAccordionHeader(item: any, expanded: boolean, changeExpand: any) {
    return (

      <CardCarnivalLounge
        expanded={expanded}
        canExpanded={item?.stages?.length > 0}
        title={item?.name}
        description={item?.description}
        cabinLogo={item?.picture}
        backgroundImage={item?.background}
        onPress={() => {
          changeExpand();
        }}
      />

    );
  }


  function renderAccordionContent(item: any) {
    if (item?.stages?.length <= 0 || item?.stages === null || item?.stages === undefined) {
      return null;
    }

    if (item?.stages?.length > 0) {
      return (
        <CabinCarnivalContentAccordion>

          {item?.stages.map((itemSales: any, index: number) => (
            <CabinCarnivalAccordion
              key={index}
              item={itemSales}
              renderHeader={(item: any, expanded: boolean, changeExpand: any) => renderCabinStageHeader(item, expanded, changeExpand)}
              renderContent={(item: any) => renderCabinStageContent(item)}
            />
          ))}

        </CabinCarnivalContentAccordion>
      );
    }
  }


  function renderAcordion(item: any, index: number) {
    return (

      <CabinCarnivalAccordion
        key={index}
        item={item}
        renderHeader={(item: any, expanded: boolean, changeExpand: any) => renderAccordionHeader(item, expanded, changeExpand)}
        renderContent={(item: any) => renderAccordionContent(item)}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function renderFooter() {
    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noAttractions}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_ATTRACTIONS)}
      />

    );
  }


  async function getCabins() {
    try {
      setLoading(true);

      const response = await GetCarnivalCabins({ ...carnivalLocalities, period: periodCarnival?.id });

      const listResponse = response;

      if (listResponse?.length > 0) {
        const allCabin: Array<any> = [];

        listResponse?.map((item: any, index: number) => {
          allCabin.push({
            id: index,
            label: item?.name,
            key: item?.key,
            value: index,
            cabins: item?.cabins,
            circuit: item?.circuit,
            type: item?.type,
          });
        });
        const defaultType = allTypes[0];
        setSelectedType(defaultType);
        const defaultCabin = allCabin.find((e) => e.type === defaultType.value);
        if (defaultCabin) {
          dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: defaultCabin });
          setupDistricts(allCabin, defaultCabin);
        }
        setAllCircuits(allCabin);
        const start_date = isValidDate(periodCarnival.start_at) ? convertToDateHours(periodCarnival.start_at, 9) : new Date();
        setSelectedDate(start_date);
      }
      else {
        dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: null });
        setAllCircuits([]);
      }
    }
    catch (error: any) {
      console.error(error);
      dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: null });
      setAllCircuits([]);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }



  useEffect(() => {
    if (periodCarnival?.id > 0) {
      const start_date = isValidDate(periodCarnival.start_at) ? convertToDateHours(periodCarnival.start_at) : new Date();
      const end_date = isValidDate(periodCarnival.end_at) ? convertToDateHours(periodCarnival.end_at) : new Date();
      setStartDate(start_date);
      setEndDate(end_date);
      setSelectedDate(start_date);
      getCabins();
    }
  }, []);


  useEffect(() => {
    setupDistricts(allCircuits, selectedCircuit);
  }, [selectedCircuit]);


  useEffect(() => {
    if (selectedType && allCircuits) {
      const defaultCircuit = allCircuits.find((e) => e.type === selectedType.value);
      if (defaultCircuit) {
        dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: defaultCircuit });
      }
    }
  }, [selectedType]);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityCarnival />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonCarnivalMusic
              color={Colors.white}
            />

            <ButtonCarnivalGuide
              color={Colors.white}
            />

            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        middleToolbar={
          <HighlightMenuSeparator>

            <HighlightsSeparator>

              <ShimmerMargin
                height={40}
                width={140}
                opacity={Sizes.shimmerOpacity}
                borderRadius={Sizes.cardRadius}
                visible={!loading}>

                <ButtonSelect
                  activeOpacity={Sizes.CardActiveOpacity}
                  disabled={loading || allTypes?.length <= 0}
                  title={translate(selectedType?.label) || '-'}
                  subtitle={translate(TranslateConfig.TYPE)}
                  onPress={() => {
                    if (allTypes?.length > 0) {
                      setActionSheetCircuitType({
                        title: `${translate(TranslateConfig.TYPE)}: ${allTypes?.length}`,
                        data: allTypes,
                      });
                    }
                  }}
                />

              </ShimmerMargin>



              <Shimmer
                height={40}
                width={140}
                opacity={Sizes.shimmerOpacity}
                borderRadius={Sizes.cardRadius}
                visible={!loading}>

                <ButtonSelect
                  activeOpacity={Sizes.CardActiveOpacity}
                  disabled={loading || allCircuits?.filter((e) => e.type === selectedType.value)?.length <= 0}
                  title={selectedCircuit?.label || '-'}
                  subtitle={selectedType?.value === ECarnivalCircuit.CIRCUIT ? translate(TranslateConfig.CIRCUIT) : translate(TranslateConfig.DISTRICTS)}
                  onPress={() => {
                    if (allCircuits?.filter((e) => e.type === selectedType.value)?.length > 0) {
                      dispatch({
                        type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: {
                          show: true,
                          title: `${translate(TranslateConfig.CIRCUIT)}: ${allCircuits?.filter((e) => e.type === selectedType.value)?.length}`,
                          data: allCircuits?.filter((e) => e.type === selectedType.value),
                        },
                      });
                    }
                  }}
                />

              </Shimmer>

            </HighlightsSeparator>


            <ChangeEventTypeButton
              onClick={() =>
                history.replace(NameRoutes.GridEventsScreen)
              }>
              <ChangeEventTypeImage
                alt={'change'}
                src={Images.noitadaHomeImageButton}
              />
            </ChangeEventTypeButton>

          </HighlightMenuSeparator>
        }
        noSubToolbarPadding>


        {!loading
          ? (
            <CalendarHorizontal
              color={Colors.accent}
              textColor={Colors.white}
              backgroundColor={[
                Colors.transparent,
                Colors.calendarColor,
              ]}
              selectedDate={selectedDate}
              startDate={startDate}
              endDate={endDate}
              onSelectDate={(dateFromCalendar: Date) => {
                setSelectedDate(dateFromCalendar);
              }}
            />
          )
          : <></>
        }

      </HeaderScreen>



      <CabinCarnivalFlatList
        loading={loading}
        data={cabinsEvents.filter((dataFilter) => {
          const date = FormatIsoDateWithPatternDayjs(selectedDate, 'YYYY-MM-DD');
          return dataFilter?.dates?.includes(date);
        }).map((e) => {
          const date = FormatIsoDateWithPatternDayjs(selectedDate, 'YYYY-MM-DD');
          return {
            ...e,
            stages: e?.stages.filter((f) => f?.date === date),
          };
        })}
        renderItem={renderAcordion}
        listLoadingComponent={renderLoading()}
        listFooterComponent={renderFooter()}
        listEmptyComponent={listEmptyComponent()}
      />

      <CarnivalActionSheet
        visible={actionSheetCircuitType}
        title={actionSheetCircuitType?.title}
        data={actionSheetCircuitType?.data}
        onClose={() => {
          setActionSheetCircuitType(null);
        }}
        onPress={(item) => {
          setSelectedType(item);
          setActionSheetCircuitType(null);
        }}
      />

    </Container>

  );
};



export default TabCabinsScreen;
