import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonAction from '../../../../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../../../../components/Composh/web';

import CopiedItem from '../../../../../components/Contents/CopiedItem';

import {
  IconCopy,
} from '../../../../../components/Icons';

import {
  WifiModalContainer,
  WifiModalImage,
  WifiModalTitle,
  WifiModalSpanTitle,
  WifiModalInfoContent,
  WifiCompanyModalImage,
  WifiCompanyModalName,
  WifiCompanyModalRouteName,
  WifiCompanyModalCyanText,
  WifiCopyButton,
  WifiCopyText,
} from './styled';



export interface IProps {
  visible?: boolean;
  name: string;
  picture?: string;
  companyName?: string;
  wifiName?: string;
  wifiPassword?: string;
  onClose?: any;
}



const WifiModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }


  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const [copied, setCopied] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function copyPassword() {
    setCopied(true);
    navigator.clipboard.writeText(props.wifiPassword || '');
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_PASSWORD_COPY));

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <WifiModalContainer>

        <WifiModalImage
          src={Images.wifi}
        />


        <WifiModalTitle>
          {translate(TranslateConfig.HAVE_WIFI)},
          {' '}
          <WifiModalSpanTitle>
            {props.name || '-'}!
          </WifiModalSpanTitle>
        </WifiModalTitle>



        <WifiModalInfoContent>

          <WifiCompanyModalImage
            src={props.picture === '' || props.picture === null || props.picture === undefined
              ? Images?.placeholderAvatarCompany
              : props.picture
            }
          />



          <WifiCompanyModalName>
            {props.companyName}
          </WifiCompanyModalName>



          <WifiCompanyModalRouteName>
            <WifiCompanyModalCyanText>
              {translate(TranslateConfig.WIFI)}:
            </WifiCompanyModalCyanText>
            {' '}
            {props.wifiName}
          </WifiCompanyModalRouteName>



          <WifiCompanyModalRouteName>
            <WifiCompanyModalCyanText>
              {translate(TranslateConfig.PASSWORD)}:
            </WifiCompanyModalCyanText>
            {' '}
            {props.wifiPassword}
          </WifiCompanyModalRouteName>



          <WifiCopyButton
            onClick={() => {
              copyPassword();
            }}>

            <CopiedItem
              copied={copied}
              icon={
                <IconCopy
                  size={25}
                  color={Colors.accent}
                />
              }
            />

            <WifiCopyText>
              {translate(TranslateConfig.ACTION_COPY_PASSWORD)}
            </WifiCopyText>

          </WifiCopyButton>

        </WifiModalInfoContent>



        <ButtonAction
          width={150}
          activeOpacity={Sizes.CardActiveOpacity}
          title={translate(TranslateConfig.ACTION_CLOSE)}
          onPress={props.onClose}
        />

      </WifiModalContainer>

    </Overlay>

  );
};



export default WifiModal;
