import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import AlertMessage from '../../../../components/Alerts';

import {
  Body,
  Container,
  Screen,
  StepIndicator,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import InstallmentsModal from '../../../../modals/Installments';
import ModalFinished from '../../../../modals/ModalFinished';
import PaymentsModal from '../../../../modals/Payments';

import {
  ButtonText,
} from '../../../../styles/styled.layout';

import TabListEntrances from './Tabs/Tab1List';
import Tab2Identity from './Tabs/Tab2Identify';
import TabChoosePayment from './Tabs/Tab3Payment';
import TabCheckout from './Tabs/Tab4Resume';



const BuyTicketsScreen: React.FC = () => {
  const history = useHistory();
  const { t: translate } = useTranslation();


  const event = useSelector((state: RootState) => state.event.selected);


  const eventTicketsArray = event.event_tickets;

  const steps = [
    'Ingressos',
    'Identificação',
    'Pagamento',
    'Resumo',
  ];
  const [stepsValidity, setStepsValidity] = useState([
    false,
    false,
    false,
    false,
  ]);

  const [currentStep, setCurrentStep] = useState(0);

  const [selectedTicket, setSelectedTicket] = useState<any>(null);

  const [installmentArray, setInstallmentArray] = useState<any>([]);
  const [installmentObject, setInstallmentObject] = useState<any | null>(null);
  const [showInstallmentModal, setShowInstallmentModal] = useState<boolean>(false);

  const [cardItem, setCardItem] = useState<any>(null);
  const [mainCreditCard, setMainCreditCard] = useState<any>(null);
  const [showCreditCardModal, setShowCreditCardModal] = useState<boolean>(false);

  const [paidTicketsModal, setPaidTicketsModal] = useState<boolean>(false);

  const [showTicketsBackModal, setShowTicketsBackModal] = useState<boolean>(false);


  const isStepValid = (step: number) => stepsValidity[step];


  const setStepValid = (step: number, isValid: boolean) => {
    const newValidity = [...stepsValidity];
    newValidity[step] = isValid;
    setStepsValidity(newValidity);
  };


  const handleStepPress = (step: number) => {
    if (step < currentStep || isStepValid(step)) {
      setCurrentStep(step);
    }
  };


  // Avançar ou voltar steps
  const okButtonPress = () => {
    if (currentStep < steps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
    else {
      // No último passo: finalizar
      setPaidTicketsModal(true);
    }
  };


  function onTicktsBack() {
    history.goBack();
  }


  const cancelButtonPress = () => {
    if (currentStep === 0) {
      history.goBack();
    }
    else {
      setCurrentStep(currentStep - 1);
    }
  };



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                setShowTicketsBackModal(true);
              }}
            />
          }
          centerContent={
            <Body>
              <Title>
                Comprar ingressos
              </Title>

              <SubTitle>
                {event?.name || '-'}
              </SubTitle>
            </Body>
          }
          noSubToolbarPadding>

          {currentStep !== 4 && (
            <StepIndicator
              data={steps}
              stepCurrent={currentStep}

              textColorPassed={Colors.white}
              colorPassed={Colors.secondary}

              colorAccent={Colors.primary}
              colorMoreStep={Colors.white}

              stepsValidity={stepsValidity}
              onStepPress={handleStepPress}
            />
          )}

        </HeaderScreen>



        {currentStep === 0 && (
          <TabListEntrances
            selectedId={selectedTicket?.id}
            tickets={eventTicketsArray}
            onPress={(id: number) => {
              // Marca o step como válido
              setStepValid(0, true);
            }}
          />
        )}


        {currentStep === 1 && (
          <Tab2Identity
            onPress={(isValid) => {
              // Atualiza a validade do step 
              setStepValid(1, isValid);
            }}
          />
        )}


        {currentStep === 2 && (
          <TabChoosePayment
            mainCreditCard={mainCreditCard}
            openCreditCardModal={(openModal: boolean) => {
              if (openModal) {
                setShowCreditCardModal(true);
              }
            }}

            installmentReceived={installmentObject}
            openInstallmentModal={(openModal: boolean) => {
              if (openModal) {
                setShowInstallmentModal(true);
              }
            }}

            onPress={(isValid) => {
              // Atualiza a validade do step 
              setStepValid(2, isValid);
            }}
          />
        )}


        {currentStep === 3 && (
          <TabCheckout
            onPress={(isValid) => {
              // Atualiza a validade do step 
              setStepValid(3, isValid);
            }}
          />
        )}



        <FooterChoose
          hideCancelButton={currentStep === 3 || currentStep === 4}

          cancelColor={ArrayColors.arrayCancel}
          cancelLabel={
            <ButtonText
              color={Colors.white}>
              {currentStep === 0 ? 'Cancelar' : 'Voltar'}
            </ButtonText>
          }
          cancelPress={cancelButtonPress}

          okColor={Colors.buttonOk}
          okLabel={
            <ButtonText
              color={Colors.white}>
              {currentStep === 3 ? 'Finalizar' : 'Avançar'}
            </ButtonText>
          }
          okPress={okButtonPress}
        />

      </Container>



      <AlertMessage
        visible={showTicketsBackModal}
        title={translate('Voltar a lista de Ingressos?')}
        description={translate('Ao voltar, os ingressos selecionados não estarão mais reservados e você terá que efetuar uma nova compra.')}
        cancelText={translate(TranslateConfig.ACTION_NO)}
        cancelPress={() => {
          setShowTicketsBackModal(false);
        }}
        okText={translate(TranslateConfig.ACTION_YES)}
        okPress={() => {
          setShowTicketsBackModal(false);
          onTicktsBack();
        }}
      />



      <PaymentsModal
        visible={showCreditCardModal}
        data={cardItem}
        onCancelPress={() => {
          setShowCreditCardModal(false);
        }}
        onPress={(card: any) => {
          setMainCreditCard(card);
          setShowCreditCardModal(false);
        }}
      />



      <InstallmentsModal
        visible={showInstallmentModal}
        data={installmentArray}
        onCancelPress={() => {
          setShowInstallmentModal(false);
        }}
        onPress={(installment: any) => {
          setInstallmentObject(installment);
          setShowInstallmentModal(false);
        }}
      />



      <ModalFinished
        visible={paidTicketsModal}
        title={translate('Pago!')}
        buttonText={translate(TranslateConfig.ACTION_CLOSE)}
        onPress={() => {
          onTicktsBack();
        }}
      />

    </Screen>

  );
};



export default BuyTicketsScreen;
