import React from 'react';

import {
  ActionViewButton,
  ActionViewText,
} from './styled';



export interface IProps {
  activeOpacity?: number;
  disabled?: boolean;
  width?: number;
  height?: number;
  backgroundColor?: string;
  noShadow?: boolean;

  title: string;
  onPress?: any;
}



const ButtonAction: React.FC<IProps> = (props: IProps) => {
  // const activeOpacityButton = props.activeOpacity;



  return (

    <ActionViewButton
      // activeOpacity={activeOpacityButton}
      // disabled={props.disabled}
      width={props.width}
      height={props.height}
      backgroundColor={props.backgroundColor}
      noShadow={props.noShadow}
      onClick={() => {
        if (!props.disabled && props.onPress) {
          props.onPress();
        }
      }}>

      <ActionViewText>
        {props.title}
      </ActionViewText>

    </ActionViewButton>

  );
};



export default ButtonAction;
