import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  disabled?: boolean;
}



export const CardBagCountView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 35px;
  background-color: ${(props: IProps) => props.disabled ? Colors.disabled : Colors.buttonOkDark};
  border-radius: ${Sizes.cardRadius}px;
`;

export const CardBagCountButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 100%;
`;

export const CardBagCountText = styled.p`
  width: 30px;
  margin-left: 6px;
  margin-right: 6px;
  color: ${Colors.white};
  font-size: 19px;
  font-weight: bold;
  text-align: center;
`;
