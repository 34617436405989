import React from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_DRAWER_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_LANGUAGE_MODAL,
} from '@noitada/redux-config/reducers/localities.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconArchived,
  IconBack,
  IconBackSkip,
  IconCarnivalGuide,
  IconCarnivalMusic,
  IconClose,
  IconConfig,
  IconDelete,
  IconDrawer,
  IconFavoriteOff,
  IconFavoriteOn,
  IconHelp,
  IconLanguages,
  IconMore,
  IconNotifications,
  IconSearch,
  IconShare,
} from '../../components/Icons';

import NameRoutes from '../../navigation/names';

import {
  BadgeAbsoluteView,
  BadgeText,
} from '../../styles/styled.layout';

import {
  ButtonControl,
} from './styled';



export interface IProps {
  close?: boolean;
  active?: boolean;
  onPress?: any;
  color?: string;
  size?: number;
}



export const ButtonArchived: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconArchived
        color={props.color}
      />

    </ButtonControl>

  );
};



export const ButtonBack: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();



  function chooseIcon() {
    if (props.close) {
      return (
        <IconClose
          color={props.color}
        />
      );
    }
    else {
      return (
        <IconBack
          color={props.color}
        />
      );
    }
  }

  function haveOnPress() {
    if (props.onPress) {
      props.onPress();
      return;
    }

    if (history.length > 2) {
      history.goBack();
      return;
    }

    history.replace(NameRoutes.GridEventsScreen);
  }


  return (

    <ButtonControl
      onClick={() => {
        haveOnPress();
      }}>

      {chooseIcon()}

    </ButtonControl>

  );
};



export const ButtonBackSkip: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();


  function haveSkipPress() {
    if (!props.onPress) {
      history.goBack();
    }
    else {
      props.onPress();
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        haveSkipPress();
      }}>

      <IconBackSkip
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonBuild: React.FC<IProps> = (props: IProps) => {
  // const history = useHistory();



  return (

    <ButtonControl
      onClick={() => {
        if (props.onPress) {
          props.onPress();
          // return;
        }

        // history.push(NameRoutes.PreferencesScreen);
      }}>

      <IconConfig
        color={props.color}
      />

    </ButtonControl>

  );
};



export const ButtonCarnivalGuide: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();


  return (

    <ButtonControl
      onClick={() => {
        history.push(NameRoutes.CarnivalGuidesScreen);
      }}>

      <IconCarnivalGuide
        color={props.color}
      />

    </ButtonControl>

  );
};



export const ButtonCarnivalMusic: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  function openMenuPage() {
    if (availableUser) {
      history.push(NameRoutes.CarnivalVotesListScreen);
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        openMenuPage();
      }}>

      <IconCarnivalMusic
        color={availableUser
          ? props.color
          : 'gray'
        }
      />

    </ButtonControl>

  );
};



export const ButtonDelete: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconDelete
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonDrawer: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;


  function openMenuPage() {
    if (availableUser) {
      dispatch({ type: APP_DRAWER_ACTION, payload: true });
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        openMenuPage();
      }}>

      <IconDrawer
        color={availableUser
          ? props.color
          : Colors.gray
        }
      />

    </ButtonControl>

  );
};



export const ButtonFavorite: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      {props.active
        ? (
          <IconFavoriteOn
            color={props.color}
          />
        )
        : (
          <IconFavoriteOff
            color={props.color}
          />
        )
      }

    </ButtonControl>

  );
};



export const ButtonHelp: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconHelp
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonLanguage: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();


  function onOpenTranslateModal() {
    dispatch({ type: USER_LANGUAGE_MODAL, payload: true });
  }


  return (

    <ButtonControl
      onClick={() => {
        onOpenTranslateModal();
      }}>

      <IconLanguages
        color={props.color}
        size={props.size}
      />

    </ButtonControl>

  );
};



export const ButtonMore: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();



  return (

    <ButtonControl
      onClick={() => {
        history.push(NameRoutes.PreferencesScreen);
      }}>

      <IconMore
        color={props.color}
      />

    </ButtonControl>

  );
};



export const ButtonNotifications: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();

  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const notifications = 0;


  function openNotificationsPage() {
    if (availableUser) {
      history.push(NameRoutes.NotificationsScreen);
    }
    else {
      history.push(NameRoutes.AccessScreen);
    }
  }


  return (

    <ButtonControl
      onClick={() => {
        openNotificationsPage();
      }}>

      <IconNotifications
        color={availableUser
          ? props.color
          : 'gray'
        }
      />

      {availableUser && notifications ? (
        <BadgeAbsoluteView>
          <BadgeText>
            {(notifications > 0 && notifications < 100) ? notifications : '+99'}
          </BadgeText>
        </BadgeAbsoluteView>
      ) : null}

    </ButtonControl>

  );
};



export const ButtonSearch: React.FC<IProps> = (props: IProps) => {
  const history = useHistory();



  return (

    <ButtonControl
      onClick={() => {
        history.push(NameRoutes.SearchScreen);
      }}>

      <IconSearch
        color={props.color}
      />

    </ButtonControl>

  );
};



export const ButtonShare: React.FC<IProps> = (props: IProps) => {
  return (

    <ButtonControl
      onClick={props.onPress}>

      <IconShare
        color={props.color}
      />

    </ButtonControl>

  );
};
