import React from 'react';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  isBirthdayToday,
} from '@noitada/shared/utils/date.utils';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  IconVerified,
} from '../../Icons';

import BadgeLevel from '../../Level/BadgeLevel';

import {
  CardInfoPersonContainer,
  CardInfoPersonImageView,
  CardInfoPersonImage,
  CardInfoPersonTypeBadge,
  CardReserveInfoView,
  CardInfoPersonInfoView,
  CardInfoPersonRowView,
  CardInfoPersonUsernameText,
  CardInfoPersonVerifiedIcon,
  CardInfoPersonCakeImage,
  CardInfoPersonPublicNameText,
} from './styled';



export interface IProps {
  enabled?: boolean;
  image?: string;
  name?: string;
  username?: string;
  birthday?: string;
  verified?: boolean;
  level: string;
  deleteIcon?: any;
}



const CardUserFound: React.FC<IProps> = (props: IProps) => {
  return (

    <CardInfoPersonContainer>

      <CardInfoPersonImageView>

        <CardInfoPersonImage
          src={validateString(props.image)
            ? props.image
            : Images.placeholderAvatarUser
          }
        />

        <CardInfoPersonTypeBadge>
          <BadgeLevel
            level={props.level}
          />
        </CardInfoPersonTypeBadge>

      </CardInfoPersonImageView>



      <CardReserveInfoView>

        <CardInfoPersonInfoView>

          <CardInfoPersonRowView>

            <CardInfoPersonUsernameText>
              {props.username || '-'}
            </CardInfoPersonUsernameText>



            {props.verified && (
              <CardInfoPersonVerifiedIcon>
                <IconVerified
                  color={Colors.primary}
                  size={15}
                />
              </CardInfoPersonVerifiedIcon>
            )}

          </CardInfoPersonRowView>



          <CardInfoPersonRowView>
            {isBirthdayToday(props.birthday) && (
              <CardInfoPersonCakeImage
                src={Images.stickerCake}
              />
            )}


            <CardInfoPersonPublicNameText>
              {props.name || '-'}
            </CardInfoPersonPublicNameText>
          </CardInfoPersonRowView>

        </CardInfoPersonInfoView>



        {props.deleteIcon}

      </CardReserveInfoView>

    </CardInfoPersonContainer>

  );
};



export default CardUserFound;;
