import axios,
{
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';

import {
  storePersisted,
} from '@noitada/redux-config';

import {
  USER_LOGGED,
  USER_LOGOUT_STATE,
  USER_SET,
  USER_UPDATE,
} from '@noitada/redux-config/reducers/user.store';

import {
  ETokenAsync,
} from '@noitada/shared/enums';

import AppEnvironments from '@noitada/shared/environments/application';



function NoitadaWS() {
  const baseURL = AppEnvironments().apiUrl;

  const typeWS: 'SITE' | 'MOBILE' = 'SITE';
  let token: string | null;


  const axiosApplicationServer = axios.create({
    baseURL,
  });



  axiosApplicationServer.interceptors.request.use((config: AxiosRequestConfig) => {
    if (typeWS === 'SITE') {
      token = localStorage.getItem(ETokenAsync.USER_TOKEN);
    }
    // else if (typeWS === 'MOBILE') {
    //   token = AsyncStorage.getItem(ETokenAsync.TOKEN);
    //   console.log('MOBILE-TOKEN', token);
    // }
    // else {
    //   console.error('Error');
    // }

    if (token) {
      if (config.headers) {
        config.headers.authorization = token;
      }
    }

    return config;
  });



  axiosApplicationServer.interceptors.response.use(
    (response: AxiosResponse<any>) => {
      return response;
    },
    (error: any) => {
      if (!error?.response) {
        const response = {
          message: 'Internal server error',
          data: error,
        };

        throw response;
      }

      const { status } = error?.response;

      if (typeWS === 'SITE') {
        if (status === 401) {
          localStorage.clear();

          storePersisted.dispatch({ type: USER_SET, payload: {} });
          storePersisted.dispatch({ type: USER_UPDATE, payload: {} });
          storePersisted.dispatch({ type: USER_LOGGED, payload: false });
          storePersisted.dispatch({ type: USER_LOGOUT_STATE, payload: false });
        }
      }

      throw error?.response?.data;
    },
  );



  return axiosApplicationServer;
};



export default NoitadaWS();
