import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const ChipView = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-left: 3px;
  padding-right: 3px;
`;



// Details Views

export const DetailsSectionView = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 8px;
`;

export const DetailsTopViewItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 8px;
`;

export const SocialButton = styled.a`
  display: flex;
  height: 42px;
  width: 42px;
  align-items: center;
  justify-content: center;
  margin-left: 6px;
  margin-right: 6px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;



// Details Text And Shimmers

export const SubtitleDetails = styled.p`
  margin-top: 2px;
  margin-bottom: 2px;
  color: ${Colors.textTitle};
  font-size: ${Sizes.TextTitle}px;
  font-weight: bold;
  line-height: 26px;
`;



export const RowLine = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
`;

export const RowFlexLine = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const ViewSeparator = styled.div`
  margin-top: 5px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 5px;
`;



// Badge Style

export const BadgeView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
  min-width: 16px;
  background: ${Colors.danger};
  border-radius: 10px;
`;

export const BadgeAbsoluteView = styled(BadgeView)`
  position: absolute;
  top: 7px;
  right: 2px;
`;

export const BadgeText = styled.p`
  margin-top: -1px;
  padding-left: 4px;
  padding-right: 4px;
  color: white;
  font-size: 10.5px;
  font-weight: 700;
`;



// Footer Styles

export const FooterStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
`;

export const Footer = styled(FooterStyle)`
  height: 58px;
`;

export const FooterMini = styled(FooterStyle)`
  height: 35px;
  min-height: 35px;
`;



export const RequiredText = styled.p`
  margin-bottom: 5px;
  color: ${Colors.buttonCancelAccent};
  font-size: 11px;
  text-align: right;
`;



export const FooterDescriptionStyle = styled.p`
  color: ${Colors.textSubDescription};
  font-style: italic;
  font-size: 12px;
`;

export const FooterDescription = styled(FooterDescriptionStyle)`
  margin-left: 10px;
  margin-bottom: 15px;
  text-align: right;
`;



// Button

export const ButtonPrimary = styled.a`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${Sizes.buttonHeight}px;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
  background-color: ${(props: IProps) => props.backgroundColor || '#000000'};
  border-radius: 3px;
  border-width: 0;
  z-index: 0;
`;

// export const ButtonLinearPrimary = styled(LinearGradient)`
//   height: ${Sizes.buttonHeight}px;
//   width: 100%;
//   align-items: center;
//   justify-content: center;
//   border-radius: 3px;
//   margin-top: 5px;
//   margin-bottom: 5px;
//   z-index: 0;
// `;

export const ButtonSecondary = styled(ButtonPrimary)`
  border-bottom-width: 3px;
  z-index: 0;
`;

export const ButtonText = styled.p`
  color: ${(props: IProps) => props.color || Colors.white};
  font-size: 13.5px;
  line-height: 16px;
  text-align: center;
`;



// ---------------------------------------------------------------------



export const SubtitleText = styled.p`
  margin-top: 3px;
  color: ${Colors.textApp};
  font-size: ${Sizes.TextSubtitle}px;
  line-height: 20.5px;
  white-space: pre-wrap;
`;


// Common Styles

export const SettingsHelpText = styled.h1`
  margin-top: 5px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 25px;
  color: white;
  font-size: 14px;
  text-align: center;
`;



export const ContentPadder = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Sizes.paddingContainer}px;
`;

export const ContentPadderHorizontal = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;



// Carnival / Festival Stykes

export const ChangeEventTypeButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: -5px;
  margin-left: 4px;
  margin-right: 4px;
`;

export const ChangeEventTypeImage = styled.img`
  width: 45px;
  height: 45px;
  object-fit: contain;
`;
