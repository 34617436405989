import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetDetailsEventByIdShort,
} from '@noitada/axios-config/endpoints/events.endpoints';

import {
  PostFavoriteEvent,
} from '@noitada/axios-config/endpoints/favorites.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  FAVORITES_SET,
} from '@noitada/redux-config/reducers/favorites.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EEventOcurrence,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import {
  Body,
  Container,
  Screen,
  SubTitle,
  Title,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonBack,
  ButtonFavorite,
  ButtonLanguage,
  ButtonShare,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import ShareModal from '../../../modals/Share';

import NameRoutes from '../../../navigation/names';

import ActiveEventScreen from './Active';
import FinishedEventScreen from './Finished';

import {
  FavoriteEventOpacityView,
} from './styled';



const DetailsEventScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const {
    event_id,
  } = useParams() as any;

  const idEventSearch = event_id;


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const favoritesUser: Array<any> = useSelector((state: RootState) => state.favorites.actualFavorites);
  const verifyItem = favoritesUser?.length > 0 ? favoritesUser?.some((valueItem) => valueItem?.relationId === event?.id) : false;


  const [event, setEvent] = useState<any | null>(null);
  const [installments, setInstallments] = useState<Array<any>>([]);

  const [showShare, setShowShare] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);

  const publishedEvent = event?.status !== EEventOcurrence.FINISHED && event?.status !== EEventOcurrence.CANCELED;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getEventByParams() {
    try {
      setLoading(true);

      const response = await GetDetailsEventByIdShort(idEventSearch);
      const responseData = response?.data;

      const eventsResponse = responseData?.event;
      const installmentsResponse = responseData?.installments;

      if (!eventsResponse) {
        setEvent(null);
        setInstallments([]);
        return;
      }

      setEvent(eventsResponse);
      setInstallments(installmentsResponse);
    }
    catch (error: any) {
      console.error(error);
      setEvent(null);
      setInstallments([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_EVENTS_NOT_FOUND)}
      />

    );
  }


  async function onFavoritePress() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const data = await PostFavoriteEvent(availableUser, event?.id);

      if (data && !data?.hasError) {
        dispatch({ type: FAVORITES_SET, payload: data || [] });

        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.SUCCESS_FAVORITE_REMOVE)
            : translate(TranslateConfig.SUCCESS_FAVORITE_ADD),
        );
      }
      else {
        showSnackBarProps(Colors.accept,
          verifyItem
            ? translate(TranslateConfig.ERROR_FAVORITE_REMOVE)
            : translate(TranslateConfig.ERROR_FAVORITE_ADD),
        );
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function renderFavoriteButton() {
    if (!availableUser) {
      return (

        <FavoriteEventOpacityView>
          <ButtonFavorite
            color={Colors.white}
            onPress={() => {
              history.push(NameRoutes.AccessScreen);
            }}
          />
        </FavoriteEventOpacityView>

      );
    }


    return (

      <ButtonFavorite
        active={verifyItem}
        color={verifyItem ? Colors.secondary : Colors.white}
        onPress={() => {
          onFavoritePress();
        }}
      />

    );
  }



  useEffect(() => {
    getEventByParams();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Body>
              <Title>
                {event?.name || '-'}
              </Title>

              <SubTitle>
                {event?.company?.company_username
                  ? `@${event?.company?.company_username}`
                  : '-'
                }
              </SubTitle>
            </Body>
          }
          rightIcon={
            <ToolbarGroup>
              {publishedEvent && renderFavoriteButton()}


              <ButtonLanguage
                color={Colors.white}
              />


              {publishedEvent && (
                <ButtonShare
                  color={Colors.white}
                  onPress={() => {
                    setShowShare(true);
                  }}
                />
              )}

            </ToolbarGroup>
          }
        />



        {loading && (
          renderLoading()
        )}



        {!loading && !event && (
          listEmptyComponent()
        )}



        {!loading && event && publishedEvent && (
          <ActiveEventScreen
            event={event}
            installments={installments}
          />
        )}



        {!loading && event && !publishedEvent && (
          <FinishedEventScreen
            event={event}
          />
        )}

      </Container>



      <ShareModal
        type={'EVENT'}
        visible={showShare}
        idEvent={event?.idShort}
        name={event?.name}
        image={event?.picture}
        onClose={() => {
          setShowShare(false);
        }}
      />

    </Screen>

  );
};



export default DetailsEventScreen;
