import NoitadaWS from '../index';



export const GetFavoriteCompanies = async () => {
  const url = '/app/favorite/companies';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const PostFavoriteCompany = async (idUserReceived: string, idCompany: any) => {
  const url = '/app/favorite/companies';

  const payload = {
    id: idCompany,
    idUser: idUserReceived,
  };

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};



export const GetFavoriteEvents = async () => {
  const url = '/app/favorite/events';

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const PostFavoriteEvent = async (idUserReceived: string, idEvent: any) => {
  const url = '/app/favorite/events';

  const payload = {
    id: idEvent,
    idUser: idUserReceived,
  };

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};
