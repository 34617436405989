import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetEntranceListWithUser,
} from '@noitada/axios-config/endpoints/entrance-list.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardLists from '../../../../components/Cards/CardLists';

import {
  Body,
  Container,
  Epigraph,
  Screen,
  SubTitle,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../navigation/names';

import {
  ButtonText,
  ContentPadder,
  DetailsSectionView,
} from '../../../../styles/styled.layout';

import ListParticipateScreen from './Participate';

import {
  ListEventFlatList,
} from './styled';



const JoinListsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const {
    event_id,
  } = useParams() as any;

  const idEventSearch = event_id;


  const user = useSelector((state: RootState) => state.user.actualUser);
  const availableUser = user?.id;

  const [event, setEvent] = useState<any | null>(null);

  const [normalLists, setNormalLists] = useState<Array<any>>([]);
  const [differentLists, setDifferentLists] = useState<Array<any>>([]);

  const [openParticipate, setOpenParticipate] = useState<any | null>(null);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function onPressGetList() {
    try {
      setLoading(true);

      const payload = {
        eventId: idEventSearch,
        userId: availableUser ? user?.id : null,
      };

      const response = await GetEntranceListWithUser(payload);
      const responseData = response;

      if (!responseData) {
        setEvent(null);

        setNormalLists([]);
        setDifferentLists([]);
        return;
      }

      setEvent(responseData?.event);

      setNormalLists(responseData?.lists?.filter((list) => !list.special));
      setDifferentLists(responseData?.lists?.filter((list) => list.special));
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }

  async function onCloseAndRecall() {
    setNormalLists([]);
    setOpenParticipate(false);
    setDifferentLists([]);
    await onPressGetList();
  }


  function renderItem(item: any, index: number) {
    return (

      <CardLists
        key={index}
        disabled={!availableUser}
        name={item?.name}
        finalDate={item?.finishDate}
        description={item?.description}

        inList={item?.inList}
        onPress={() => {
          if (availableUser) {
            setOpenParticipate(item);
          }
        }}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.EMPTY_LISTS)}
      />

    );
  }


  // async function refreshList() {
  //   setRefreshing(true);
  //   await getRules();
  //   setRefreshing(false);
  // }


  useEffect(() => {
    onPressGetList();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
            />
          }
          centerContent={
            <Body>
              <Title>
                {translate(TranslateConfig.LISTS_EVENT)}
              </Title>

              <SubTitle>
                {event?.name || '-'}
              </SubTitle>
            </Body>
          }
        />



        <ListEventFlatList style={{
          justifyContent: loading ? 'center' : 'flex-start',
        }}>


          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {!loading && normalLists?.length === 0 && differentLists?.length === 0 && (
            listEmptyComponent()
          )}



          {!loading && (normalLists?.length !== 0 || differentLists?.length !== 0) && (
            <ContentPadder>

              <Title
                color={Colors.white}>
                {translate(TranslateConfig.LISTS_ALL)}
              </Title>



              {normalLists && normalLists.length > 0 && (
                <DetailsSectionView>
                  <Epigraph.Section
                    text={translate(TranslateConfig.LISTS_AVAILABLES)}
                    textColor={Colors.primary}
                    borderBottomColor={Colors.primary}
                  />

                  {normalLists && normalLists.length > 0 && normalLists.map((item: any, index: number) =>
                    renderItem(item, index),
                  )}
                </DetailsSectionView>
              )}



              {differentLists && differentLists.length > 0 && (
                <DetailsSectionView>
                  <Epigraph.Section
                    text={translate(TranslateConfig.LISTS_SPECIALS)}
                    textColor={Colors.primary}
                    borderBottomColor={Colors.primary}
                  />

                  {differentLists && differentLists.length > 0 && differentLists.map((item: any, index: number) =>
                    renderItem(item, index),
                  )}
                </DetailsSectionView>
              )}

            </ContentPadder>
          )}

        </ListEventFlatList>



        {!availableUser && (
          <FooterChoose
            hideCancelButton

            okColor={ArrayColors.arrayAction}
            okPress={() => {
              history.push(NameRoutes.AccessScreen);
            }}
            okLabel={
              <ButtonText
                color={Colors.white}>
                {translate(TranslateConfig.ACTION_ACCESS_REGISTER)}
              </ButtonText>
            }
          />
        )}

      </Container>



      <ListParticipateScreen
        visible={openParticipate}
        list={openParticipate}
        onCloseAndRecall={() => {
          onCloseAndRecall();
        }}
        editable={true}
        idUser={user?.id}
        canInvite={openParticipate?.canInvite}
        inList={openParticipate?.inList}
        onCancelPress={() => {
          setOpenParticipate(null);
        }}
      />

    </Screen>

  );
};



export default JoinListsScreen;
