import NoitadaWS from '../index';



export const GetOrderUser = async (type: string) => {
  const url = `/app/orders-sheet/user?type=${type}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};



export const AddItem = async (payload: any) => {
  const url = '/app/orders-sheet/order';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};



export const PayInAppRequest = async (payload: any) => {
  const url = '/app/orders-sheet/payment';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};
