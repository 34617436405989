import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Screen,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import HeaderScreen from '../../../components/Headers/HeaderScreen';

// import { Container } from './styles';



const TransactionsScreen: React.FC = () => {
  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            Transações
          </Title>
        }
      />

    </Screen>

  );
};



export default TransactionsScreen;
