import React,
{
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  TryRevalidateSession,
} from '@noitada/axios-config/endpoints/auth.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_DRAWER_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_LANGUAGE_MODAL,
} from '@noitada/redux-config/reducers/localities.store';

import {
  USER_LOGOUT_STATE,
  USER_LOGGED,
  USER_SET,
  USER_UPDATE,
} from '@noitada/redux-config/reducers/user.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  ETokenAsync,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import AlertMessage from '../../components/Alerts';

import LanguagesModal from '../../screens/Settings/Languages';
import DrawerMenu from '../drawer/drawer.menu';

import NameRoutes from '../names';

import DrawerRoutes from './drawer.routes';
import ScreenRoutes from './screens.routes';



function AppRoutes() {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const loggedIn = useSelector((state: RootState) => state.user.loggedIn);
  const logout = useSelector((state: RootState) => state.user.logout);
  const drawerState = useSelector((state: RootState) => state.app.drawer);

  const showModalLanguage = useSelector((state: RootState) => state.localities.languageModal);


  const ScreensApp = [
    ...DrawerRoutes,
    ...ScreenRoutes,
  ];


  function setValues(idUser: string, newToken: string): void {
    try {
      localStorage.setItem(ETokenAsync.USER_ID, idUser);
      localStorage.setItem(ETokenAsync.USER_TOKEN, newToken);
      // localStorage.setItem(ETokenAsync.USER_DATA, JSON.stringify(data)); // FIXME

      dispatch({ type: USER_LOGGED, payload: true });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function clearValues(): void {
    try {
      localStorage.clear();
      dispatch({ type: USER_LOGGED, payload: false });
      dispatch({ type: USER_LOGOUT_STATE, payload: false });
      dispatch({ type: USER_SET, payload: {} });
      dispatch({ type: USER_UPDATE, payload: {} });
      dispatch({ type: APP_DRAWER_ACTION, payload: false });
    }
    catch (error: any) {
      console.error(error);
    }
  }


  function logoffAccount() {
    clearValues();

    console.log('Logoff');
  }


  // Try to validate user and logged in
  async function tryValidateAndLogin() {
    const userId = localStorage.getItem(ETokenAsync.USER_ID);
    const token = localStorage.getItem(ETokenAsync.USER_TOKEN);

    const cssText = `color: ${Colors.danger}`;

    try {
      if (!loggedIn && userId && token) {
        const data = await TryRevalidateSession(token);
        const newToken = data?.token;
        const idUser = data?.user?.id;

        if (newToken) {
          setValues(idUser, newToken);
        }
        else {
          clearValues();
          console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
        }
      }
      else if (!loggedIn && (!userId || !token)) {
        clearValues();
        console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
      }
    }
    catch (error: any) {
      console.error(error);

      clearValues();
      console.log(`%c${'NOT_AUTHORIZED'}`, cssText);
    }
  }



  useEffect(() => {
    tryValidateAndLogin();
  }, []);



  return (

    <BrowserRouter>

      <Switch>
        {ScreensApp.map(
          ({ path, page: Page, isPublic, exact }, index) => {
            return isPublic ? (

              <Route
                key={index}
                path={path}
                exact={exact}>

                <Page />

              </Route>

            ) : (

              <Route
                key={index}
                path={path}
                exact={exact}
                render={({ location }) => {
                  if (loggedIn) {
                    return <Page />;
                  }

                  // Redirect to login screen
                  return (

                    <Redirect
                      to={{
                        pathname: NameRoutes.AccessScreen,
                        state: { from: location },
                      }}
                    />

                  );
                }}
              />

            );
          },
        )}
      </Switch>



      <DrawerMenu
        open={drawerState}
        onOpen={() => {
          dispatch({ type: APP_DRAWER_ACTION, payload: true });
        }}
        onClose={() => {
          dispatch({ type: APP_DRAWER_ACTION, payload: false });
        }}
      />



      <LanguagesModal
        visible={showModalLanguage}
        onOverlayPress={() => {
          dispatch({ type: USER_LANGUAGE_MODAL, payload: false });
        }}
      />



      <AlertMessage
        visible={logout}
        title={translate(TranslateConfig.QUESTION_EXIT)}
        cancelText={translate(TranslateConfig.NO)}
        cancelPress={() => {
          dispatch({ type: USER_LOGOUT_STATE, payload: false });
        }}
        okText={translate(TranslateConfig.YES)}
        okPress={() => {
          logoffAccount();
        }}
      />

    </BrowserRouter>

  );
}



export default AppRoutes;
