import React from 'react';

import {
  _setLevelText,
  _setLevelImage,
  _setLevelColor,
} from '@noitada/shared/config/user-levels.config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EUserLevel,
} from '@noitada/shared/enums';

import {
  MenuLevelImageContent,
  MenuImageLevel,
  MenuPlaceImage,
  MenuTextLevel,
} from './styled';



export interface IProps {
  level: string;
}



const RibbonLevel: React.FC<IProps> = (props: IProps) => {
  const levelProps = props?.level || '';


  function setColorByLevel() {
    return levelProps.toUpperCase() === EUserLevel.KING ? Colors.black : Colors.white;
  }


  function setFontWeightByLevel() {
    return levelProps.toUpperCase() === EUserLevel.KING ? 'bold' : 'normal';
  }


  function setBackbroundColorByLevel() {
    return _setLevelColor(levelProps);
  }



  return (

    <MenuLevelImageContent
      borderColor={_setLevelColor(props.level)}>

      <MenuImageLevel>
        <MenuPlaceImage
          alt={'LevelUser'}
          src={_setLevelImage(props.level)}
        />
      </MenuImageLevel>



      <MenuTextLevel
        backgroundColor={setBackbroundColorByLevel()}
        color={setColorByLevel()}
        fontWeight={setFontWeightByLevel()}>
        {_setLevelText(props.level)}
      </MenuTextLevel>

    </MenuLevelImageContent>

  );
};



export default RibbonLevel;
