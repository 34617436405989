import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import ButtonAction from '../../../../components/Buttons/ButtonAction';

import {
  Overlay,
} from '../../../../components/Composh/web';

import DetailsWifi from '../../../../components/Details/DetailsWifi';

import {
  IconOrderSheet,
} from '../../../../components/Icons';

import {
  FormatMoney,
} from '../../../../config/mask.config';

import {
  SubtitleDetails,
} from '../../../../styles/styled.layout';

import {
  WifiAndInfosContainer,
  WifiInfoSection,
  PayMinInfoContainer,
  PayMinInfoItemView,
  PayMinInfoIcon,
  PayMinInfoTitle,
  PayMinInfoPrice,
  WifiCloseContainer,
} from './styled';



export interface IProps {
  visible?: boolean;
  showWifi?: boolean;
  company?: any;
  onClose?: any;
}



const WifiAndInfosModal: React.FC<IProps> = (props: IProps) => {
  const visibleModal = props.visible;
  if (!visibleModal) {
    return null;
  }

  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const company = props.company;
  const haveWifi = company?.company_about?.wifiName && company?.company_about?.wifiPassword;
  const showInfoWifi = haveWifi && company?.company_about?.showWifiMenusheet;

  const [copied, setCopied] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function renderItemInfoPay(icon: any, text: string, value: string) {
    return (

      <PayMinInfoContainer>

        <PayMinInfoItemView>
          <PayMinInfoIcon>
            {icon}
          </PayMinInfoIcon>

          <PayMinInfoTitle>
            {text}
          </PayMinInfoTitle>
        </PayMinInfoItemView>



        <PayMinInfoPrice>
          {value}
        </PayMinInfoPrice>

      </PayMinInfoContainer>

    );
  }


  function copyPassword() {
    setCopied(true);
    navigator.clipboard.writeText(company?.company_about?.wifiPassword || '');
    showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_PASSWORD_COPY));

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onClose}>

      <WifiAndInfosContainer>

        {props.showWifi && (
          <WifiInfoSection>

            <SubtitleDetails>
              {translate(TranslateConfig.WIFI)}
            </SubtitleDetails>

            {showInfoWifi
              ? (
                <DetailsWifi
                  copied={copied}
                  name={company?.company_about?.wifiName}
                  password={company?.company_about?.wifiPassword}
                  onCopyPress={() => {
                    copyPassword();
                  }}
                />
              )
              : (
                <PayMinInfoTitle>
                  {translate(TranslateConfig.HELP_WIFI_NO_REGISTER)}
                </PayMinInfoTitle>
              )
            }

          </WifiInfoSection>
        )}



        <WifiInfoSection>

          <SubtitleDetails>
            {translate(TranslateConfig.REQUESTS_MINIMAL)}
          </SubtitleDetails>


          {renderItemInfoPay(
            <IconOrderSheet
              color={Colors.white}
              size={23}
            />,
            translate(TranslateConfig.ORDERSHEET),
            validateString(company?.company_params?.minOrderSheet)
              ? FormatMoney(company?.company_params?.minOrderSheet)
              : translate(TranslateConfig.NO_VALUE_MINIMAL),
          )}

        </WifiInfoSection>



        <WifiCloseContainer>
          <ButtonAction
            width={150}
            activeOpacity={Sizes.CardActiveOpacity}
            title={translate(TranslateConfig.ACTION_CLOSE)}
            onPress={props.onClose}
          />
        </WifiCloseContainer>

      </WifiAndInfosContainer>

    </Overlay>

  );
};



export default WifiAndInfosModal;
