import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import LoadingProgress from '../LoadingProgress';

import {
  LoadingSubtitleContainer,
  LoadingSubtitleText,
} from './styled';



export interface IProps {
  loading?: boolean;
  time?: number;
}



const LoadingSubTitle: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();


  const timeComponent = props.time || 0;



  return (

    <LoadingSubtitleContainer>

      <LoadingSubtitleText>
        {timeComponent > 0
          ? `${translate(TranslateConfig.UPDATING_IN)} ${timeComponent}s`
          : translate(TranslateConfig.UPDATING)
        }
      </LoadingSubtitleText>


      {timeComponent <= 0 && (
        <LoadingProgress
          color={Colors.gray}
          size={12}
        />
      )}

    </LoadingSubtitleContainer>

  );
};



export default LoadingSubTitle;
