import React from 'react';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  IconMathMinus,
  IconMathPlus,
} from '../../Icons';

import {
  CardBagCountView,
  CardBagCountButton,
  CardBagCountText,
} from './styled';



export interface IProps {
  disabled?: boolean;
  removePress: any;
  quantity: any;
  addPress: any;
}



const ButtonAddCount: React.FC<IProps> = (props: IProps) => {
  return (

    <CardBagCountView
      disabled={props.disabled}>

      <CardBagCountButton
        onClick={() => {
          if (!props.disabled && props.removePress) {
            props.removePress();
          }
        }}>
        <IconMathMinus
          color={Colors.white}
        />
      </CardBagCountButton>


      <CardBagCountText>
        {props.quantity || 0}
      </CardBagCountText>


      <CardBagCountButton
        onClick={() => {
          if (!props.disabled && props.addPress) {
            props.addPress();
          }
        }}>
        <IconMathPlus
          color={Colors.white}
        />
      </CardBagCountButton>

    </CardBagCountView>

  );
};



export default ButtonAddCount;
