export const APP_ACCOUNT = {
  globalAccountRegister:            'https://conta.plugante.com/register-user',
  globalAccountForgotPassword:      'https://conta.plugante.com/conta/password/forgot',
  globalAccountUser:                'https://conta.plugante.com/conta',
  globalAccountAddress:             'https://conta.plugante.com/conta/address',
  globalAccountPhone:               'https://conta.plugante.com/conta/form/cellphone',
  globalAccountProfile:             'https://conta.plugante.com/conta/profiles/public/details',
  globalAccountCreditCard:          'https://conta.plugante.com/conta/payments/cards',
};


export const APPSITES = {
  noitadaSite:                      'https://noitada.net',

  noitadaAppLink:                   'https://noitada.app',
};


export const NOITADA_TERMS = {
  licencesLink:                     'https://noitada.net/terms-policy/noitada-license',
  privacityPolicyLink:              'https://noitada.net/terms-policy/noitada-privacity',
  termsUseLink:                     'https://noitada.net/terms-policy/noitada-use',
};



const Vars = {
  ...APP_ACCOUNT,
  ...APPSITES,
  ...NOITADA_TERMS,
};



export default Vars;
