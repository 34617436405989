import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardHistoryContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 7px;
  margin-bottom: 7px;
`;



export const CardHistoryImage = styled.img`
  width: 50px;
  height: 50px;
  margin-right: 14px;
  border-radius: ${Sizes.cardRadius - 2}px;
  object-fit: cover;
`;



export const HistoryInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
`;

export const HistorySheetTitle = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
`;


export const HistoryStatusView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 3px;
`;

export const HistorySheetStatusText = styled.p`
  margin-right: 10px;
  font-size: 15px;
  font-weight: bold;
  line-height: 20px;
`;

export const HistorySheetHourText = styled.p`
  color: ${Colors.gray};
  font-size: 14px;
  line-height: 20px;
`;



export const HistorySheetPriceText = styled.p`
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 16px;
`;
