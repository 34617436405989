export const APP_ACCOUNT = {
  globalAccountRegister:            'http://localhost:1001/register-user',
  globalAccountForgotPassword:      'http://localhost:1001/conta/password/forgot',
  globalAccountUser:                'http://localhost:1001/conta',
  globalAccountAddress:             'http://localhost:1001/conta/address',
  globalAccountPhone:               'http://localhost:1001/conta/form/cellphone',
  globalAccountProfile:             'http://localhost:1001/conta/profiles/public/details',
  globalAccountCreditCard:          'http://localhost:1001/conta/payments/cards',
};


export const APP_SITES = {
  noitadaSite:                      'http://localhost:4000',

  noitadaAppLink:                   'http://localhost:4001',
};


export const APP_ACCOUNT_TERMS = {
  licencesLink:                     'http://localhost:4000/terms-policy/noitada-license',
  privacityPolicyLink:              'http://localhost:4000/terms-policy/noitada-privacity',
  termsUseLink:                     'http://localhost:4000/terms-policy/noitada-use',
};



const Vars = {
  ...APP_ACCOUNT,
  ...APP_SITES,
  ...APP_ACCOUNT_TERMS,
};



export default Vars;
