import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Badge,
} from '../../../../../../components/Composh/web';



export const ViewCountContent = styled.div`
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 15px;
`;

export const HelpTicketText = styled.p`
  margin-top: 15px;
  margin-bottom: 5px;
  margin-left: 30px;
  margin-right: 30px;
  font-size: 12px;
  color: ${Colors.textApp};
  text-align: center;
`;
