import styled from 'styled-components';

import {
  Content,
} from '../../../components/Composh/web';


export const LocationCountryContent = styled(Content)`
  padding-top: 10px;
  padding-bottom: 10px;
`;
