import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Divider,
} from '../../../components/Composh/web';



export interface IProps {
  height?: number;
}



export const ProfileHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${(props: IProps) => props.height}px;
  overflow: hidden;
  position: relative;
  z-index: 4;
`;

export const ProfileInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  position: absolute;
  left: 0;
  height: 100%;
  padding-left: 17px;
  padding-right: 5px;
  padding-bottom: 20px;
  z-index: 3;
`;

export const ProfileName = styled.h1`
  color: ${Colors.buttonOkAccent};
  font-size: 30px;
  font-weight: bold;
  flex-wrap: wrap;
  text-align: left;
`;


export const ProfileViewUsername = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ProfileUsername = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  text-align: left;
`;

export const IconVerifiedComponent = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 6px;
  padding-bottom: 2px;
`;


export const ProfileDivider = styled(Divider)`
  margin-top: 8px;
  margin-bottom: 10px;
`;


export const ProfileUserView = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  right: 0;
  object-fit: cover;
  z-index: 1;
`;

export const ProfileUserImage = styled.img`
  object-fit: cover;
`;


export const ProfileDataContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 25px;
  padding-left: ${Sizes.paddingContainer}px;
  padding-right: ${Sizes.paddingContainer}px;
`;



export const ProfileAbout = styled.h3`
  color: white;
  margin-bottom: 7px;
  font-size: 18px;
  font-style: italic;
  text-align: center;
`;



export const ProfileInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
`;

export const ProfileInfoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 5px;
`;


export const ProfileTextInformations = styled.p`
  margin-top: 3px;
  margin-bottom: 3px;
  color: ${Colors.textDescription};
  font-size: 14px;
  font-style: italic;
`;



export const ActionButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin-top: ${Sizes.paddingContainer}px;
  margin-bottom: ${Sizes.paddingContainer}px;
  justify-content: center;
`;

export const EditButtonView = styled.a`
  display: flex;
  flex-direction: column;
  width: 85px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const IconButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 10px;
`;

export const TextContentView = styled.p`
  color: white;
  font-size: 12px;
  text-align: center;
`;
