import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadder,
} from '../../../../styles/styled.layout';
import CouponChoose from '../../../../components/Payments/CouponChoose';



export const ContentPadderStyled = styled(ContentPadder)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const ContentStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 15px;
`;



export const PayLogosContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const PayOrderSheetImage = styled.img`
  width: 115px;
  height: 115px;
  object-fit: cover;
`;

export const PaySeparatorHorizontal = styled.img`
  height: 100%;
  width: 40px;
`;

export const PayCompanyLogo = styled.img`
  width: 100px;
  height: 100px;
  margin-left: 10px;
  object-fit: cover;
  background-color: ${Colors.black};
  border-radius: ${Sizes.cardRadius}px;
`;



export const TotalPriceOrderView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 25px;
`;

export const TotalOrderText = styled.h1`
  margin-bottom: 5px;
  color: white;
  font-size: 26px;
  font-weight: bold;
`;

export const PriceOrderText = styled.h2`
  color: white;
  font-size: 20px;
`;



export const OrderPaymentAppView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
  width: 100%;
`;

export const OrderPaymentPlaceView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  width: 100%;
  padding-left: 30px;
  padding-right: 30px;
`;

export const OrderPaymentPlaceText = styled.p`
  color: gray;
  font-size: 18px;
  text-align: center;
`;


export const CouponChooseStyled = styled(CouponChoose)`
  margin-top: 10px;
  margin-bottom: 25px;
`;
