import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  disabled?: boolean
}



export const CarnivalButtonActionSheet = styled.a`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  width: 140px;
  height: 40px;
  max-height: 40px;
  padding-top: 3px;
  padding-left: 8px;
  padding-right: 8px;
  padding-bottom: 3px;
  background-color: ${Colors.cardBackground};
  border-color: ${Colors.accent};
  border-style: solid;
  border-width: 1px;
  border-radius: 5px;

  opacity: ${(props: IProps) => props.disabled ? 0.5 : 1};
`;

export const CircuitTextContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;


export const CarnivalCircuitText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  line-height: 16px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;

export const CarnivalCircuitName = styled.p`
  margin-top: 1px;
  color: ${Colors.textSubtitle};
  font-size: 11px;
  line-height: 13px;
`;


export const CircuitIcon = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 5px;
`;
