import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export const CardListContainer = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${Colors.cardBackground};
  border-radius: 4px;
  margin-top: 6px;
  margin-bottom: 6px;
  padding: 10px;
`;



export const CardListInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CardListInfoTile = styled.h3`
  color: ${Colors.white};
  font-size: 17px;
`;

export const CardListFinalDate = styled.p`
  color: ${Colors.textApp};
  font-size: 14px;
`;

export const CardListDescription = styled.p`
  margin-top: 5px;
  color: ${Colors.textApp};
  font-size: 13.5px;
`;

export const CardListView = styled.p`
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-end;
`;

export const CardListMoreText = styled.p`
  margin-top: 5px;
  color: ${Colors.textSubDescription};
  font-size: 13px;
`;



export const CardListParticipeStatus = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ListButtonView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 90px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: ${Sizes.buttonRadius}px;
`;

export const ListIcon = styled.div`
  display: flex;
  margin-bottom: 10px;
`;

export const ListStatusText = styled.p`
  color: white;
  font-size: 12.5px;
  line-height: 16px;
  font-style: italic;
  text-align: center;
`;

