import styled from 'styled-components';

import {
  Colors, Sizes,
} from '@noitada/shared/constants';

import {
  ContentPadderHorizontal,
} from '../../../../../styles/styled.layout';



export const InviteLinkContainer = styled(ContentPadderHorizontal)`
  padding-top: 16px;
`;


export const InviteText = styled.p`
  margin-top: 10px;
  color: ${Colors.white};
  font-size: 14px;
  text-align: center;
`;



export const InviteShareContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  padding-top: 16px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const InviteShareLinkContent = styled.a`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const InviteShareContentLinkText = styled.p`
  display: flex;
  flex: 1;
  height: 35px;
  margin-right: 16px;
  padding-top: 6px;
  padding-left: 10px;
  padding-right: 10px;
  background-color: ${Colors.black};
  color: ${Colors.white};
  border-color: ${Colors.white};
  border-style: solid;
  border-width: 1px;
  border-radius: ${Sizes.cardRadius}px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;



export const InvitePersonAlertText = styled.p`
  margin-top: 20px;
  margin-bottom: 15px;
  color: ${Colors.danger};
  text-align: center;
  font-size: 14px;
`;


export const InvitePersonsView = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
`;
