import React from 'react';

import {
  HomeTitleText,
} from './styled';



interface IProps {
  uppercase?: boolean;
  title?: string;
  size?: number;
};



const HomeTitle: React.FC<IProps> = (props: any) => {
  function setText() {
    if (props.title) {
      if (props.uppercase) {
        return (props.title).toUpperCase();
      }
      else {
        return props.title;
      }
    }
    else {
      return null;
    }
  };



  return (

    <HomeTitleText
      size={props.size || 26.5}>
      {setText()}
    </HomeTitleText>

  );
};



export default HomeTitle;
