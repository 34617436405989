import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const ViewCountContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-left: 5px;
  padding-right: 5px;
`;

export const DashLeftInfoView = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: ${Sizes.buttonHeight}px;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.gray};
  border-radius: ${Sizes.buttonRadius}px;
`;

export const DashProfileRatingText = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  line-height: 16px;
  text-align: center;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;
