import NoitadaWS from '../index';



export const ValidateCoupon = async (payload: any) => {
  const url = '/app/coupons/validate';

  const response = await NoitadaWS.post(url, payload);
  return response.data;
};
