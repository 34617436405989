import styled,
{
  css,
} from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundShow?: boolean;
  canClick?: boolean;
  color?: string;
}



export const ProfileButtonsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-top: 3px;
  margin-bottom: 2px;
`;


export const DetailsButtonBackground = css`
  background-color: ${(props: IProps) => props.canClick ? Colors.cardBackground : Colors.transparent};
`;

export const DashLeftInfoView = styled.a`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-right: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: ${Sizes.buttonRadius}px;

  ${(props: IProps) => props.backgroundShow && DetailsButtonBackground};
`;

export const DashProfileRatingText = styled.p`
  margin-top: 7px;
  color: ${(props: IProps) => props.color || Colors.gray};
  font-size: 13px;
  line-height: 15px;
  text-align: center;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;
