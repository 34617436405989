import React,
{
  useEffect,
  useState,
} from 'react';

import {
  Colors,
  Sizes,
  Values,
} from '@noitada/shared/constants';

import {
  ButtonText,
  ButtonPrimary,
} from '../../../styles/styled.layout';

import LoadingProgress from '../../Loadings/LoadingProgress';

// const DEVICE_WIDTH = Dimensions.get('window').width;
const MARGIN = Sizes.buttonHeight;
const ANIMATION_TIME = Values.animationTime;

const BUTTON_RADIUS = 100;
const AJUST_MARGIN = 10;



export interface IProps {
  color?: string;
  circleColor?: string;

  text?: string;
  loading?: boolean;
  loaded?: any;

  onPress?: Function;
  onNextPress?: Function;

  style?: any;
  buttonStyle?: any;
}



const ButtonLogin: React.FC<IProps> = (props: any) => {
  const [isLoading, setIsLoading] = useState(props.loading);
  const [timeoutFn, setTimeoutFn] = useState<any>(null);

  const [buttonAnimated] = useState(0);
  const [radiusAnimated] = useState(Sizes.cardRadius);
  const [growAnimated] = useState(0);
  const [opacityCircleAnimation] = useState(0);


  // const changeWidth = buttonAnimated.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [DEVICE_WIDTH - MARGIN - AJUST_MARGIN, MARGIN],
  // });

  // const changeScale = growAnimated.interpolate({
  //   inputRange: [0, 1],
  //   outputRange: [1, MARGIN],
  // });



  function animateButton() {
    setIsLoading(true);


    // Animated.parallel([
    //   Animated.timing(buttonAnimated, {
    //     toValue: 1,
    //     duration: ANIMATION_TIME,
    //     easing: Easing.linear,
    //     useNativeDriver: false,
    //   }),
    //   Animated.timing(radiusAnimated, {
    //     toValue: BUTTON_RADIUS,
    //     duration: ANIMATION_TIME,
    //     easing: Easing.linear,
    //     useNativeDriver: true,
    //   }),
    // ]).start();
  }



  function _onPress() {
    if (isLoading) return;

    props.onPress();
  }



  // function _onGrow() {
  //   Animated.parallel([
  //     Animated.timing(growAnimated, {
  //       toValue: 1,
  //       duration: ANIMATION_TIME,
  //       easing: Easing.linear,
  //       useNativeDriver: false,
  //     }),
  //     Animated.timing(opacityCircleAnimation, {
  //       toValue: 1,
  //       duration: ANIMATION_TIME - 100,
  //       easing: Easing.linear,
  //       useNativeDriver: false,
  //     }),
  //   ]).start();
  // }



  useEffect(() => {
    if (props.loading) {
      animateButton();
    }
    else {
      setIsLoading(false);
      // buttonAnimated.setValue(0);
      // radiusAnimated.setValue(Sizes.cardRadius);
    }
  }, [props.loading]);



  useEffect(() => {
    if (props.loaded) {
      // _onGrow();

      const timeoutFn = setTimeout(() => {
        props.onNextPress();

        setTimeout(() => {
          setIsLoading(false);
          // buttonAnimated.setValue(0);
          // growAnimated.setValue(0);
          // radiusAnimated.setValue(Sizes.cardRadius);
        }, 1000);
      }, 1000);

      setTimeoutFn(timeoutFn);
    }

    return function cleanup() {
      clearTimeout(timeoutFn);
    };
  }, [props.loaded]);



  return (

    <div style={{
      // flex: 1,
      // top: -95,
      alignItems: 'center',
      justifyContent: 'flex-start',
      zIndex: 100,
    }}>
      <div style={{
        // width: changeWidth,
      }}>


        <ButtonPrimary
          // disabled={false}
          // activeOpacity={1}
          onClick={() => {
            _onPress();
          }}
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: MARGIN,
            backgroundColor: props.color || 'black',
            borderRadius: radiusAnimated,
          }}>
          {props.loading ?
            (
              <LoadingProgress
                color={Colors.white}
                size={20}
              />
            ) : (
              <ButtonText
                style={{
                  color: Colors.white,
                }}>
                {props.text}
              </ButtonText>
            )
          }
        </ButtonPrimary>



        <div
          style={{

            alignSelf: 'center',

            // Para ficar acima de todos os componentes
            // elevation: 20,
            zIndex: 9999,

            height: MARGIN,
            width: MARGIN,
            marginTop: -MARGIN - (AJUST_MARGIN / 2),
            backgroundColor: props.circleColor || 'black',
            opacity: opacityCircleAnimation,
            borderRadius: BUTTON_RADIUS,
            // transform: [{ scale: changeScale }],
          }}
        />

      </div>
    </div>
  );
};



ButtonLogin.defaultProps = {
  loading: false,
  loaded: false,
};



export default ButtonLogin;
