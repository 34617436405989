import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  GetReserveDetail,
  CancelReservation,
} from '@noitada/axios-config/endpoints/reserves.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EEventOcurrence,
  EUserStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import DetailsAddress from '../../../../components/Address';
import CardUserFound from '../../../../components/Cards/CardUserFound';

import {
  Mask,
} from '../../../../components/Composh/plugins';

import {
  Content,
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../components/Controls';

import DetailsCompanyRealization from '../../../../components/Details/DetailsCompanyRealization';

import FooterChoose from '../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';
import CardPayInfo from '../../../../components/Payments/CardPayInfo';

import NameRoutes from '../../../../navigation/names';

import {
  ButtonText,
  DetailsSectionView,
  SubtitleDetails,
} from '../../../../styles/styled.layout';

import {
  ReserveContentPadder,
  MyReservePlaceInfo,
  MyReserveDate,
  CardReservePaymentView,
  CardReserveReservePayView,
  CardReserveReservePayText,
} from './styled';



export interface IRouteParams {
  reserve_id: string;
}



const DetailsReserveScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const rootLocation = useLocation<any>();
  const state_root = rootLocation.state;
  const {
    reserve_id,
  } = useParams() as IRouteParams;



  const user = useSelector((state: RootState) => state.user.actualUser);

  const [loading, setLoading] = useState<boolean>(false);

  const [reserveDetail, setReserveDetail] = useState<any>(null);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function loadReserve() {
    try {
      setLoading(true);

      const response = await GetReserveDetail(reserve_id);
      if (response) {
        const week = new Intl.DateTimeFormat('pt-BR', { weekday: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(response?.date)).slice(0, -1);
        const Capitalize_week = week[0].toUpperCase() + week.slice(1);
        const full_day = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long', timeZone: 'America/Sao_Paulo' }).format(new Date(response?.date));
        const time = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(response?.date));
        const week_created = new Intl.DateTimeFormat('pt-BR', { weekday: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(response?.created)).slice(0, -1);
        const Capitalize_week_created = week_created[0].toUpperCase() + week_created.slice(1);
        const full_day_created = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long', timeZone: 'America/Sao_Paulo' }).format(new Date(response?.created));
        const time_created = new Intl.DateTimeFormat('pt-BR', { timeStyle: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(response?.created));
        const newResponse = { ...response, time, full_day, Capitalize_week, Capitalize_week_created, full_day_created, time_created };
        setReserveDetail(newResponse);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };



  async function CancerReserve() {
    try {
      setLoading(true);

      const response = await CancelReservation({
        reserveId: reserve_id,
        userId: user?.id,
      });
      if (response) {
        loadReserve();
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderUserPerson(item: any, index: number) {
    return (

      <CardUserFound
        key={index}
        image={item?.picture}
        name={item?.publicName}
        username={`@${item?.username}`}
        birthday={item?.birthday}
        verified={item?.verified}
        level={item?.level}
      />

    );
  }


  function renderItemInvitePerson(item: any, index: number) {
    return (

      <CardUserFound
        key={index}
        image={item?.picture}
        name={item?.status === EUserStatus.REGISTERED
          ? `${item?.publicName}`
          : item?.status === EUserStatus.PRE_REGISTERED
            ? `${translate(TranslateConfig.CPF)}: ${Mask.toMask('cpf', item?.cpf)}`
            : translate(TranslateConfig.EMPTY_USER_DATA)
        }
        username={item?.status === EUserStatus.REGISTERED
          ? `@${item?.username}`
          : item?.status === EUserStatus.PRE_REGISTERED
            ? translate(TranslateConfig.USER_PRE_REGISTER)
            : translate(TranslateConfig.EMPTY_USER_NO_RECOG)
        }
        birthday={item?.birthday}
        verified={item?.status === EUserStatus.REGISTERED
          ? item?.people?.verified
          : false
        }
        level={item?.people?.level}
      // deleteIcon={
      //   <ButtonDelete
      //     color={Colors.danger}
      //     onPress={() => {
      //       handleRemoveItem(index);
      //     }}
      //   />
      // }
      />

    );
  }



  useEffect(() => {
    loadReserve();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>


      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Title>
            {translate(TranslateConfig.RESERVE_DETAILS)}
          </Title>
        }
      />



      {loading && (
        <LoadingScreen
          type={'SCREEN'}
          color={Colors.primary}
        />
      )}



      {!loading && (
        <Content>

          <ReserveContentPadder>

            <DetailsCompanyRealization
              name={reserveDetail?.company?.name}
              image={reserveDetail?.company?.picture}
              onPress={() => {
                history.push({
                  pathname: NameRoutes.DetailsCompanyScreen.replace(
                    ':company_username',
                    reserveDetail?.company?.company_username,
                  ),
                  state: {
                    showOptions: false,
                  },
                });
              }}
            />



            <MyReservePlaceInfo>
              <SubtitleDetails>
                {translate(TranslateConfig.RESERVED_TO)}:
              </SubtitleDetails>

              <MyReserveDate>
                {`${reserveDetail?.Capitalize_week || '-'}, ${reserveDetail?.full_day || '-'}`}
              </MyReserveDate>

              <MyReserveDate>
                {`${reserveDetail?.time || '-'}`} • {`${validateString(reserveDetail?.environment?.environment) || '-'}`}
              </MyReserveDate>
            </MyReservePlaceInfo>



            <DetailsSectionView>
              <SubtitleDetails>
                {translate(TranslateConfig.RESERVE_MADE_TO)}:
              </SubtitleDetails>


              {renderUserPerson(reserveDetail?.user, 99)}
            </DetailsSectionView>



            <DetailsSectionView>
              <SubtitleDetails>
                {translate(TranslateConfig.PEOPLE)}: {reserveDetail?.totalPeople || '-'}
              </SubtitleDetails>


              {reserveDetail?.people?.map((item: any, index: number) => (
                renderItemInvitePerson(item, index)
              ))}
            </DetailsSectionView>

          </ReserveContentPadder>



          <DetailsAddress
            companyName={reserveDetail?.company?.name}
            address={reserveDetail?.address}
          />



          <CardReservePaymentView>

            <CardReserveReservePayView>

              <CardReserveReservePayText>
                {translate(TranslateConfig.RESERVED_AT)}: {`${reserveDetail?.time_created || '-'}`}
              </CardReserveReservePayText>


              <CardReserveReservePayText>
                {`${reserveDetail?.Capitalize_week_created || '-'}, ${reserveDetail?.full_day_created || '-'}`}
              </CardReserveReservePayText>
            </CardReserveReservePayView>


            <CardPayInfo
              id={reserve_id ? `#${reserve_id}` : '-'}
              date={''} // {reserveDetail?.time_created}

              modality={'ONLINE'}
              delivery={reserveDetail?.payment?.delivery}
              discount={reserveDetail?.payment?.discount}
              subTotal={reserveDetail?.payment?.price}
              total={reserveDetail?.payment?.price}

              last4={reserveDetail?.payment?.last4}
              cardBrand={reserveDetail?.payment?.brand}
            />

          </CardReservePaymentView>

        </Content>
      )}


      {reserveDetail?.status === EEventOcurrence.PUBLISHED && !state_root?.past &&
        <FooterChoose
          hideCancelButton

          okColor={loading
            ? ArrayColors.arrayDisabled
            : ArrayColors.arrayCancel
          }
          okLabel={
            <ButtonText
              color={Colors.white}>
              {translate(TranslateConfig.ACTION_CANCEL_RESERVE)}
            </ButtonText>
          }
          okPress={() => {
            CancerReserve();
          }}
        />
      }
    </Screen>

  );
};



export default DetailsReserveScreen;

