export enum EDrawerMenu {
  HOME = 'HOME',
  FAVORITES = 'FAVORITES',

  PROFILE = 'PROFILE',
  WALLET = 'WALLET',
  NOITAG = 'NOITAG',

  TICKETS = 'TICKETS',
  LISTS_PARTICIPATE = 'LISTS_PARTICIPATE',
  WAIT_LISTS = 'WAIT_LISTS',
  REQUESTS = 'REQUESTS',
  RESERVES = 'RESERVES',

  HELP = 'HELP',

  EXIT = 'EXIT',
}



export default EDrawerMenu;
