import {
  createAction,
  createReducer,
} from '@reduxjs/toolkit';



const INITIAL_STATE = {
  actualFavorites: [] as any,
};



// Action Types

export const FAVORITES_SET = '@favorites/SET';



// Action Creators

export const favoritesAction = createAction<object>(FAVORITES_SET);



// Handlers

const setFavorites = (state = INITIAL_STATE, action: any) => ({
  ...state,
  actualFavorites: action.payload,
});



// Reducers

export default createReducer(INITIAL_STATE, {
  [favoritesAction.type]: setFavorites,
});
