import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';

import {
  ListFlat,
} from '../../../components/Composh/web';

const paddingHighlight = Sizes.paddingFlatList + 'px';



export const ProfileFavoritesScrollCardsContent = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: paddingHighlight,
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
    paddingBottom: paddingHighlight,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const EventFavoritesScrollFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: paddingHighlight,
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
    paddingBottom: paddingHighlight,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
