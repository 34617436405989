import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router';

import {
  GetCompaniesEventsMap,
} from '@noitada/axios-config/endpoints/highlights.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EEventOcurrence,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatExtenseDate,
} from '@noitada/shared/utils/date.utils';

import ButtonCityUser from '../../../components/Buttons/ButtonCitiesApp/CityUser';
import ButtonMapChoose from '../../../components/Buttons/ButtonMapChoose';
import CardMapEvent from '../../../components/Cards/CardEvents/CardMapEvent';

import {
  Container,
  ToolbarGroup,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
  ButtonNotifications,
  ButtonSearch,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import {
  IconRefresh,
} from '../../../components/Icons';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';
import MapGoogle from '../../../components/Maps/Map';
import MapAppMarker from '../../../components/Maps/MapAppMarker';

import NameRoutes from '../../../navigation/names';

import {
  HighlightGridHeader,
  HighlightDate,
  HighlightTextsContent,
  HighlightTitleToday,
  MapContainer,
  MapRefreshButton,
} from './styled';



const TabMapScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);


  const todayDate = new Date().toISOString();

  const [typeSelectMap, setTypeSelectMap] = useState<'EVENT' | 'COMPANY'>('COMPANY');

  const [permissionMap, setPermissionMap] = useState<boolean>(false);

  const [eventSelected, setEventSelected] = useState<any>(null);
  const [pinsEvents, setPinsEvents] = useState<Array<any>>([]);

  const [pinsCompany, setPinsCompany] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getCompanyAddresses = async (companiesResponse: any) => {
    const data: any[] = [];

    for (let i = 0; i < companiesResponse.length; i++) {
      const companyItem: any = companiesResponse[i];

      if (
        companyItem?.enabled &&
        companyItem?.coordinates?.latitude &&
        companyItem?.coordinates?.longitude
      ) {
        data.push({
          ...companyItem,
          coordinates: [
            companyItem?.coordinates?.latitude,
            companyItem?.coordinates?.longitude,
          ],
        });
      }
    }

    setPinsCompany(data);
  };


  const getEventAddresses = async (eventsResponse: any) => {
    const data: any[] = [];

    for (let i = 0; i < eventsResponse.length; i++) {
      const eventItem: any = eventsResponse[i];

      if (
        ([EEventOcurrence.PUBLISHED, EEventOcurrence.STARTED, EEventOcurrence.UPDATED]).includes(eventItem?.status) &&
        eventItem?.coordinates?.latitude &&
        eventItem?.coordinates?.longitude
      ) {
        data.push({
          ...eventItem,
          coordinates: [
            eventItem?.coordinates?.latitude,
            eventItem?.coordinates?.longitude,
          ],
        });
      }
    }

    setPinsEvents(data);
  };


  async function updateForm() {
    try {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const data = await GetCompaniesEventsMap(localities);
      const companiesResponse = data?.companies;
      const eventsResponse = data?.events;

      getCompanyAddresses(companiesResponse);
      getEventAddresses(eventsResponse);
    }
    catch (error: any) {
      console.error(error);

      getCompanyAddresses([]);
      getEventAddresses([]);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  };


  function onOpenCompany(username: string) {
    history.push({
      pathname: NameRoutes.DetailsCompanyScreen.replace(
        ':company_username',
        username,
      ),
    });
  }


  function onOpenEvent(idShort: string) {
    history.push({
      pathname: NameRoutes.DetailsEventScreen.replace(
        ':event_id',
        idShort,
      ),
    });
  }


  function onOpenTicketEvent(idShort: string) {
    history.push({
      pathname: NameRoutes.BuyTicketsScreen.replace(
        ':event_id',
        idShort,
      ),
    });
  }


  function onOpenListEvent(idShort: string) {
    history.push({
      pathname: NameRoutes.JoinListsScreen.replace(
        ':event_id',
        idShort,
      ),
    });
  }


  function customMarker(item: any) {
    return (

      <MapAppMarker
        key={item?.id}
        type={typeSelectMap}
        lat={item?.address?.latitude}
        lng={item?.address?.longitude}
        image={item?.picture}
        onPress={() => {
          if (typeSelectMap === 'COMPANY') {
            onOpenCompany(item?.company_username);
          }

          if (typeSelectMap === 'EVENT') {
            setEventSelected(item);
          }
        }}
      />
    );
  }


  const onSelectSwitch = (index: 'EVENT' | 'COMPANY') => {
    setTypeSelectMap(index);
  };


  function tryLocationPermission() {
    console.log('Perguntar permissão de localização');
  }


  function renderLoading() {
    return (
      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />
    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noHighlight}
        title={translate(TranslateConfig.SUBTITLES_HIGHLIGHT_MAP)}
        description={translate(TranslateConfig.SUBTITLES_HIGHLIGHT_MAP_EXPERIENCE)}
        buttonText={translate(TranslateConfig.ACTION_LOCATION_ENABLED)}
        onPress={() => {
          tryLocationPermission();
        }}
      />

    );
  }



  useEffect(() => {
    setLoading(true);
    setPermissionMap(true);
    setLoading(false);
  }, []);


  useEffect(() => {
    updateForm();
  }, [localities]);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityUser />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonSearch
              color={Colors.white}
            />


            <ButtonNotifications
              color={Colors.white}
            />


            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        noSubToolbarPadding>

        <HighlightGridHeader>
          <HighlightTextsContent>

            <HighlightTitleToday>
              {translate(TranslateConfig.TODAY)}
            </HighlightTitleToday>


            <HighlightDate>
              {FormatExtenseDate(todayDate)}
            </HighlightDate>

          </HighlightTextsContent>
        </HighlightGridHeader>

      </HeaderScreen>



      {loading && (
        renderLoading()
      )}



      {!loading && !permissionMap && (
        listEmptyComponent()
      )}



      {!loading && permissionMap && (
        <MapContainer>

          <MapGoogle>
            {typeSelectMap === 'COMPANY' && pinsCompany.map((pin) => customMarker(pin))}
            {typeSelectMap === 'EVENT' && pinsEvents.map((pin) => customMarker(pin))}
          </MapGoogle>



          <MapRefreshButton
            onClick={() => {
              updateForm();
            }}>
            <IconRefresh
              size={26}
              color={Colors.black}
            />
          </MapRefreshButton>



          {typeSelectMap === 'EVENT' && eventSelected && (
            <CardMapEvent
              date={'-'} // FIXME: SÁB, 11 MAI, 2021 • 20:00
              name={eventSelected?.name}
              eventImage={eventSelected?.picture}
              placeName={eventSelected?.eventPlaceName}
              companyName={eventSelected?.company?.name}
              companyImage={eventSelected?.company?.picture}
              onEventPress={() => {
                onOpenEvent(eventSelected?.idShort);
              }}
              onTicketsPress={eventSelected?.buyTickets
                ? () => {
                  onOpenTicketEvent(eventSelected?.idShort);
                }
                : null
              }
              onListPress={eventSelected?.seeLists
                ? () => {
                  onOpenListEvent(eventSelected?.idShort);
                }
                : null
              }
              onCompanyPress={() => {
                onOpenCompany(eventSelected?.company?.company_username);
              }}
              onClosePress={() => {
                setEventSelected(null);
              }}
            />
          )}



          <ButtonMapChoose
            selectionMode={typeSelectMap}
            textOption1={translate(TranslateConfig.EVENTS)}
            textOption2={translate(TranslateConfig.PARTNERS)}
            onSelectSwitch={(value: 'EVENT' | 'COMPANY') => {
              onSelectSwitch(value);
            }}
          />

        </MapContainer>
      )}

    </Container>

  );
};



export default TabMapScreen;
