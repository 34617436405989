import React from 'react';

import {
  Alert,
} from '../Composh/web';



export interface IProps {
  visible?: boolean;
  title: string;
  description?: string;
  text?: string;
  cancelText?: string;
  cancelPress?: any;
  okText: string;
  okPress: any;
}



const AlertMessage: React.FC<IProps> = (props) => {
  return (

    <Alert.Container
      visible={props.visible}
      titleContainer={
        <Alert.Title>
          {props.title}
        </Alert.Title>
      }
      descriptionContainer={
        <Alert.Content>
          {props.description && (
            <Alert.Description>
              {props.description}
            </Alert.Description>
          )}

          {props.text && (
            <Alert.Text>
              {props.text}
            </Alert.Text>
          )}
        </Alert.Content>
      }
      buttonContainer={
        <Alert.Footer>
          {(props.cancelText || props.cancelPress) && (
            <Alert.Button
              label={props.cancelText}
              onPress={props.cancelPress}
            />
          )}

          <Alert.Button
            label={props.okText}
            onPress={props.okPress}
          />
        </Alert.Footer>
      }
    />

  );
};



export default AlertMessage;
