import IAppRoute from '../../interfaces/app-route.interface';

import AccessScreen from '../../screens/Access';
import CarnivalGuidesScreen from '../../screens/Carmival/Guides';
import CarnivalVotesListScreen from '../../screens/Carmival/Votes/List';
import QrCodeScreen from '../../screens/Checkin/ReadQr';
import DetailsCompanyScreen from '../../screens/Company/DetailsCompany';
import CompanyGalleryScreen from '../../screens/Company/Gallery';
import JoinListsScreen from '../../screens/Entrances/Lists/CompanyLists';
import ListInviteScreen from '../../screens/Entrances/Lists/CompanyLists/Invite';
import BuyTicketsScreen from '../../screens/Entrances/Tickets/CompanyTickets';
import ChangeTicketScreen from '../../screens/Entrances/Tickets/UserTickets/ChangeTicket';
import TicketBuyDetailsScreen from '../../screens/Entrances/Tickets/UserTickets/TicketBuyDetails';
import AllCompanyEventsScreen from '../../screens/Events/AllCompanyEvents';
import DetailsEventScreen from '../../screens/Events/DetailsEvents';
import FaqBulletScreen from '../../screens/Help/FAQ/Bullets';
import FaqCategoryScreen from '../../screens/Help/FAQ/Categories';
import MenuSheetScreen from '../../screens/MenuSheet';
import NotificationsScreen from '../../screens/Notifications/AllNotifications';
import OrderBagScreen from '../../screens/Orders/CompanyOrders/OrderBag';
import OrderSheetScreen from '../../screens/Orders/CompanyOrders/OrderSheet';
import OrderSheetPayAppScreen from '../../screens/Orders/CompanyOrders/OrderSheetPay';
import UserDetailsOrderScreen from '../../screens/Orders/UserOrders/UserDetailsOrder';
import PermissionsConfigAppScreen from '../../screens/Permissions';
import ReserveConfirmedScreen from '../../screens/Reserves/CompanyReserves/ConfirmReserve';
import CompanyReservesScreen from '../../screens/Reserves/CompanyReserves/DoReserve';
import DetailsReserveScreen from '../../screens/Reserves/UserReserves/DetailsReserve';
import SearchScreen from '../../screens/Search/Filters';
import SearchResultsScreen from '../../screens/Search/Results';
import AboutScreen from '../../screens/Settings/About';
import PreferencesScreen from '../../screens/Settings/Preferences';
import TermsPolicyScreen from '../../screens/Settings/TermsPolicy';
import SettingWalletNoitagScreen from '../../screens/Settings/Wallets';
import TutorialScreen from '../../screens/Tutorial';
import WaitListsUserScreen from '../../screens/WaitLists/ActiveList';
import CompanyWaitListsScreen from '../../screens/WaitLists/CompanyWaitList';
import WaitListPastScreen from '../../screens/WaitLists/ListAll/PastWaitList';
import TransactionsScreen from '../../screens/Wallet/Transactions';
import WebViewScreen from '../../screens/WebView';

import NameRoutes from '../names';



const ScreenRoutes: IAppRoute[] = [

  {
    path: NameRoutes.TutorialScreen,
    page: TutorialScreen,
    isPublic: true,
    exact: true,
  },
  {
    path: NameRoutes.PermissionsScreen,
    page: PermissionsConfigAppScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.WebViewScreen,
    page: WebViewScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.AccessScreen,
    page: AccessScreen,
    isPublic: true,
    exact: true,
  },



  // Carnival

  {
    path: NameRoutes.CarnivalGuidesScreen,
    page: CarnivalGuidesScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.CarnivalVotesListScreen,
    page: CarnivalVotesListScreen,
    isPublic: false,
    exact: true,
  },



  // Festival

  // {
  //   path: NameRoutes.FaqCategoryScreen,
  //   page: FaqCategoryScreen,
  //   isPublic: true,
  //   exact: true,
  // },



  // FAQ

  {
    path: NameRoutes.FaqCategoryScreen,
    page: FaqCategoryScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.FaqBulletScreen,
    page: FaqBulletScreen,
    isPublic: true,
    exact: true,
  },



  // Settings

  {
    path: NameRoutes.PreferencesScreen,
    page: PreferencesScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.SettingWalletNoitagScreen,
    page: SettingWalletNoitagScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AboutScreen,
    page: AboutScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.TermsPolicyScreen,
    page: TermsPolicyScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.TransactionsScreen,
    page: TransactionsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.SearchScreen,
    page: SearchScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.SearchResultsScreen,
    page: SearchResultsScreen,
    isPublic: true,
    exact: true,
  },



  {
    path: NameRoutes.NotificationsScreen,
    page: NotificationsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.QrCodeScreen,
    page: QrCodeScreen,
    isPublic: false,
    exact: true,
  },



  // Orders Page

  {
    path: NameRoutes.OrderSheetScreen,
    page: OrderSheetScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.OrderBagScreen,
    page: OrderBagScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.OrderSheetPayAppScreen,
    page: OrderSheetPayAppScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.UserDetailsOrderScreen,
    page: UserDetailsOrderScreen,
    isPublic: false,
    exact: true,
  },



  // Events


  {
    path: NameRoutes.BuyTicketsScreen,
    page: BuyTicketsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.JoinListsScreen,
    page: JoinListsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.ListInviteScreen,
    page: ListInviteScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.DetailsEventScreen,
    page: DetailsEventScreen,
    isPublic: true,
    exact: true,
  },



  // Reserves

  {
    path: NameRoutes.ReserveConfirmedScreen,
    page: ReserveConfirmedScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.DetailsReserveScreen,
    page: DetailsReserveScreen,
    isPublic: false,
    exact: true,
  },



  // Entrances

  {
    path: NameRoutes.TicketBuyDetailsScreen,
    page: TicketBuyDetailsScreen,
    isPublic: false,
    exact: true,
  },

  {
    path: NameRoutes.ChangeTicketScreen,
    page: ChangeTicketScreen,
    isPublic: false,
    exact: true,
  },



  // Company Pages - always on the end of routes because the url pattern

  {
    path: NameRoutes.DetailsCompanyScreen,
    page: DetailsCompanyScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.CompanyGalleryScreen,
    page: CompanyGalleryScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.CompanyReservesScreen,
    page: CompanyReservesScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.CompanyWaitListsScreen,
    page: CompanyWaitListsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.MenuSheetScreen,
    page: MenuSheetScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.AllCompanyEventsScreen,
    page: AllCompanyEventsScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.WaitListPastScreen,
    page: WaitListPastScreen,
    isPublic: true,
    exact: true,
  },

  {
    path: NameRoutes.WaitListsUserScreen,
    page: WaitListsUserScreen,
    isPublic: true,
    exact: true,
  },

];



export default ScreenRoutes;
