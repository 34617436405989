import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  BannerImageContainer,
  BannerBackground,
  BannerImageOverlay,
  BannerInfoView,
  BannerImage,
  BannerInfoTextsView,
  BannerInfoTitle,
  BannerInfoUsername,
  BannerInfoOpenText,
} from './styled';



export interface IProps {
  image?: string;
  banner?: string;
  name?: string;
  username?: string;
  opened?: boolean; // FIXME - usar
}



const DetailsHeaderBlur: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  const eventImage = validateString(props.image)
    ? props.image
    : Images.placeholderEvent;



  return (

    <BannerImageContainer>

      <BannerBackground
        alt={'picture'}
        src={eventImage}
      />

      <BannerImageOverlay />



      <BannerInfoView>

        <BannerImage
          src={eventImage}
        />



        <BannerInfoTextsView>

          <BannerInfoTitle>
            {props?.name}
          </BannerInfoTitle>


          <BannerInfoUsername>
            @{props?.username}
          </BannerInfoUsername>


          {/* TODO */}
          {false && !props.opened && (
            <BannerInfoOpenText
              color={Colors.danger}>
              {translate(TranslateConfig.CLOSED)}
            </BannerInfoOpenText>
          )}


          {/* TODO */}
          {false && props.opened && (
            <BannerInfoOpenText
              color={Colors.accept}>
              {translate(TranslateConfig.OPENED)}
            </BannerInfoOpenText>
          )}

        </BannerInfoTextsView>

      </BannerInfoView>

    </BannerImageContainer>

  );
};



export default DetailsHeaderBlur;
