import React from 'react';

import {
  renderCardFlag,
} from '@noitada/shared/config/payments.config';

import {
  Images,
} from '@noitada/shared/constants';

import {
  CardFlagImage,
} from './styled';



export interface IProps {
  type?: string;
  size?: number;
  borderRadius?: number;
}



const CreditFlag: React.FC<IProps> = (props) => {
  function renderFlagCard() {
    if (props.type === 'cash') {
      return Images.flagTypeCash;
    }

    if (props.type === 'pix') {
      return Images.flagTypePix;
    }

    if (props.type === 'cheque') {
      return Images.flagTypeCheque;
    }

    return renderCardFlag(props.type || 'unknown');
  }



  return (

    <CardFlagImage
      alt={'flag'}
      src={renderFlagCard()}
      size={props.size}
      borderRadius={props.borderRadius}
    />

  );
};



export default CreditFlag;
