import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom';

import {
  GetMenuSheet,
} from '@noitada/axios-config/endpoints/menu-sheet.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import CardOrderMenu from '../../components/Cards/CardOrders/CardOrderMenu';

import {
  Screen,
  Title,
  Container,
  Body,
  SubTitle,
  ToolbarGroup,
  Toggle,
} from '../../components/Composh/web';

import {
  ButtonBack,
  ButtonLanguage,
  // ButtonSearch,
} from '../../components/Controls';

import DetailsCart from '../../components/Details/DetailsCart';
import EmptyContent from '../../components/Empty';
import HeaderScreen from '../../components/Headers/HeaderScreen';
import LoadingScreen from '../../components/Loadings/LoadingScreen';

import {
  FormatMoney,
} from '../../config/mask.config';

import KnowApp from '../../modals/KnowApp';

import NameRoutes from '../../navigation/names';

import MenuOrderDetails from './Modals/ItemDetails';
import WifiAndInfosModal from './Modals/WifiAndInfos';

import {
  CompanyInfoImageButton,
  CompanyImageButton,
  ImageCompanyInfo,
  TypeItemsView,
  MenuSheetContainer,
  FlatListCategory,
  FlatListItems,
  CategoryMenuView,
  MenuSheetViewText,
  MenuSheetText,
} from './styled';



export interface IRouteParams {
  company_username: string;
}

export interface IRouteLocation {
  details: boolean;
}



const MenuSheetScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const routeParams: IRouteParams = useParams();
  const { company_username } = routeParams;

  const routeLocation = useLocation();
  const stateLocation = routeLocation?.state as IRouteLocation;

  const urlScreen = window.location.href;
  const showKnowApp = urlScreen.includes('number=');
  const showOpenDetails = stateLocation?.details === false ? false : true;


  const sacola = useSelector((state: RootState) => state.sacola);
  const user = useSelector((state: RootState) => state.user.actualUser);
  const checkinUser = useSelector((state: RootState) => state.comanda.checkin);


  const [menuSheetResponse, setMenuSheetResponse] = useState<Array<any>>([]);
  const [companyResponse, setCompanyResponse] = useState<any>(null);
  const [groups, setGroups] = useState<Array<any>>([]);


  const [selectProduct, setSelectProduct] = useState<any>();
  const [highlightProds, setHighlightProds] = useState<Array<any>>([]);
  const [allMenuSheetResponse, setAllMenuSheetResponse] = useState<Array<any>>([]);
  const [selectedType, setSelectedType] = useState(0);

  const [categorySelected, setCategorySelected] = useState(0);
  const [itemSelected, setItemSelected] = useState();

  const [loading, setLoading] = useState<boolean>(true);

  const [showInfoCompany, setShowInfoCompany] = useState<boolean>(false);
  const [showItemModal, setShowItemModal] = useState<boolean>(false);
  const [statusKnowApp, setStatusKnowApp] = useState<boolean>(true);

  // FIXME: Se o checki esta feito nessa empresa, também poder mostrar o wifi
  const canShowWifi = showKnowApp;



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function changeTouchCategory() {
    if (selectedType === -1) {
      return;
    }

    if (selectedType === 0) {
      const allCategories: any[] = [];
      allMenuSheetResponse.forEach((e: any) => {
        e?.categories?.forEach((f: any) => {
          allCategories.push(f);
        });
      });
      setMenuSheetResponse([...allCategories]);
    }
    else {
      const allCategories: any[] = [];
      allMenuSheetResponse.filter((e) => e?.id === selectedType)?.forEach((e: any) => {
        e?.categories?.forEach((f: any) => {
          allCategories.push(f);
        });
      });
      setMenuSheetResponse([...allCategories]);
    }
  }


  async function getMenuSheet() {
    try {
      setLoading(true);

      const response = await GetMenuSheet(company_username);
      const responseData = response?.data;

      const menuSheetData = responseData?.menuSheet;
      const companyData = responseData?.company;

      if (!responseData || menuSheetData?.length <= 0) {
        console.error(response);
        setAllMenuSheetResponse([]);
        setCompanyResponse(null);
        return;
      }

      const groups_filter = menuSheetData?.map((itemGroup: any) => {
        return { id: itemGroup?.id, name: itemGroup?.name };
      });
      setGroups(groups_filter || []);
      setAllMenuSheetResponse([...menuSheetData]);

      setCompanyResponse(companyData);
    }
    catch (error: any) {
      console.error(error);
      setAllMenuSheetResponse([]);
      setCompanyResponse(null);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function renderCategory(item: any, index: number) {
    if (item?.item_sales?.length <= 0) {
      return null;
    }

    return (

      <CategoryMenuView
        key={index}
        // activeOpacity={0.6}
        onClick={() => {
          setCategorySelected(index);
        }}
        style={{
          backgroundColor: categorySelected === index
            ? Colors.primary
            : Colors.cardBackground,
        }}>

        <MenuSheetViewText>
          <MenuSheetText>
            {item?.name !== 'uncategorized'
              ? item?.name
              : translate(TranslateConfig.EMPTY_CATEGORIES)
            }
          </MenuSheetText>
        </MenuSheetViewText>

      </CategoryMenuView>

    );
  }


  function renderItem(item: any, index: number) {
    let minPrice: string | number = Infinity;
    let maxPrice: string | number = 0;

    if (item?.group?.length === 1) {
      minPrice = item?.group[0].price;

      minPrice = FormatMoney(item?.group[0].price);
      maxPrice = minPrice;
    }
    else if (item?.group?.length > 1) {
      item?.group?.forEach((itemPriceGroup: any) => {
        minPrice = Math.min(parseFloat(`${minPrice}`), itemPriceGroup?.price);
        maxPrice = Math.max(parseFloat(`${maxPrice}`), itemPriceGroup?.price);
      });

      minPrice = FormatMoney(minPrice);
      maxPrice = FormatMoney(maxPrice);
    }


    return (

      <CardOrderMenu
        key={index}
        image={item?.picture}
        name={item?.name}
        description={item?.description}
        price={minPrice !== maxPrice
          ? `${translate(TranslateConfig.FROM)} ${minPrice} ${translate(TranslateConfig.TO).toLowerCase()} ${maxPrice}`
          : `${minPrice}`
        }

        alcoholic={item?.alcoholic}
        spicy={item?.spicy}
        vegetarian={item?.vegetarian}
        vegan={item?.vegan}
        organic={item?.organic}
        noLactose={item?.no_lactose}
        noGlutem={item?.no_glutem}
        onPress={() => {
          setItemSelected(item);
          setSelectProduct(null);
          setShowItemModal(true);
        }}
      />

    );
  }


  function renderToggleGroups(item: any, index: any) {
    return (

      <Toggle
        key={index}
        noToggle
        disabled={loading}
        selected={selectedType === item?.value}
        displayValue={item?.label}
        backgroundColor={Colors.inputChip}
        backgroundTintColor={Colors.primary}
        borderRadius={50}
        textColor={Colors.white}
        textTintColor={Colors.white}
        onPress={() => {
          setSelectedType(item?.value);
        }}
      />

    );
  }


  function separateHighlight() {
    const allprods: any[] = [];
    allMenuSheetResponse?.forEach((eachItems) => {
      eachItems?.categories?.forEach((forCategories: any) => {
        forCategories?.item_sales?.forEach((itemProduct: any) => {
          allprods.push(itemProduct);
        });
      });
    });

    const hprods = allprods?.filter((item) => item?.highlight);
    if (hprods[0]) {
      setHighlightProds(hprods);
      setSelectedType(-1);
    }
  }



  useEffect(() => {
    getMenuSheet();
  }, []);


  useEffect(() => {
    if (allMenuSheetResponse[0]) {
      setCategorySelected(0);
      changeTouchCategory();
    }
  }, [selectedType]);


  useEffect(() => {
    if (allMenuSheetResponse[0]) {
      setCategorySelected(0);
      changeTouchCategory();
      separateHighlight();
    }
  }, [allMenuSheetResponse]);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        {showKnowApp && statusKnowApp && (
          <KnowApp
            onPress={() => {
              history.replace(NameRoutes.GridEventsScreen);
              setStatusKnowApp(false);
            }}
            onClosePress={() => {
              setStatusKnowApp(false);
            }}
          />
        )}



        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              color={Colors.white}
            />
          }
          centerContent={
            <Body>
              <Title
                color={Colors.white}>
                {translate(TranslateConfig.MENUSHEET)}
              </Title>

              <SubTitle
                color={Colors.white}>
                {!loading ? companyResponse?.name || '-' : ' '}
              </SubTitle>
            </Body>
          }
          rightIcon={loading
            ? null
            : (
              <ToolbarGroup>

                {/* TODO
                <ButtonSearch
                  color={Colors.white}
                />
                */}


                {/* TODO: Liberar só quando traduzir os textos dinâmicos */}
                {false && !loading && (
                  <ButtonLanguage
                    color={Colors.white}
                  />
                )}



                <CompanyInfoImageButton
                  onClick={() => {
                    setShowInfoCompany(true);
                  }}>
                  <ImageCompanyInfo
                    // resizeMode={'cover'}
                    src={canShowWifi ? Images.wifiInfos : Images.infos}
                  />
                </CompanyInfoImageButton>



                <CompanyImageButton
                  onClick={() => {
                    if (showOpenDetails) {
                      history.push({
                        pathname: NameRoutes.DetailsCompanyScreen.replace(
                          ':company_username',
                          company_username,
                        ),
                      });
                    }
                  }}>
                  <ImageCompanyInfo
                    // resizeMode={'cover'}
                    src={
                      companyResponse?.picture === '' || companyResponse?.picture === null || companyResponse?.picture === undefined
                        ? Images.placeholderAvatarCompany
                        : companyResponse?.picture
                    }
                  />
                </CompanyImageButton>

              </ToolbarGroup>
            )
          }
          noSubToolbarPadding>


          <TypeItemsView>
            {!loading && highlightProds[0] && renderToggleGroups({ label: translate(TranslateConfig.HIGHLIGHTS), value: -1 }, -1)}

            {!loading && renderToggleGroups({ label: translate(TranslateConfig.ALL), value: 0 }, 0)}

            {!loading && groups.length > 0 && groups.map((itemFilter) => {
              const index = itemFilter?.id || Math.ceil(Math.random() * 1000);
              return renderToggleGroups({ label: itemFilter?.name || '', value: index }, index);
            })}
          </TypeItemsView>

        </HeaderScreen>



        <Container>

          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {!loading && menuSheetResponse.length === 0 && (
            <EmptyContent
              image={Images.noOrderItems}
              title={translate(TranslateConfig.EMPTY_MENUSHEET_ITEMS)}
            />
          )}



          {!loading && menuSheetResponse?.length !== 0 && (
            <MenuSheetContainer>

              <FlatListCategory
                style={{ display: selectedType === -1 ? 'none' : 'flex' }}>
                {menuSheetResponse?.length !== 0 && menuSheetResponse.map((category: any, index: number) => (
                  renderCategory(category, index)
                ))}
              </FlatListCategory>



              {menuSheetResponse?.length !== 0 && menuSheetResponse[categorySelected]?.item_sales <= 0
                ? (
                  <EmptyContent
                    image={Images.noOrderItems}
                    title={TranslateConfig.EMPTY_MENUSHEET_CATEGORIES}
                  />
                )
                : (
                  <FlatListItems>
                    {selectedType > -1 && menuSheetResponse[categorySelected]?.item_sales?.length !== 0 && menuSheetResponse[categorySelected]?.item_sales.map((item: any, index: number) => (
                      renderItem(item, index)
                    ))}
                    {selectedType === -1 && highlightProds[0] && highlightProds.map((item, index) => renderItem(item, index))}
                  </FlatListItems>
                )
              }

            </MenuSheetContainer>
          )}

        </Container>



        {sacola?.items[0] && user?.id && checkinUser && (
          <DetailsCart
            items={sacola?.items?.length}
            totalItems={sacola?.items?.length}
            price={FormatMoney(
              sacola?.items?.reduce((itemA: any, itemB: any) => {
                return itemA + (itemB?.itemGroup?.price * itemB?.quantity);
              }, 0),
            )}
            onPress={() => {
              history.push(NameRoutes.OrderBagScreen);
            }}
          />
        )}

      </Container>



      <WifiAndInfosModal
        visible={showInfoCompany}
        showWifi={canShowWifi}
        company={companyResponse}
        onClose={() => {
          setShowInfoCompany(false);
        }}
      />



      <MenuOrderDetails
        visible={showItemModal}
        idCompany={companyResponse?.id}
        item={itemSelected}
        selectProduct={selectProduct}
        onCancelPress={() => {
          setShowItemModal(false);
        }}
        onSelectProduct={(item: any) => {
          setSelectProduct(item);
        }}
      />

    </Screen>

  );
};



export default MenuSheetScreen;
