import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  EWaitListsStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  formatMinuteToFractionDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  IconCalendarHour,
  IconCheck,
  IconText,
  IconVerified,
  IconWarning,
} from '../../../Icons';

import {
  CardWaitListsContainer,
  CardWaitListsDataView,
  CheckInAvatarContent,
  CardWaitListsImage,
  CardWaitListsInfoView,
  CheckInInfoContent,
  CheckInUsernameContent,
  CardWaitListsName,
  CardWaitListsDescription,
  CheckInVerifiedIcon,
  CardWaitListsCountsView,
  CardWaitListsCountsChip,
  CardWaitListsCountsTitle,
  CardWaitListsPriorityView,
  CardWaitListsPriorityTitle,
  CardWaitListsReportsView,
  CardWaitListsStatusText,
  CardWaitListsReportsText,
} from './styled';



export interface IProps {
  image?: string;
  name?: string;
  level?: string;
  description?: string;
  verified?: boolean;
  time?: string;
  finished?: string;
  observations?: string;
  priority?: boolean;
  status?: string;
  waitTime?: number;

  onPress?: any;
}



const CardWaitListsCompanyFinished: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderStatusText() {
    const propsStatus = props.status;

    if (!propsStatus) {
      return '-';
    }
    switch (propsStatus) {
      case EWaitListsStatus.WAITING:
        return translate(TranslateConfig.WAITING);

      case EWaitListsStatus.CANCELED:
      case EWaitListsStatus.USER_CANCELED:
        return translate(TranslateConfig.CANCELED);

      case EWaitListsStatus.FINISHED:
        return translate(TranslateConfig.FINISHED);

      default:
        return '-';
    }
  }


  function renderStatusColor() {
    const propsStatus = props.status;

    if (!propsStatus) {
      return '-';
    }
    switch (propsStatus) {
      case EWaitListsStatus.WAITING:
        return Colors.attentionDark;

      case EWaitListsStatus.CANCELED:
      case EWaitListsStatus.USER_CANCELED:
        return Colors.danger;

      case EWaitListsStatus.FINISHED:
        return Colors.accept;

      default:
        return '-';
    }
  }



  return (

    <CardWaitListsContainer
      backgroundColor={Colors.cardBackground}
      borderRadius={Sizes.cardRadius}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <CardWaitListsDataView>

        <CheckInAvatarContent>
          <CardWaitListsImage
            alt={'ImageAvatarUser'}
            src={validateString(props.image)
              ? props.image
              : Images.placeholderAvatarUser
            }
          />
        </CheckInAvatarContent>



        <CardWaitListsInfoView>

          <CheckInInfoContent>

            <CheckInUsernameContent>

              <CardWaitListsName>
                {props.name || '-'}
              </CardWaitListsName>

            </CheckInUsernameContent>



            <CheckInUsernameContent>
              <CardWaitListsDescription>
                {props.description || '-'}
              </CardWaitListsDescription>

              {props.verified && (
                <CheckInVerifiedIcon>
                  <IconVerified
                    color={Colors.accent}
                    size={14}
                  />
                </CheckInVerifiedIcon>
              )}
            </CheckInUsernameContent>

          </CheckInInfoContent>



          <CardWaitListsCountsView>

            {/* Tempo Inicial */}
            <CardWaitListsCountsChip>
              <IconCalendarHour
                color={Colors.primary}
                size={18}
              />

              <CardWaitListsCountsTitle>
                {props.time || '-'}
              </CardWaitListsCountsTitle>
            </CardWaitListsCountsChip>



            {/* Tempo Final */}
            <CardWaitListsCountsChip>
              <IconCheck
                color={Colors.primary}
                size={18}
              />

              <CardWaitListsCountsTitle>
                {props.finished || '-'}
              </CardWaitListsCountsTitle>
            </CardWaitListsCountsChip>



            {/* Obersavações */}
            {props.observations && (
              <CardWaitListsCountsChip>
                <IconText
                  color={Colors.primary}
                  size={16}
                />

                <CardWaitListsCountsTitle>
                  {translate(TranslateConfig.OBSERVATIONS)}
                </CardWaitListsCountsTitle>
              </CardWaitListsCountsChip>
            )}

          </CardWaitListsCountsView>

        </CardWaitListsInfoView>



        {props.priority && (
          <CardWaitListsPriorityView>

            <IconWarning
              color={Colors.attention}
              size={32}
            />


            <CardWaitListsPriorityTitle>
              {translate(TranslateConfig.PRIORITY)}
            </CardWaitListsPriorityTitle>

          </CardWaitListsPriorityView>
        )}

      </CardWaitListsDataView>



      <CardWaitListsReportsView>

        <CardWaitListsStatusText
          color={renderStatusColor()}>
          {renderStatusText()}
        </CardWaitListsStatusText>


        <CardWaitListsReportsText>
          {translate(TranslateConfig.WAIT)}: {formatMinuteToFractionDayjs(props.waitTime || 0) || '-'}
        </CardWaitListsReportsText>

      </CardWaitListsReportsView>

    </CardWaitListsContainer>

  );
};



export default CardWaitListsCompanyFinished;
