import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/mask.config';

import ButtonAddCount from '../../Buttons/ButtonAddCount';

import {
  CardTicketContainer,
  CardTicketText,
  CardTicketAllView,
  CardTicketInfoView,
  CardTicketLot,
  CardTicketSalesUntil,
  CardTicketSalesPrice,
  CardTicketSalesType,
  CardTicketTypesView,
} from './styled';



export interface IProps {
  name: string;
  lot: string;
  type?: string;
  endSale?: any;
  price: any;

  onPress?: any;
}



const CardBuyTicket: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();

  const [quantity, setQuantity] = useState(1);



  function updateQuantity(e: number) {
    // if (!props?.selectItem?.id) {
    //   return;
    // }

    if (e < 1 && quantity > 1) {
      setQuantity(quantity - 1);
    }
    else if (e > 0) {
      setQuantity(quantity + 1);
    }
  };



  return (

    <CardTicketContainer
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>


      <CardTicketText>
        {props.name}
      </CardTicketText>



      <CardTicketAllView>

        <CardTicketInfoView>

          <CardTicketLot>
            {props.lot || '-'}
          </CardTicketLot>


          <CardTicketSalesUntil>
            {translate(TranslateConfig.TICKETS_SALES_UNTIL)}: {props.endSale || '-'}
          </CardTicketSalesUntil>


          <CardTicketSalesPrice>
            {FormatMoney(props.price)}
          </CardTicketSalesPrice>


          <CardTicketSalesType>
            {'Ingresso inteiro'}
          </CardTicketSalesType>

        </CardTicketInfoView>



        <CardTicketTypesView>
          <ButtonAddCount
            disabled={false}
            quantity={quantity}
            removePress={() => {
              updateQuantity(-1);
            }}
            addPress={() => {
              updateQuantity(1);
            }}
          />
        </CardTicketTypesView>

      </CardTicketAllView>

    </CardTicketContainer>

  );
};



export default CardBuyTicket;
