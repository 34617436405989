import React,
{
  useEffect,
} from 'react';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_RESET_APP,
  APP_SNACKBAR_RESET,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  Loader,
  SnackBar,
} from '../components/Composh/web';

import LoadingProgress from '../components/Loadings/LoadingProgress';

import SplashScreen from '../screens/Splash';

import AppRoutes from './routes/app.routes';

import {
  LoadingProgressStyled,
} from './styled';



const Navigation: React.FC = () => {
  const dispatch = useDispatch();


  const modalLoadingState = useSelector((state: RootState) => state.app.modalLoading);
  const snackbarState = useSelector((state: RootState) => state.app.snackBar);



  useEffect(() => {
    dispatch({ type: APP_RESET_APP });
  }, []);


  useEffect(() => {
    setTimeout(() => {
      dispatch({ type: APP_SNACKBAR_RESET });
    }, 1800);
  }, [snackbarState.visible]);



  return (

    <SplashScreen>

      <AppRoutes />


      <SnackBar
        visible={snackbarState.visible}
        backgroundColor={snackbarState.color}
        textMessage={snackbarState.text}
      />


      <Loader
        visible={modalLoadingState}
        overlayBackground={Colors.alertOverlay}>

        <LoadingProgressStyled>
          <LoadingProgress
            color={Colors.primary}
          />
        </LoadingProgressStyled>

      </Loader>

    </SplashScreen>

  );
};



export default Navigation;
