import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  IconEventEnd,
  IconEventStart,
} from '../../../../../../components/Icons';

import {
  CalendarContainerStyle,
  TimeCalendar,
  DetailsDayCalendar,
  DetailsDateCalendar,
  DetailsCalendarName,
  CalendarInfoContent,
  CalendarSubHeader,
  CalendarTitleHeader,
  CalendarInfoDates,
} from './styled';



export interface IProps {
  startTime: any;
  endTime: any;
}



const DetailsCalendar: React.FC<IProps> = (props: any) => {
  const { t: translate } = useTranslation();


  const getDayFromDate = (startTime) => {
    if (startTime) {
      return FormatIsoDateWithPatternDayjs(startTime, 'DD');
    }
    else {
      return 'DD';
    }
  };


  const getMonthFromDate = (startTime) => {
    if (startTime) {
      return FormatIsoDateWithPatternDayjs(startTime, 'MMM');
    }
    else {
      return 'MMM';
    }
  };


  const getTextTimeDate = (startTime) => {
    if (startTime) {
      return FormatIsoDateWithPatternDayjs(startTime, 'DD/MM/YYYY');
    }
    else {
      return '**';
    }
  };


  const getTextTimeHour = (startTime) => {
    if (startTime) {
      return FormatIsoDateWithPatternDayjs(startTime, 'HH:mm');
    }
    else {
      return '**';
    }
  };



  return (

    <CalendarContainerStyle>

      <TimeCalendar>

        <DetailsDayCalendar>
          {getDayFromDate(props.startTime)}
        </DetailsDayCalendar>

        <DetailsDateCalendar>
          {getMonthFromDate(props.startTime)?.toUpperCase()}
        </DetailsDateCalendar>

      </TimeCalendar>



      <DetailsCalendarName>

        <CalendarInfoContent>
          <IconEventStart
            size={19}
            color={Colors.accent}
          />


          <CalendarInfoDates>
            <CalendarTitleHeader>
              {translate(TranslateConfig.EVENT_START)}
            </CalendarTitleHeader>

            <CalendarSubHeader>
              {`${getTextTimeDate(props.startTime)} - ${getTextTimeHour(props.startTime)}`}
            </CalendarSubHeader>
          </CalendarInfoDates>

        </CalendarInfoContent>



        <CalendarInfoContent>
          <IconEventEnd
            size={19}
            color={Colors.accent}
          />


          <CalendarInfoDates>
            <CalendarTitleHeader>
              {translate(TranslateConfig.EVENT_END)}
            </CalendarTitleHeader>

            <CalendarSubHeader>
              {`${getTextTimeDate(props.endTime)} - ${getTextTimeHour(props.endTime)}`}
            </CalendarSubHeader>
          </CalendarInfoDates>

        </CalendarInfoContent>

      </DetailsCalendarName>

    </CalendarContainerStyle>

  );
};



export default DetailsCalendar;
