// Backgrounds
import _appTermPoliciesPrivacity from '../assets/backgrounds/app-term-policies-privacity.png';
import _backgroundFooterAbout from '../assets/backgrounds/background_fallback.png';
import _LoginImageBackground from '../assets/backgrounds/background_login.png';
import _backgroundSplashBubble from '../assets/backgrounds/background_splash.png';


// Placeholders
import _placeholderAvatarCompany from '../assets/backgrounds/banner_avatar_company.png';
import _placeholderAvatarUser from '../assets/backgrounds/banner_avatar_user.png';
import _placeholderAvatarWaiter from '../assets/backgrounds/banner_avatar_waiter.png';
import _placeholderEvent from '../assets/backgrounds/banner_event.png';

import _patternSquare from '../assets/backgrounds/pattern_squares.png';


// Drawables
import _pinBecoDasCores from '../assets/drawables/color_carnaval_beco_das_cores.png';
import _pinFantasyParades from '../assets/drawables/color_carnaval_fantasy_parade.png';
import _pinParadeGate from '../assets/drawables/color_carnaval_parade_gate.png';
import _pinTransitAgent from '../assets/drawables/color_carnaval_transit_agent.png';
import _pinBathroom from '../assets/drawables/color_marker_bathroom.png';
import _pinBus from '../assets/drawables/color_marker_bus.png';
import _pinChild from '../assets/drawables/color_marker_child.png';
import _pinSelectiveOther from '../assets/drawables/color_marker_collect_other.png';
import _pinSelectiveCollect from '../assets/drawables/color_marker_collect_selective.png';
import _pinFire from '../assets/drawables/color_marker_fire.png';
import _pinLifeguard from '../assets/drawables/color_marker_lifeguard.png';
import _pinMedical from '../assets/drawables/color_marker_medical.png';
import _pinPoliceCivil from '../assets/drawables/color_marker_police_civil.png';
import _pinPoliceMilitar from '../assets/drawables/color_marker_police_militar.png';
import _pinTaxi from '../assets/drawables/color_marker_taxi.png';
import _pinHealthSurveillance from '../assets/drawables/color_marker_vigilancia_sanitaria.png';
import _markerNCompany from '../assets/drawables/marker_n_company.png';
import _markerNEvent from '../assets/drawables/marker_n_event.png';
import _markerNStreet from '../assets/drawables/marker_n_street.png';
import _pinCleanHealth from '../assets/drawables/marker-carnival-clean-health.png';
import _pinPublicAgency from '../assets/drawables/marker-carnival-public-agency.png';
import _pinReservedArea from '../assets/drawables/marker-carnival-reserved-area.png';


// Flags
import _countryBrazilFlag from '../assets/flags/flag-brazil.png';
import _countryFranceFlag from '../assets/flags/flag-france.png';
import _countryItalyFlag from '../assets/flags/flag-italy.png';
import _countryPortugalFlag from '../assets/flags/flag-portugal.png';
import _countrySpainFlag from '../assets/flags/flag-spain.png';
import _countryUnitedStatesFlag from '../assets/flags/flag-united-states.png';


// Images
import _flagPrideColor from '../assets/images/flag-lgbt-color.png';
import _flagPrideGray from '../assets/images/flag-lgbt-gray.png';

import _carnivalImageButton from '../assets/images/grid-button-carnival.png';
import _festivalImageButton from '../assets/images/grid-button-festival.png';
import _noitadaHomeImageButton from '../assets/images/grid-button-home.png';

import _infos from '../assets/images/infos.png';

import _colorLevelBasic from '../assets/images/level-basic.png';
import _colorLevelKing from '../assets/images/level-king.png';
import _colorLevelNone from '../assets/images/level-none.png';
import _colorLevelPersas from '../assets/images/level-persas.png';
import _colorLevelSuper from '../assets/images/level-prime.png';
import _colorLevelStar from '../assets/images/level-star.png';


// Markets
import _listEventDone from '../assets/images/list-event-done.png';
import _listEventWait from '../assets/images/list-event-wait.png';
import _locationGlobal from '../assets/images/location-global.png';

import _marketAppStore from '../assets/images/market_app_store.png';
import _marketGooglePlay from '../assets/images/market_google_play.png';
import _marketWindowsStore from '../assets/images/market_windows_store.png';


import _menuMarkerCarnival from '../assets/images/menu_marker_carnival.png';

import _noBar from '../assets/images/no_bar.png';
import _noCarnivalAttractions from '../assets/images/no_carnival_attraction.png';
import _noCarnivalCabin from '../assets/images/no_carnival_cabin.png';
import _noCarnivalDistrict from '../assets/images/no_carnival_district.png';
import _noCarnivalGuides from '../assets/images/no_carnival_guide.png';
import _noCarnivalPhones from '../assets/images/no_carnival_phone.png';
import _noCarnivalStreet from '../assets/images/no_carnival_street.png';
import _noClub from '../assets/images/no_club.png';
import _noFestival from '../assets/images/no_festival.png';
import _noHighlight from '../assets/images/no_highlight.png';
import _noOrderItems from '../assets/images/no_order_items.png';
import _noParty from '../assets/images/no_party.png';
import _noPayCards from '../assets/images/no_pay_cards.png';
import _noReserveUpcoming from '../assets/images/no_reserves_upcoming.png';
import _noFavoritesCompanies from '../assets/images/no-favorites-companies.png';
import _noFavoritesEvents from '../assets/images/no-favorites-events.png';
import _noHelp from '../assets/images/no-help.png';
import _noNoitag from '../assets/images/noitag-all-no.png';
import _noitagCardOff from '../assets/images/noitag-card-off.png';
import _noitagCardOn from '../assets/images/noitag-card-on.png';
import _noitagWatchOff from '../assets/images/noitag-watch-off.png';
import _noitagWatchOn from '../assets/images/noitag-watch-on.png';
import _noNotifications from '../assets/images/notifications.png';

import _order_done from '../assets/images/order_done.png';
import _ordersheet_done from '../assets/images/ordersheet_done.png';
import _ordersheet_finishing from '../assets/images/ordersheet_finishing.png';
import _ordersheet_pay_app from '../assets/images/ordersheet_pay_app.png';
import _ordersheet_pay_place from '../assets/images/ordersheet_pay_place.png';
import _ordersheet_wait from '../assets/images/ordersheet_wait.png';
import _permissions from '../assets/images/permissions.png';
import _searchEmpty from '../assets/images/search-empty.png';

import _separatorHorizontal from '../assets/images/separator_horizontal.png';
import _separatorVertical from '../assets/images/separator_vertical.png';
import _shadowFloor from '../assets/images/shadow_floor.png';


// Stickers
import _smile from '../assets/images/smile.png';
import _stickerCake from '../assets/images/sticker_cake.png';

import _ticketsEmpty from '../assets/images/tickets-empty.png';
import _ticketsWait from '../assets/images/tickets-wait.png';


// Tutorial e Configs
import _tutorial1 from '../assets/images/tutorial-1.png';
import _tutorial2 from '../assets/images/tutorial-2.png';
import _tutorial3 from '../assets/images/tutorial-3.png';

import _wifiInfos from '../assets/images/wifi-infos.png';
import _wifi from '../assets/images/wifi.png';


// Logos
import _logoMobileAndroidApp from '../assets/logos/icon_mobile_android_app.png';
import _logoIconAppBlack from '../assets/logos/logo_icon_app_black.png';
import _logoIconAppWhite from '../assets/logos/logo_icon_app_white.png';
import _logoUberIcon from '../assets/logos/logo_uber_icon.png';
import _logoWazeIcon from '../assets/logos/logo_waze_icon.png';
import _logoAppNameWhite from '../assets/logos/logo-name-app-white.png';
import _logoNoitadaCarnivalIcon from '../assets/logos/logo-noitada-carnival-icon.png';


// Payments
import _flagDefault from '../assets/payments/flag_card_acnull.png';
import _flagCardAmerican from '../assets/payments/flag_card_american.png';
import _flagCardDiners from '../assets/payments/flag_card_diners.png';
import _flagCardElo from '../assets/payments/flag_card_elo.png';
import _flagCardHipercard from '../assets/payments/flag_card_hipercard.png';
import _flagCardMastercard from '../assets/payments/flag_card_mastercard.png';
import _flagCardNubank from '../assets/payments/flag_card_nubank.png';
import _flagCardVisa from '../assets/payments/flag_card_visa.png';
import _creditCardFrontDefault from '../assets/payments/flag_credit_card_acnull_front.png';
import _creditCardBackDefault from '../assets/payments/flag_credit_card_acnull_upback.png';
import _creditCardFrontAmerican from '../assets/payments/flag_credit_card_american_front.png';
import _creditCardBackAmerican from '../assets/payments/flag_credit_card_american_upback.png';
import _creditCardFrontDiners from '../assets/payments/flag_credit_card_diners_front.png';
import _creditCardBackDiners from '../assets/payments/flag_credit_card_diners_upback.png';
import _creditCardFrontElo from '../assets/payments/flag_credit_card_elo_front.png';
import _creditCardBackElo from '../assets/payments/flag_credit_card_elo_upback.png';
import _creditCardFrontHipercard from '../assets/payments/flag_credit_card_hipercard_front.png';
import _creditCardBackHipercard from '../assets/payments/flag_credit_card_hipercard_upback.png';
import _creditCardFrontMastercard from '../assets/payments/flag_credit_card_mastercard_front.png';
import _creditCardBackMastercard from '../assets/payments/flag_credit_card_mastercard_upback.png';
import _creditCardFrontNubank from '../assets/payments/flag_credit_card_nubank_front.png';
import _creditCardBackNubank from '../assets/payments/flag_credit_card_nubank_upback.png';
import _creditCardFrontVisa from '../assets/payments/flag_credit_card_visa_front.png';
import _creditCardBackVisa from '../assets/payments/flag_credit_card_visa_upback.png';
import _flagDiscount from '../assets/payments/flag_discount.png';
import _flagTicketAlelo from '../assets/payments/flag_ticket_alelo.png';
import _flagTicketBen from '../assets/payments/flag_ticket_ben.png';
import _flagTicketSodexo from '../assets/payments/flag_ticket_sodexo.png';
import _flagTicketTicket from '../assets/payments/flag_ticket_ticket.png';
import _flagTicketVr from '../assets/payments/flag_ticket_vr.png';
import _flagTypeCash from '../assets/payments/flag_type_cash.png';
import _flagTypeCheque from '../assets/payments/flag_type_cheque.png';
import _flagTypePix from '../assets/payments/flag_type_pix.png';
import _logoAmerican from '../assets/payments/logo_american.png';
import _logoDiners from '../assets/payments/logo_diners.png';
import _logoElo from '../assets/payments/logo_elo.png';
import _logoHipercard from '../assets/payments/logo_hipercard.png';
import _logoMastercard from '../assets/payments/logo_mastercard.png';
import _logoNubank from '../assets/payments/logo_nubank.png';
import _logoVisa from '../assets/payments/logo_visa.png';



export const BACKGROUNDS = {
  loginImageBackground:             _LoginImageBackground,
  
  backgroundFooterAbout:            _backgroundFooterAbout,
  backgroundSplashBubble:           _backgroundSplashBubble,
  
  patternSquare:                    _patternSquare,

  appTermPoliciesPrivacity:         _appTermPoliciesPrivacity,
};



export const CARNIVAL = {
  pinTransitAgent:                  _pinTransitAgent,
  pinBecoDasCores:                  _pinBecoDasCores,

  pinBathroom:                      _pinBathroom,
  pinBus:                           _pinBus,
  pinChild:                         _pinChild,
  pinFire:                          _pinFire,
  pinLifeguard:                     _pinLifeguard,
  pinMedical:                       _pinMedical,
  pinTaxi:                          _pinTaxi,
  pinHealthSurveillance:            _pinHealthSurveillance,


  // Ordem
  pinPoliceCivil:                   _pinPoliceCivil,
  pinPoliceMilitar:                 _pinPoliceMilitar,
  pinParadeGate:                    _pinParadeGate,
  pinFantasyParades:                _pinFantasyParades,
  pinSelectiveCollect:              _pinSelectiveCollect,
  pinSelectiveOther:                _pinSelectiveOther,

  menuMarkerCarnival:               _menuMarkerCarnival,
  pinCleanHealth:                   _pinCleanHealth,
  pinPublicAgency:                  _pinPublicAgency,
  pinReservedArea:                  _pinReservedArea,
};



export const CONFIGS = {
  tutorial1:                        _tutorial1,
  tutorial2:                        _tutorial2,
  tutorial3:                        _tutorial3,
  permissions:                      _permissions,
};


export const FLAGS_COUNTRY = {
  countryBrazilFlag:                _countryBrazilFlag,
  countryPortugalFlag:              _countryPortugalFlag,
  countryItalyFlag:                 _countryItalyFlag,
  countryUnitedStatesFlag:          _countryUnitedStatesFlag,
  countrySpainFlag:                 _countrySpainFlag,
  countryFranceFlag:                _countryFranceFlag,
};



export const IMAGES_APP = {
  carnivalImageButton:              _carnivalImageButton,
  festivalImageButton:              _festivalImageButton,
  noitadaHomeImageButton:           _noitadaHomeImageButton,

  infos:                            _infos,

  wifi:                             _wifi,
  wifiInfos:                        _wifiInfos,
};



export const ICONS = {
  markerNCompany:                   _markerNCompany,
  markerNEvent:                     _markerNEvent,
  markerNStreet:                    _markerNStreet,

  flagPrideGray:                    _flagPrideGray,
  flagPrideColor:                   _flagPrideColor,
};



export const ITEMS = {
  orderDone:                        _order_done,
  orderSheetDone:                   _ordersheet_done,
  orderSheetFinishing:              _ordersheet_finishing,
  ordersheetPayApp:                 _ordersheet_pay_app,
  ordersheetPayPlace:               _ordersheet_pay_place,
  ordersheetWait:                   _ordersheet_wait,
};



export const ITEMS_EMPTY = {
  noBar:                            _noBar,
  noClub:                           _noClub,
  noHighlight:                      _noHighlight,
  noParty:                          _noParty,

  noAttractions:                    _noCarnivalAttractions,
  noCabin:                          _noCarnivalCabin,
  noDistrict:                       _noCarnivalDistrict,
  noStreet:                         _noCarnivalStreet,
  noGuides:                         _noCarnivalGuides,
  noPhones:                         _noCarnivalPhones,

  locationGlobal:                   _locationGlobal,

  noFavoritesCompanies:             _noFavoritesCompanies,
  noFavoritesEvents:                _noFavoritesEvents,
  
  noFestival:                       _noFestival,
  
  listEventDone:                    _listEventDone,
  listEventWait:                    _listEventWait,
  
  searchEmpty:                      _searchEmpty,

  ticketsEmpty:                     _ticketsEmpty,
  ticketsWait:                      _ticketsWait,

  noHelp:                           _noHelp,

  noNotifications:                  _noNotifications,
  noOrderItems:                     _noOrderItems,
  
  noReserveUpcoming:                _noReserveUpcoming,

  noNoitag:                         _noNoitag,
  noitagCardOff:                    _noitagCardOff,
  noitagCardOn:                     _noitagCardOn,
  noitagWatchOff:                   _noitagWatchOff,
  noitagWatchOn:                    _noitagWatchOn,

  noPayCards:                       _noPayCards,
};



export const LEVELS = {
  colorLevelNone:                   _colorLevelNone,
  colorLevelBasic:                  _colorLevelBasic,
  colorLevelPersas:                 _colorLevelPersas,
  colorLevelSuper:                  _colorLevelSuper,
  colorLevelStar:                   _colorLevelStar,
  colorLevelKing:                   _colorLevelKing,
};



export const LOGOS = {
  logoIconAppBlack:                 _logoIconAppBlack,
  logoIconAppWhite:                 _logoIconAppWhite,
  logoMobileAndroidApp:             _logoMobileAndroidApp,
  logoAppNameWhite:                 _logoAppNameWhite,
  
  logoUberIcon:                     _logoUberIcon,
  logoWazeIcon:                     _logoWazeIcon,

  logoNoitadaCarnivalIcon:          _logoNoitadaCarnivalIcon,
};



export const MARKETS = {
  marketGooglePlay:                 _marketGooglePlay,
  marketAppStore:                   _marketAppStore,
  marketWindowsStore:               _marketWindowsStore,
};



export const PAYMENTS = {
  flagDiscount:                     _flagDiscount,

  flagTypeCash:                     _flagTypeCash,
  flagTypeCheque:                   _flagTypeCheque,
  flagTypePix:                      _flagTypePix,

  flagTicketAlelo:                  _flagTicketAlelo,
  flagTicketBen:                    _flagTicketBen,
  flagTicketSodexo:                 _flagTicketSodexo,
  flagTicketTicket:                 _flagTicketTicket,
  flagTicketVr:                     _flagTicketVr,

  flagDefault:                      _flagDefault,
  creditCardFrontDefault:           _creditCardFrontDefault,
  creditCardBackDefault:            _creditCardBackDefault,

  logoAmerican:                     _logoAmerican,
  flagCardAmerican:                 _flagCardAmerican,
  creditCardFrontAmerican:          _creditCardFrontAmerican,
  creditCardBackAmerican:           _creditCardBackAmerican,

  logoDiners:                       _logoDiners,
  flagCardDiners:                   _flagCardDiners,
  creditCardFrontDiners:            _creditCardFrontDiners,
  creditCardBackDiners:             _creditCardBackDiners,

  logoElo:                          _logoElo,
  flagCardElo:                      _flagCardElo,
  creditCardFrontElo:               _creditCardFrontElo,
  creditCardBackElo:                _creditCardBackElo,

  logoHipercard:                    _logoHipercard,
  flagCardHipercard:                _flagCardHipercard,
  creditCardFrontHipercard:         _creditCardFrontHipercard,
  creditCardBackHipercard:          _creditCardBackHipercard,

  logoMastercard:                   _logoMastercard,
  flagCardMastercard:               _flagCardMastercard,
  creditCardFrontMastercard:        _creditCardFrontMastercard,
  creditCardBackMastercard:         _creditCardBackMastercard,

  logoNubank:                       _logoNubank,
  flagCardNubank:                   _flagCardNubank,
  creditCardFrontNubank:            _creditCardFrontNubank,
  creditCardBackNubank:             _creditCardBackNubank,

  logoVisa:                         _logoVisa,
  flagCardVisa:                     _flagCardVisa,
  creditCardFrontVisa:              _creditCardFrontVisa,
  creditCardBackVisa:               _creditCardBackVisa,
};



export const PLACEHOLDERS = {
  placeholderAvatarCompany:         _placeholderAvatarCompany,
  placeholderAvatarUser:            _placeholderAvatarUser,
  placeholderAvatarWaiter:          _placeholderAvatarWaiter,
  placeholderEvent:                 _placeholderEvent,
};



export const SEPARATORS = {
  separatorHorizontal:              _separatorHorizontal,
  separatorVertical:                _separatorVertical,
  shadowFloor:                      _shadowFloor,
};



export const STICKERS = {
  smile:                            _smile,
  stickerCake:                      _stickerCake,
};

const Images = {
  ...BACKGROUNDS,
  ...CARNIVAL,
  ...CONFIGS,
  ...FLAGS_COUNTRY,
  ...IMAGES_APP,
  ...ICONS,
  ...ITEMS,
  ...ITEMS_EMPTY,
  ...LEVELS,
  ...LOGOS,
  ...MARKETS,
  ...PAYMENTS,
  ...PLACEHOLDERS,
  ...SEPARATORS,
  ...STICKERS,
};



export default Images;
