import {
  Images,
} from '../../constants';

import {
  TranslateConfig,
} from '../../translations';



export const countriesOption: Array<{ code: any, namePt: string, label: any, image: any, moneyFull: any, moneySymbol: any }> = [
  {
    code: 'BRA',
    namePt: 'Brasil',
    label: TranslateConfig.BRAZIL,
    image: 'Images.countryFlagBrazil',
    moneyFull: 'BRL (R$)',
    moneySymbol: 'R$',
  },
  {
    code: 'POR',
    namePt: 'Portugal',
    label: TranslateConfig.PORTUGAL,
    image: 'Images.countryFlagPortugal',
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'USA',
    namePt: 'Estados Unidos',
    label: TranslateConfig.UNITED_STATES,
    image: 'Images.countryFlagUnitedStates',
    moneyFull: 'USA ($)',
    moneySymbol: '$',
  },
  {
    code: 'ESP',
    namePt: 'Espanha',
    label: TranslateConfig.SPAIN,
    image: 'Images.countryFlagSpain',
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'ITA',
    namePt: 'Itália',
    label: TranslateConfig.ITALY,
    image: 'Images.countryFlagItaly',
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
  {
    code: 'FRA',
    namePt: 'França',
    label: TranslateConfig.FANCE,
    image: 'Images.countryFlagFrance',
    moneyFull: 'EUR (€)',
    moneySymbol: '€',
  },
];
