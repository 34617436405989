import React from 'react';

import {
  _setLevelColor,
  _setLevelText,
} from '@noitada/shared/config/user-levels.config';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EUserLevel,
} from '@noitada/shared/enums';

import {
  CheckInUserLevel,
} from './styled';



export interface IProps {
  size?: number;
  level?: string;
}



const BadgeLevel: React.FC<IProps> = (props: IProps) => {
  const className: any = { ...props };


  const levelProps = props?.level || '';


  function setColorByLevel() {
    return levelProps.toUpperCase() === EUserLevel.KING ? Colors.black : Colors.white;
  }


  function setFontWeightByLevel() {
    return levelProps.toUpperCase() === EUserLevel.KING ? 'bold' : 'normal';
  }


  function setBackbroundColorByLevel() {
    return _setLevelColor(levelProps);
  }



  return (

    <CheckInUserLevel
      className={className?.className}

      fontSize={props.size}
      color={setColorByLevel()}
      backgroundColor={setBackbroundColorByLevel()}
      fontWeight={setFontWeightByLevel()}>
      {_setLevelText(levelProps)}
    </CheckInUserLevel>

  );
};



export default BadgeLevel;
