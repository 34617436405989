import styled from 'styled-components';

import {
  Sizes,
} from '@noitada/shared/constants';

import {
  Body,
  ListFlat,
} from '../../../components/Composh/web';

const paddingHighlight = Sizes.paddingFlatList + 'px';



export const SearchResultBody = styled(Body)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -7px;
`;



export const ProfileSearchScrollCardsContent = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: paddingHighlight,
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
    paddingBottom: paddingHighlight,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;



export const EventSearchScrollFlatList = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    paddingTop: paddingHighlight,
    paddingLeft: paddingHighlight,
    paddingRight: paddingHighlight,
    paddingBottom: paddingHighlight,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
