export const APP_KEYS = {
  UberKey:                          'BCI1rhO33PHD4Lowed_iGQKYYzPBjvxN',
};


export const NOITADA_CONTACT = {
  whatsAppNumber:                   '5571992742085',
  whatsAppMessage:                  'Olá! Eu gostaria de mais informações sobre a Noitada. Poderia me ajudar?',

  messengerUsername:                'noitadaBRA',
  messengerIDUsername:              '636260263204734',
  messengerLink:                    'https://www.messenger.com/t/636260263204734',
};


export const NOITADA_SOCIAL = {
  facebookUsername:                 'noitadaBRA',
  facebookIDUsername:               '636260263204734',
  facebookLink:                     'https://www.facebook.com/noitadaBRA',

  instagramUsername:                'noitadaBRA',
  instagramLink:                    'https://www.instagram.com/_u/noitadaBRA',
  
  twitterUsername:                  'noitadaBRA',
  twitterIDUsername:                '837020906467766272',
  twitterLink:                      'https://www.twitter.com/noitadaBRA',

  linkedInLink:                     'https://www.linkedin.com/company/noitada',

  youtubeChannel:                   '@noitadaBRA',
  youtubeLink:                      'https://www.youtube.com/@noitadaBRA',

  threadsLink:                      'https://www.threads.net/@noitadabra',

  tiktokLink:                       'https://www.tiktok.com/@noitadabra',

  noitadaEmailContact:              'mailto:noitadabra@gmail.com',

  jobsLink:                         'https://www.linkedin.com/company/noitada/jobs',
};


export const NOITADA_STORES = {
  noitadaName:                      'noitada',
  noitadaPackage:                   'com.noitada',
  noitadaStoreID:                   '1455081329',
  noitadaMobileAppAndroidLink:      'https://play.google.com/store/apps/details?id=com.noitada',

  organizerName:                    'noitada-organizer',
  organizerPackage:                 'com.noitada.organizer',
  organizerStoreID:                 '1490572081',
  noitadaMobileAppIosLink:          'https://apps.apple.com/us/app/noitada/id1455081329',
};



const Keys = {
  ...APP_KEYS,
  ...NOITADA_CONTACT,
  ...NOITADA_SOCIAL,
  ...NOITADA_STORES,
};



export default Keys;
