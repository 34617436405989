import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  selected?: boolean;
  color?: string;
}



export const DayView = styled.div`
  display: flex;
  flex-direction: column;
`;



export const DayItem = styled.p`
  margin-bottom: 1px;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 11px;
  text-align: center;
  font-weight: ${(props: IProps) => props.selected ? 'bold' : 'normal'};
`;

export const DateItem = styled.p`
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 14px;
  text-align: center;
  font-weight: ${(props: IProps) => props.selected ? 'bold' : 'normal'};
`;


export const YearItem = styled.p`
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 11px;
  letter-spacing: 1px;
  text-align: center;
  font-weight: ${(props: IProps) => props.selected ? 'bold' : 'normal'};
`;
