import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  Screen,
  Title,
  ToolbarGroup,
} from '../../components/Composh/web';

import {
  ButtonBuild,
  ButtonDrawer,
  ButtonMore,
} from '../../components/Controls';

import EmptyContent from '../../components/Empty';
import HeaderScreen from '../../components/Headers/HeaderScreen';
import NameRoutes from '../../navigation/names';

import {
  CashlessOptionsContainer,
  CashlessOptionsContent,
  CashlessItemContent,
  CashlessItemImage,
  CashlessItemText,
  CashlessFooterOptionsText,
  CashlessFooterHelp,
} from './styled';



const NoitagScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();


  const [activeNoitag, setActiveNoitag] = useState<boolean>(false);

  const [noitagCard, setNoitagCard] = useState<string>('');
  const [noitagWatch, setNoitagWatch] = useState<string>('');



  function openNoitagSetting() {
    history.push(NameRoutes.SettingWalletNoitagScreen);
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noNoitag}
        title={'O uso da Noitag não está ativado'}
        buttonText={'Clique para configurar'}
        onPress={() => {
          openNoitagSetting();
        }}
      />

    );
  }



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.NOITAG });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <Title
            color={Colors.white}>
            Noitag
          </Title>
        }
        rightIcon={
          <ToolbarGroup>
            <ButtonBuild
              color={Colors.white}
              onPress={() => {
                openNoitagSetting();
              }}
            />


            <ButtonMore
              color={Colors.white}
            />
          </ToolbarGroup>
        }
      />



      {!activeNoitag && listEmptyComponent()}



      {activeNoitag && (
        <CashlessOptionsContainer>

          <CashlessOptionsContent>

            <CashlessItemContent>
              <CashlessItemImage
                src={noitagCard
                  ? Images.noitagCardOn
                  : Images.noitagCardOff
                }
              />


              <CashlessItemText>
                {noitagCard
                  ? 'Noitag Card vinculado ao seu perfil'
                  : 'Noitag Card ainda não vinculado'
                }
              </CashlessItemText>
            </CashlessItemContent>



            <CashlessItemContent>
              <CashlessItemImage
                src={noitagWatch
                  ? Images.noitagWatchOn
                  : Images.noitagWatchOff
                }
              />


              <CashlessItemText>
                {noitagWatch
                  ? 'Pulseira Noitag vinculado ao seu perfil'
                  : 'Pulseira Noitag ainda não vinculado'
                }
              </CashlessItemText>
            </CashlessItemContent>

          </CashlessOptionsContent>



          <CashlessFooterOptionsText>
            Você pode vincular as Noitags em nossos parceiros credenciados ou nas lojas Noitada
          </CashlessFooterOptionsText>



          <CashlessFooterHelp>
            Precisa de ajuda?
          </CashlessFooterHelp>

        </CashlessOptionsContainer>
      )}

    </Screen>

  );
};



export default NoitagScreen;
