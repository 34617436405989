import React from 'react';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  IconItemAlcoholYes,
  IconItemGlutenNo,
  IconItemLactoseNo,
  IconItemOrganicYes,
  IconItemSpicyYes,
  IconItemVeganYes,
  IconItemVegetarianYes,
} from '../../../Icons';

import {
  OrderMenuContainer,
  OrdeMenuItemViewImage,
  OrdeMenuItemImage,
  OrderTypesItemView,
  OrderTypesItemIcon,
  OrderMenuInfoView,
  OrderMenuTitle,
  OrderMenuDescription,
  OrderMenuFooterView,
  OrderMenuPrice,
} from './styled';



export interface IProps {
  image?: string;

  name?: string;
  description?: string;
  price?: any;

  alcoholic?: boolean;
  spicy?: boolean;
  vegetarian?: boolean;
  vegan?: boolean;
  organic?: boolean;
  noLactose?: boolean;
  noGlutem?: boolean;

  onPress?: any;
}



const CardOrderMenu: React.FC<IProps> = (props: IProps) => {
  return (

    <OrderMenuContainer
      // activeOpacity={Sizes.CardActiveOpacity}
      onClick={props.onPress}>

      <OrdeMenuItemViewImage>
        <OrdeMenuItemImage
          // resizeMode={'cover'}
          src={validateString(props.image)
            ? props.image
            : Images.placeholderAvatarCompany
          }
        />


        {(props.alcoholic || props.spicy || props.vegetarian || props.vegan || props.organic || props.noLactose || props.noGlutem) && (
          <OrderTypesItemView>

            {props.alcoholic && (
              <OrderTypesItemIcon>
                <IconItemAlcoholYes
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}


            {props.spicy && (
              <OrderTypesItemIcon>
                <IconItemSpicyYes
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}


            {props.vegetarian && (
              <OrderTypesItemIcon>
                <IconItemVegetarianYes
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}


            {props.vegan && (
              <OrderTypesItemIcon>
                <IconItemVeganYes
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}


            {props.organic && (
              <OrderTypesItemIcon>
                <IconItemOrganicYes
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}


            {props.noLactose && (
              <OrderTypesItemIcon>
                <IconItemLactoseNo
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}


            {props.noGlutem && (
              <OrderTypesItemIcon>
                <IconItemGlutenNo
                  color={Colors.white}
                  size={16}
                />
              </OrderTypesItemIcon>
            )}

          </OrderTypesItemView>
        )}

      </OrdeMenuItemViewImage>



      <OrderMenuInfoView>

        <OrderMenuTitle>
          {props.name}
        </OrderMenuTitle>


        <OrderMenuDescription>
          {props.description}
        </OrderMenuDescription>


        <OrderMenuFooterView>
          <OrderMenuPrice>
            {props.price}
          </OrderMenuPrice>
        </OrderMenuFooterView>

      </OrderMenuInfoView>

    </OrderMenuContainer>

  );
};



export default CardOrderMenu;
