import React,
{
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  Formik,
  FormikProps,
} from 'formik';

import {
  SearchProfiles,
} from '@noitada/axios-config/endpoints/search.endpoints';

import SearchProfileModel from '@noitada/axios-config/models/Search/search-profile.model';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  companyTypesKitchenArray,
} from '@noitada/shared/arrays/company-kitchen.array';

import {
  companyModalityArray,
} from '@noitada/shared/arrays/company-modalities.array';

import {
  companyServicesArray,
} from '@noitada/shared/arrays/company-services.array';

import {
  companyTypeArray,
} from '@noitada/shared/arrays/company-type.array';

import {
  Colors,
  Values,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

// import SearchProfileValidationSchema from '@noitada/shared/validations/Search/search-profile.validations';

import LocationUser from '../../../../../components/Cards/CardLocation/Location';
import ChipApp from '../../../../../components/Chip';

import {
  Container,
  Content,
  Epigraph,
  Input,
} from '../../../../../components/Composh/web';

import FooterChoose from '../../../../../components/Footers/FooterChoose';

import InputText from '../../../../../components/Inputs/InputText';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  returnIconCompanyModalities,
} from '../../../../../config/icon-modalities.config';

import {
  returnIconCompanyServices,
} from '../../../../../config/icon-services.config';

import {
  returnIconCompanyType,
} from '../../../../../config/icon-types-company.config';

import NameRoutes from '../../../../../navigation/names';

import {
  ButtonText,
  DetailsSectionView,
} from '../../../../../styles/styled.layout';

import {
  SearchLabelContainer,
  SearchLabelTypeText,
  DividerSearch,
  SearchParamsContainer,
  SearchTogglesContent,
  SearchToggle25Grid,
  SearchToggle33Grid,
  SearchChipContent,
} from './styled';



export interface IProps {
  location?: any;
  openLocation?: any;
}



const TabSearchCompanyScreen: React.FC<IProps> = (props: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const searchLocality = props.location;


  const formik = useRef<FormikProps<any>>(null);

  const [companyType, setCompanyType] = useState<Array<any>>([]);
  const [modalityCompany, setModalityCompany] = useState<string | null>(null);
  const [servicesCompany, setServicesCompany] = useState<Array<any>>([]);
  const [typesKitchen, setTypesKitchen] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  const [mounted, setMounted] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function updateForm() {
    setTimeout(() => {
      setMounted(true);
    }, Values.mountTime);
  }


  function setArrayMoreItems(name: string, item: string, valueTo: any, setValueTo: any) {
    const oldValues = [...valueTo];
    const index = oldValues.indexOf(item);
    if (index !== -1) {
      oldValues.splice(index, 1);
    }
    else {
      oldValues.push(item);
    }
    setValueTo(oldValues);
    formik.current?.setFieldValue(name, oldValues);
  }


  function renderTypesItem(item: any, index: number) {
    const verifyItem = companyType.some((valueItem) => valueItem === item.value);


    return (
      <SearchToggle25Grid
        key={index}
        icon={returnIconCompanyType(
          item.value,
          verifyItem
            ? Colors.buttonOkAccent
            : Colors.textApp,
          35,
        )}
        iconGridLabel={translate(item.label)}
        iconGridColor={
          verifyItem
            ? Colors.buttonOkAccent
            : Colors.textApp
        }
        onPress={() => {
          setArrayMoreItems('types', item.value, companyType, setCompanyType);
        }}
      />

    );
  }


  function renderModalities(item: any, index: number, onPress: any) {
    return (

      <SearchToggle33Grid
        key={index}
        icon={returnIconCompanyModalities(
          item.value,
          modalityCompany === item.value
            ? Colors.buttonOkAccent
            : Colors.textApp,
          35,
        )}
        iconGridLabel={translate(item.label)}
        iconGridColor={
          modalityCompany === item.value
            ? Colors.buttonOkAccent
            : Colors.textApp
        }
        onPress={onPress}
      />

    );
  }


  function renderServicesItem(item: any, index: number) {
    const verifyItem = servicesCompany.some((valueItem) => valueItem === item.value);


    return (

      <SearchToggle33Grid
        key={index}
        icon={returnIconCompanyServices(
          item.value,
          verifyItem
            ? Colors.buttonOkAccent
            : Colors.textApp,
          35,
        )}
        iconGridLabel={translate(item.label)}
        iconGridColor={
          verifyItem
            ? Colors.buttonOkAccent
            : Colors.textApp
        }
        onPress={() => {
          setArrayMoreItems('services', item.value, servicesCompany, setServicesCompany);
        }}
      />

    );
  }


  function renderChipItem(item: any, index: number) {
    const verifyItem = typesKitchen.some((valueItem) => valueItem === item.value);


    return (

      <ChipApp
        key={index}
        disabled={loading}
        selected={verifyItem}
        title={translate(item.label)}
        onPress={() => {
          setArrayMoreItems('typeKitchens', item.value, typesKitchen, setTypesKitchen);
        }}
      />

    );
  }


  async function searchCompanyProfiles(values: any) {
    let payload: any;

    if (values.usernameOrName) {
      payload = {
        name: values.usernameOrName,
      };
    }
    else {
      payload = {
        city: searchLocality.city,
        state: searchLocality.state,
        country: searchLocality.country,

        types: values.types,
        params: values.modality,
        services: values.services,
        kitchenTypes: values.typeKitchens,
      };
    }

    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await SearchProfiles(payload);

      if (response) {
        history.replace({
          pathname: NameRoutes.SearchResultsScreen,
          state: {
            type: 'COMPANY',
            results: response,
          },
        });
      }
      else {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, response.message || translate(TranslateConfig.ERROR_SEARCH));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }



  useEffect(() => {
    updateForm();
  }, [formik]);



  return (

    <Formik
      enableReinitialize
      validateOnMount={false}
      innerRef={formik}
      initialValues={SearchProfileModel}
      // validationSchema={SearchProfileValidationSchema}
      onSubmit={(values) => {
        searchCompanyProfiles(values);
      }}>
      {({
        dirty,
        isValid,
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        // errors,
        setFieldValue,
      }) => (

        <Container>

          {!mounted && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {mounted && (
            <Content>

              <SearchLabelContainer>
                <SearchLabelTypeText>
                  {translate(TranslateConfig.HELP_SEARCH_BY_NAME)}
                </SearchLabelTypeText>


                <InputText
                  disabled={loading}
                  autoCorrect
                  autoCapitalize={'words'}
                  type={'TEXT'}
                  labelText={translate(TranslateConfig.NAME_OR_USERNAME)}
                  placeholderText={translate(TranslateConfig.NAME_OR_USERNAME)}
                  // helpText={translate(errors.usernameOrName || '')}
                  countLimit={Values.nameMaxCount}
                  value={values.usernameOrName}
                  onChange={handleChange('usernameOrName')}
                  onBlur={handleBlur('usernameOrName')}
                />
              </SearchLabelContainer>



              {!values.usernameOrName && (
                <SearchParamsContainer>

                  <DividerSearch
                    height={0.5}
                    width={'100%'}
                    color={Colors.menuSeparator}
                  />



                  <SearchLabelTypeText>
                    {translate(TranslateConfig.HELP_SEARCH_PARAMETERS)}
                  </SearchLabelTypeText>


                  <DetailsSectionView>
                    <LocationUser
                      noMargin
                      city={searchLocality?.city}
                      state={searchLocality?.state}
                      country={searchLocality?.country}
                      onPress={() => {
                        props.openLocation(true);
                      }}
                    />
                  </DetailsSectionView>



                  <DetailsSectionView>
                    <Epigraph.Section
                      text={translate(TranslateConfig.PROFILE_TYPE)}
                      textColor={Colors.primary}
                      borderBottomColor={Colors.primary}
                    />


                    <Input.View
                      noShadow
                      backgroundColor={Colors.transparent}
                      // helpText={translate(errors.type || '')}
                      helpColor={Colors.danger}
                      countColor={Colors.textDescription}
                      countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>

                      <SearchTogglesContent>
                        {companyTypeArray.map((item: any, index: number) => (
                          renderTypesItem(item, index)
                        ))}
                      </SearchTogglesContent>
                    </Input.View>

                  </DetailsSectionView>



                  <DetailsSectionView>
                    <Epigraph.Section
                      text={translate(TranslateConfig.MODALITIES)}
                      textColor={Colors.primary}
                      borderBottomColor={Colors.primary}
                    />


                    <Input.View
                      noShadow
                      backgroundColor={Colors.transparent}
                      // helpText={translate(errors.modality || )}
                      helpColor={Colors.danger}
                      countColor={Colors.textDescription}
                      countLimit={translate(TranslateConfig.VALIDATE_ONLY_ONE)}>

                      <SearchTogglesContent>
                        {companyModalityArray.map((item: any, index: number) => (
                          renderModalities(
                            item,
                            index,
                            () => {
                              setModalityCompany(item.value);
                              setFieldValue('modality', item.value);
                            },
                          )
                        ))}
                      </SearchTogglesContent>
                    </Input.View>

                  </DetailsSectionView>



                  <DetailsSectionView>
                    <Epigraph.Section
                      text={translate(TranslateConfig.PARTNER_SERVICES)}
                      textColor={Colors.primary}
                      borderBottomColor={Colors.primary}
                    />


                    <Input.View
                      noShadow
                      backgroundColor={Colors.transparent}
                      // helpText={translate(errors.services || '')}
                      helpColor={Colors.danger}
                      countColor={Colors.textDescription}
                      countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>

                      <SearchTogglesContent>
                        {companyServicesArray.map((item: any, index: number) => (
                          renderServicesItem(item, index)
                        ))}
                      </SearchTogglesContent>
                    </Input.View>

                  </DetailsSectionView>



                  <DetailsSectionView>
                    <Epigraph.Section
                      text={translate(TranslateConfig.PARTNER_TYPES_KITCHEN)}
                      textColor={Colors.primary}
                      borderBottomColor={Colors.primary}
                    />


                    <Input.View
                      noShadow
                      backgroundColor={Colors.transparent}
                      countColor={Colors.textDescription}
                      countLimit={translate(TranslateConfig.VALIDATE_ONE_MORE)}>
                      <SearchChipContent>

                        {companyTypesKitchenArray.map((item: any, index: number) => (
                          renderChipItem(item, index)
                        ))}

                      </SearchChipContent>
                    </Input.View>

                  </DetailsSectionView>

                </SearchParamsContainer>
              )}

            </Content>
          )}



          <FooterChoose
            hideCancelButton

            okDisabled={!dirty || !isValid || loading}
            okColor={!dirty || !isValid || loading
              ? ArrayColors.arrayDisabled
              : ArrayColors.arrayOk
            }
            okLabel={
              <ButtonText
                color={Colors.white}>
                {translate(TranslateConfig.ACTION_SEARCH)}
              </ButtonText>
            }
            okPress={() => {
              handleSubmit();
            }}
          />

        </Container>

      )}
    </Formik>

  );
};



export default TabSearchCompanyScreen;
