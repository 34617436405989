import NoitadaWS from '../index';



export const UserGetReservations = async (period: string) => {
  const url = '/app/reserves/list';

  const response = await NoitadaWS.get(url, {
    params: {
      period: period,
    },
  });
  return response.data;
};



export const GetEnabledReserves = async (companyId: any) => {
  const url = `/app/reserves/available/${companyId}`;
  const response = await NoitadaWS.get(url);
  return response.data;
};



export const GetReserveDetail = async (reserveId: any) => {
  const url = `/app/reserves/details/${reserveId}`;
  const response = await NoitadaWS.get(url);
  return response.data;
};



export const UserMakeReservation = async (data: any) => {
  const url = '/app/reserves/create';

  const response = await NoitadaWS.post(url, data);
  return response;
};



export const CancelReservation = async (data: any) => {
  const url = '/app/reserves/cancel';

  const response = await NoitadaWS.post(url, data);
  return response;
};
