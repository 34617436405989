import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Card,
} from '../../../Composh/web';



export interface IProps {
  backgroundColor?: string;
  color?: string;
}



export const CardWaitListsContainer = styled(Card.View)`
  display: flex;
  flex-direction: column;
  margin-top: 3px;
  margin-bottom: 3px;
  padding-top: 10px;
  padding-left: 9px;
  padding-right: 9px;
  padding-bottom: 10px;
`;


export const CardWaitListsDataView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;



export const CheckInAvatarContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 55px;
  margin-right: 8px;
`;

export const CardWaitListsImage = styled.img`
  width: 50px;
  height: 50px;
  margin-bottom: 7px;
  border-radius: ${Sizes.cardRadius}px;
  object-fit: cover;
`;



export const CardWaitListsInfoView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CheckInInfoContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const CheckInUsernameContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 2px;
`;

export const CardWaitListsName = styled.p`
  color: ${Colors.white};
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
`;

export const CardWaitListsDescription = styled.p`
  color: ${Colors.white};
  font-size: 14px;
  line-height: 18px;
`;

export const CheckInVerifiedIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 8px;
`;



export const CardWaitListsCountsView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 8px;
`;

export const CardWaitListsCountsChip = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 8px;
  padding-top: 4px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 4px;
  background-color: #dbe6e3;
  border-radius: 40px;
`;

export const CardWaitListsCountsTitle = styled.p`
  margin-left: 4px;
  color: ${Colors.black};
  font-size: 13px;
  line-height: 16px;

  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow-wrap: anywhere;
`;



export const CardWaitListsPriorityView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
`;

export const CardWaitListsPriorityTitle = styled.p`
  margin-top: 4px;
  color: ${Colors.black};
  font-size: 12px;
`;



export const CardWaitListsReportsView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 5px;
`;

export const CardWaitListsStatusText = styled.p`
  margin-top: 4px;
  color: ${(props: IProps) => props.color || Colors.black};
  font-size: 13px;
  font-weight: bold;
`;

export const CardWaitListsReportsText = styled.p`
  margin-top: 4px;
  color: ${Colors.gray};
  font-size: 13px;
`;
