import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetCarnivalAttractions,
} from '@noitada/axios-config/endpoints/carnival.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  CARNIVAL_ACTIONSHEET_CIRCUITS,
  CARNIVAL_SELECTED_CIRCUITS,
} from '@noitada/redux-config/reducers/carnival-festivals.store';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  convertToDateHours,
  FormatIsoDateWithPatternDayjs,
  isValidDate,
} from '@noitada/shared/utils/date.utils';

import ButtonCityCarnival from '../../../../components/Buttons/ButtonCitiesApp/CityCarnival';
import ButtonSelect from '../../../../components/Buttons/ButtonSelect';
import CardCarnivalAttraction from '../../../../components/Cards/CardCarnival/CardCarnivalAttraction';

import {
  Container,
  Shimmer,
  ToolbarGroup,
} from '../../../../components/Composh/web';

import CalendarHorizontal from '../../../../components/Composh/web/CalendarHorizontal';

import {
  ButtonCarnivalGuide,
  ButtonCarnivalMusic,
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import EmptyContent from '../../../../components/Empty';
import HeaderScreen from '../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../navigation/names';

import {
  ChangeEventTypeButton,
  ChangeEventTypeImage,
} from '../../../../styles/styled.layout';

import {
  HighlightMenuSeparator,
  HighlightsSeparator,
  AttractionsCarnivalFlatList,
} from './styled';



const TabAttractionsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const carnivalLocalities = useSelector((state: RootState) => state.localities.selected);

  const periodCarnival = useSelector((state: RootState) => state.carnivalFestivals.itemCarnival);
  const selectedCircuit = useSelector((state: RootState) => state.carnivalFestivals.selectedCircuit);


  const [selectedDate, setSelectedDate] = useState(new Date());
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [allCircuits, setAllCircuits] = useState<any>(null);

  const [events, setEvents] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function setupAttractions(responseAttractions: Array<any>, defaultCircuit: any) {
    if (defaultCircuit?.length < 0 || responseAttractions?.length < 0) {
      throw { hasError: true, message: 'Parametros inválidos' };
    }

    if (defaultCircuit) {
      if (responseAttractions) {
        const circuitFilter = responseAttractions?.find((itemCircuit: any) => String(itemCircuit?.key).toUpperCase() === String(defaultCircuit?.key).toUpperCase());

        const allEventes: any[] = [];

        if (circuitFilter?.dates?.length > 0) {
          const haveSchedule = circuitFilter?.dates[0].schedules;

          circuitFilter?.dates.forEach((itemFilter: any) => {
            itemFilter.schedules.forEach((itemSchedule: any) => {
              allEventes.push(itemSchedule);
            });
          });

          if (haveSchedule) {
            setEvents(allEventes);
          }
        }
        else {
          setEvents([]);
        }
      }
    }
  }

  async function getAttractionsList() {
    try {
      setLoading(true);

      const response = await GetCarnivalAttractions({ ...carnivalLocalities, period: periodCarnival?.id });

      const listResponse = response;

      if (listResponse?.length > 0) {
        const allCirc: Array<any> = [];
        listResponse?.map((item: any, index: number) => {
          allCirc.push({
            id: index,
            label: item?.name,
            key: item?.key,
            value: index,
            dates: item?.dates,
            circuit: item?.circuit,
          });
        });
        const defaultCircuit = allCirc[0];
        const start_date = isValidDate(periodCarnival.start_at) ? convertToDateHours(periodCarnival.start_at, 9) : new Date();
        dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: defaultCircuit });
        setAllCircuits(allCirc);
        setSelectedDate(start_date);

        setupAttractions(allCirc, defaultCircuit);
      }
      else {
        dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: null });
        setAllCircuits([]);
      }
    }
    catch (error: any) {
      console.error(error);
      dispatch({ type: CARNIVAL_SELECTED_CIRCUITS, payload: null });
      setAllCircuits([]);
      dispatch({
        type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: {
          show: false,
          title: '',
          data: [],
        },
      });

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  function renderItem(item: any, index: number) {
    return (

      <CardCarnivalAttraction
        key={index}
        confirmed={item?.confirmed}
        blocName={item?.bloc_name}
        singerName={item?.atractions}
        singerImage={item?.picture_atractions}
        blocImage={item?.picture_bloc}
        hour={item?.hour}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function renderFooter() {
    return (

      <LoadingScreen
        type={'FOOTER'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noAttractions}
        title={translate(TranslateConfig.EMPTY_CARNIVAL_ATTRACTIONS)}
      />

    );
  }



  useEffect(() => {
    if (periodCarnival?.id > 0) {
      const start_date = isValidDate(periodCarnival.start_at) ? convertToDateHours(periodCarnival.start_at) : new Date();
      const end_date = isValidDate(periodCarnival.end_at) ? convertToDateHours(periodCarnival.end_at) : new Date();
      setStartDate(start_date);
      setEndDate(end_date);
      setSelectedDate(start_date);
      getAttractionsList();
    }
  }, []);


  useEffect(() => {
    setupAttractions(allCircuits, selectedCircuit);
  }, [selectedCircuit]);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityCarnival />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonCarnivalMusic
              color={Colors.white}
            />

            <ButtonCarnivalGuide
              color={Colors.white}
            />

            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        middleToolbar={
          <HighlightMenuSeparator>

            <HighlightsSeparator>

              <Shimmer
                height={40}
                width={140}
                opacity={Sizes.shimmerOpacity}
                borderRadius={Sizes.cardRadius}
                visible={!loading}>

                <ButtonSelect
                  activeOpacity={Sizes.CardActiveOpacity}
                  disabled={loading || allCircuits?.length <= 0}
                  title={selectedCircuit?.label || '-'}
                  subtitle={translate(TranslateConfig.CIRCUITS)}
                  onPress={() => {
                    if (allCircuits?.length > 0) {
                      dispatch({
                        type: CARNIVAL_ACTIONSHEET_CIRCUITS, payload: {
                          show: true,
                          title: `${translate(TranslateConfig.CIRCUITS)}: ${allCircuits?.length}`,
                          data: allCircuits,
                        },
                      });
                    }
                  }}
                />

              </Shimmer>

            </HighlightsSeparator>


            <ChangeEventTypeButton
              onClick={() =>
                history.replace(NameRoutes.GridEventsScreen)
              }>
              <ChangeEventTypeImage
                alt={'change'}
                src={Images.noitadaHomeImageButton}
              />
            </ChangeEventTypeButton>

          </HighlightMenuSeparator>
        }
        noSubToolbarPadding>


        {!loading
          ? (
            <CalendarHorizontal
              color={Colors.accent}
              textColor={Colors.white}
              backgroundColor={[
                Colors.transparent,
                Colors.calendarColor,
              ]}
              selectedDate={selectedDate}
              startDate={startDate}
              endDate={endDate}
              onSelectDate={(dateFromCalendar: Date) => {
                setSelectedDate(dateFromCalendar);
              }}
            />
          )
          : <></>
        }

      </HeaderScreen>



      <AttractionsCarnivalFlatList
        loading={loading}
        data={events.filter((dataFilter) => {
          const date = FormatIsoDateWithPatternDayjs(selectedDate, 'DD/MM/YYYY');
          return dataFilter?.date === date;
        })}
        renderItem={renderItem}
        listLoadingComponent={renderLoading()}
        listFooterComponent={renderFooter()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Container>

  );
};



export default TabAttractionsScreen;
