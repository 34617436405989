import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  LinearGradient,
} from '../../../components/Composh/web';



export const OrderSheetHeaderButton = styled(LinearGradient)`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 0;
`;


export const PlaceSheetView = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 25px;
  width: 100%;
  max-width: 500px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const SheetPriceView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SheetTypePriceText = styled.p`
  font-size: 24px;
  color: ${Colors.white};
`;

export const SheetHourText = styled.p`
  margin-left: 10px;
  color: ${Colors.white};
  font-size: 44px;
  font-weight: bold;
  line-height: 46px;
`;


export const WalletShowValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

export const WalletShowValueButton = styled.a`
  display: flex;
  flex-direction: column;
  width: 40px;
  height: 40px;
  margin-right: 12px;
`;


export const WalletActionHeaderApp = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: -10px;
`;



export const WalletActionConfigView = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${50}px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  background-color: ${'#272A32'};
`;

export const WalletActionConfigText = styled.p`
  margin-left: 15px;
  color: ${Colors.white};
  font-size: 16px;
`;



export const WalletOptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  padding-top: 10px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
`;

export const WalletOptionsContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const WalletOptionsItems = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
`;

export const WalletOptionsButton = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: ${50}px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 10px;
  background-color: ${Colors.cardBackground};
  border-radius: ${Sizes.cardRadius}px;
`;

export const WalletOptionsIcon = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${Sizes.iconAndroidSize}px;
  margin-right: 14px;
`;

export const WalletOptionsText = styled.p`
  color: ${Colors.white};
  font-size: 15px;
`;



export const WalletFooterOptionsText = styled.p`
  color: ${Colors.gray};
  font-size: 12px;
  text-align: center;
`;
