import styled from 'styled-components';

import {
  Colors,
  Sizes,
} from '@noitada/shared/constants';

import {
  Container,
  Content,
} from '../../../../components/Composh/web';



export const SheetContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
  background-color: ${Colors.alertBackground};
  border-top-left-radius: ${Sizes.cardRadius}px;
  border-top-right-radius: ${Sizes.cardRadius}px;
`;


export const SheetStatusViewBadge = styled.div`
  display: flex;
  flex-direction: column;
  width: 100px;
  margin-right: 8px;
  padding-top: 2px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 2px;
  border-radius: ${Sizes.cardRadius}px;
  border-color: ${Colors.white};
  border-width: 0.5px;
`;

export const SheetStatusTextBadge = styled.p`
  color: ${Colors.white};
  font-size: 13px;
  line-height: 16px;
  text-align: center;
`;


export const SheetHistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: 16px;
  padding-right: 16px;
  padding-bottom: 8px;
  background-color: ${Colors.appBackground};
`;


export const SheetHistoryCheckinView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SheetCheckInView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-top: 7px;
  margin-bottom: 7px;
`;

export const SheetPriceInfoView = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;


export const CheckPriceView = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const SheetHourText = styled.p`
  color: ${Colors.buttonOkLight};
  font-size: 32px;
  font-weight: bold;
  line-height: 36px;
`;

export const SheetTitle = styled.p`
  margin-top: 5px;
  color: ${Colors.white};
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`;

export const SheetSubtitle = styled.p`
  margin-top: 3px;
  color: ${Colors.gray};
  font-size: 13px;
  line-height: 17px;
`;


export const SheetPriceView = styled.div`
  display: flex;
  flex-direction: row;
`;

export const SheetTypePriceText = styled.p`
  margin-right: 9px;
  color: ${Colors.buttonOkLight};
  font-size: 17px;
`;



export const TimelineStyled = styled(Content)`
  display: flex;
  flex-direction: column;
  padding-top: 8px;
  padding-left: 14px;
  padding-right: 14px;
  padding-bottom: 8px;
`;
