import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Screen,
  Title,
} from '../../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../../components/Controls';

import HeaderScreen from '../../../../components/Headers/HeaderScreen';

import TabBarStyled from '../../../../components/TabBar';

import TabOrdersFinished from './Tabs/TabFinished';
import TabOrdersOpened from './Tabs/TabOpened';



const UserOrdersScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const [stateIndex, setStateIndex] = useState(0);


  const screenTabs = [
    {
      name: 'OrdersUpcomming',
      title: translate(TranslateConfig.OPENEDS),
      component: <TabOrdersOpened />,
    },
    {
      name: 'OrdersPast',
      title: translate(TranslateConfig.CLOSEDS),
      component: <TabOrdersFinished />,
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.REQUESTS });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <Title color={Colors.white}>
            {translate(TranslateConfig.MY_ORDERS)}
          </Title>
        }
        rightIcon={
          <ButtonMore
            color={Colors.white}
          />
        }
        noSubToolbarPadding>


        <TabBarStyled
          tabs={screenTabs}
          selected={stateIndex}
          onPress={(tab) => {
            setStateIndex(tab?.index);
          }}
        />

      </HeaderScreen>



      {stateIndex === 0 && screenTabs[0].component}

      {stateIndex === 1 && screenTabs[1].component}

    </Screen>

  );
};



export default UserOrdersScreen;
