import styled from 'styled-components';

import {
  ListFlat,
} from '../../../../../components/Composh/web';



export const ListTicketContentFlex = styled(ListFlat).attrs({
  contentContainerStyle: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 10,
    paddingLeft: 15,
    paddingRight: 15,
    paddingBottom: 10,
  },
})`
  display: flex;
  flex-direction: column;
  flex: 1;
`;
