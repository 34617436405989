import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  EntranceListLeave,
  EntranceListParticipate,
} from '@noitada/axios-config/endpoints/entrance-list.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonAddLine from '../../../../../components/Buttons/ButtonAddLine';

import {
  Container,
  Overlay,
  Title,
} from '../../../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../../../components/Controls';

import FooterChoose from '../../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../../../navigation/names';

import {
  ButtonText,
} from '../../../../../styles/styled.layout';

import {
  EventListContainer,
  EventListInfoView,
  EventListInfoImage,
  EventListTitle,
  EventListFooter,
} from './styled';

const width = 670;
const height = 650;



export interface IProps {
  visible?: boolean;
  list: any;
  onCloseAndRecall?: any;
  inList?: boolean;
  canInvite?: boolean;
  idUser: string;
  onCancelPress?: any;
  editable?: boolean;
  guestsInvited?: number;
  limitInvites?: number;
}



const ListParticipateScreen: React.FC<IProps> = (props: IProps) => {
  if (!props.visible) {
    return null;
  }

  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const widthLine = width - 320;
  const heightLine = height - 90;

  const { editable } = props;
  const visibleModal = props.visible;
  const userId = props?.idUser;
  const list = props?.list;
  const userInList = props.inList;
  const userCanInvite = props.canInvite;

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  function goBack() {
    if (props.onCancelPress) {
      props.onCancelPress();
    }
  };


  async function confirmParticipate() {
    try {
      setLoading(true);
      let data: any = {};

      if (userInList) {
        data = await EntranceListLeave(list?.id, userId);

        if (data) {
          props?.onCloseAndRecall();
        }
        else {
          showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_SAVE));
        }
      }
      else {
        data = await EntranceListParticipate(list?.shortId, userId);

        if (data) {
          props?.onCloseAndRecall();
        }
        else {
          showSnackBarProps(Colors.danger, translate(TranslateConfig.ERROR_LIST_ENTER));
        }
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    };
  }


  async function newAddPersonPress() {
    history.push({
      pathname: NameRoutes.ListInviteScreen.replace(
        ':list_id',
        list?.id,
      ),
      state: { list },
    });
  }



  return (

    <Overlay
      visible={visibleModal}
      showBackground={true}
      overlayColor={Colors.menuOverlay}
      onOverlayPress={props.onCancelPress}>

      <EventListContainer
        width={widthLine}
        height={heightLine}>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
              onPress={() => {
                goBack();
              }}
            />
          }
          centerContent={
            <Title>
              {translate(TranslateConfig.PARTICIPATE)}: {list?.name}
            </Title>
          }
        />



        <Container>

          {loading && (
            <LoadingScreen
              type={'SCREEN'}
              color={Colors.primary}
            />
          )}



          {!loading && (
            <EventListInfoView>

              <EventListInfoImage
                alt={'Empty Image'}
                src={Images.noHighlight}
              />


              <EventListTitle>
                {userInList
                  ? translate(TranslateConfig.SUBTITLES_LIST_INSIDE).replace('{listName}', list?.name)
                  : translate(TranslateConfig.SUBTITLES_LIST_OUTSIDE).replace('{listName}', list?.name)
                }
              </EventListTitle>


              {userInList && userCanInvite && editable && (
                <EventListFooter>
                  <ButtonAddLine
                    title={translate(TranslateConfig.INVITE_PEOPLE)}
                    onPress={() => {
                      newAddPersonPress();
                    }}
                  />
                </EventListFooter>
              )}

            </EventListInfoView>
          )}

        </Container>



        {editable &&
          <FooterChoose
            cancelDisabled={loading}
            cancelColor={loading
              ? ArrayColors.arrayDisabled
              : ArrayColors.arrayCancel
            }
            cancelLabel={
              <ButtonText
                color={Colors.white}>
                Fechar
              </ButtonText>
            }
            cancelPress={() => {
              goBack();
            }}

            okColor={loading
              ? ArrayColors.arrayDisabled
              : userInList
                ? ArrayColors.arrayOk
                : ArrayColors.arrayAction
            }
            okLabel={
              <ButtonText
                color={Colors.white}>
                {loading
                  ? translate(TranslateConfig.LOADING)
                  : userInList
                    ? translate(TranslateConfig.ACTION_LIST_LEAVE)
                    : translate(TranslateConfig.ACTION_LIST_PARTICIPATE)
                }
              </ButtonText>
            }
            okPress={() => {
              confirmParticipate();
            }}
          />
        }
        {!editable &&
          <FooterChoose
            hideCancelButton

            okColor={ArrayColors.arrayCancel}
            okLabel={
              <ButtonText
                color={Colors.white}>
                {translate(TranslateConfig.ACTION_CLOSE)}
              </ButtonText>
            }
            okPress={() => {
              goBack();
            }}
          />
        }

      </EventListContainer>

    </Overlay>

  );
};



export default ListParticipateScreen;
