import React,
{
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  EntranceListInvite,
  CheckUserInList,
} from '@noitada/axios-config/endpoints/entrance-list.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_MODAL_LOADING_ACTION,
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  ArrayColors,
} from '@noitada/shared/arrays';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EUserStatus,
} from '@noitada/shared/enums';

import Vars from '@noitada/shared/environments/variables';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import ButtonSeeMore from '../../../../../components/Buttons/ButtonSeeMore';
import CardUserFound from '../../../../../components/Cards/CardUserFound';

import {
  Mask,
} from '../../../../../components/Composh/plugins';

import {
  Body,
  Container,
  Content,
  Epigraph,
  Screen,
  SubTitle,
  Title,
} from '../../../../../components/Composh/web';

import CopiedItem from '../../../../../components/Contents/CopiedItem';
import ShareSocial from '../../../../../components/Contents/ShareSocial';
import TimeContent from '../../../../../components/Contents/TimeContent';

import {
  ButtonBack,
  ButtonDelete,
} from '../../../../../components/Controls';

import FooterChoose from '../../../../../components/Footers/FooterChoose';
import HeaderScreen from '../../../../../components/Headers/HeaderScreen';

import {
  IconCopy,
} from '../../../../../components/Icons';

import AddNewPersonModal from '../../../../../modals/AddNewPersonModal';

import NameRoutes from '../../../../../navigation/names';

import {
  ButtonText,
  ContentPadderHorizontal,
  DetailsSectionView,
} from '../../../../../styles/styled.layout';
import {
  InviteLinkContainer,
  InviteText,
  InviteShareContainer,
  InviteShareLinkContent,
  InviteShareContentLinkText,
  InvitePersonAlertText,
  InvitePersonsView,
} from './styled';



const ListInviteScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory() as any; // FIXME
  const { t: translate } = useTranslation();


  // const user = useSelector((state: RootState) => state.user.actualUser);
  const event = useSelector((state: RootState) => state.event.selected);
  const list = history.location?.state?.list;


  // const userId = user.id;
  const userListId = list?.id;

  const linkList = `${Vars().noitadaAppLink}${NameRoutes.ListInviteScreen.replace(
    ':list_id',
    event?.idShort,
  )}`;


  const [people, setPeople] = useState<Array<any>>([...list?.guests]);

  const [copied, setCopied] = useState<boolean>(false);
  const [showAddPersonModal, setShowAddPersonModal] = useState<boolean>(false);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const handleRemoveItem = (index: number) => {
    const newItems = [...people];
    newItems.splice(index, 1);
    setPeople(newItems);
  };


  function cancelButtonPress() {
    history.goBack();
  }


  async function confirmRevision() {
    try {
      setLoading(true);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: true });

      const response = await EntranceListInvite(userListId, people.map((person) => (person.id)));

      if (response) {
        showSnackBarProps(Colors.accept, translate(TranslateConfig.SUCCESS_LIST_USER_ADDED));
        cancelButtonPress();
      }
      else {
        console.error('Error', response);
        showSnackBarProps(Colors.danger, response.message || translate(TranslateConfig.ERROR_LIST_USER_ADDED));
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
      dispatch({ type: APP_MODAL_LOADING_ACTION, payload: false });
    }
  }


  function copyLinkList() {
    setCopied(true);
    navigator.clipboard.writeText(linkList);

    setTimeout(() => {
      setCopied(false);
    }, 1000);
  }



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>
        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonBack
              close
              color={Colors.white}
            />
          }
          centerContent={
            <Body>
              <Title>
                {translate(TranslateConfig.INVITE_TO_LIST)}
              </Title>

              <SubTitle>
                {event?.name}
              </SubTitle>
            </Body>
          }
        />



        <Content>
          {list?.additionalGuestsLink &&
            <InviteLinkContainer>

              <TimeContent
                title={translate(TranslateConfig.INVITE_PEOPLE)}
                description={translate(TranslateConfig.HELP_INVITE_PEOPLE)}
              />



              <DetailsSectionView>
                <Epigraph.Section
                  text={translate(TranslateConfig.ACTION_INVITE_LINK)}
                  textColor={Colors.primary}
                  borderBottomColor={Colors.primary}
                />


                <InviteText>
                  {translate(TranslateConfig.HELP_INVITE_LINK)}
                </InviteText>


                <InviteShareContainer>
                  <InviteShareLinkContent
                    onClick={() => {
                      copyLinkList();
                    }}>


                    <InviteShareContentLinkText>
                      {linkList}
                    </InviteShareContentLinkText>


                    <CopiedItem
                      copied={copied}
                      icon={
                        <IconCopy
                          color={Colors.accent}
                          size={25}
                        />
                      }
                    />

                  </InviteShareLinkContent>

                  <ShareSocial
                    whatsAppPress={() => {
                      // TODO
                    }}
                  />

                </InviteShareContainer>


              </DetailsSectionView>

            </InviteLinkContainer>
          }

          <DetailsSectionView>

            <ContentPadderHorizontal>
              <Epigraph.Section
                text={translate(TranslateConfig.ACTION_ADD_MANUAL)}
                textColor={Colors.primary}
                borderBottomColor={Colors.primary}
              />


              <InviteText>
                {translate(TranslateConfig.HELP_LIST_HOW_INVITE)}
              </InviteText>


              <InviteText>
                {translate(TranslateConfig.HELP_LIST_HOW_FINISH)}
              </InviteText>


              <InvitePersonAlertText>
                {list?.additionalGuestsNumber <= people.length
                  ? translate(TranslateConfig.HELP_LIST_HOW_FINISH).replace('{listAdditionalNumber}', list?.additionalGuestsNumber)
                  : translate(TranslateConfig.INTERPOLATE_LIST_UNTIL_LIMIT).replace('{listAdditionalNumber}', list?.additionalGuestsNumber).toUpperCase()
                }
              </InvitePersonAlertText>



              <InvitePersonsView>
                {people?.map((item: any, index: number) => {
                  return (

                    <CardUserFound
                      key={index}
                      image={item?.user_status === EUserStatus.REGISTERED
                        ? `${item?.picture}`
                        : ''
                      }
                      name={item?.user_status === EUserStatus.REGISTERED
                        ? `@${item?.publicName}`
                        : item?.status === EUserStatus.PRE_REGISTERED
                          ? `${translate(TranslateConfig.CPF)}: ${Mask.toMask('cpf', item?.cpf)}`
                          : translate(TranslateConfig.EMPTY_USER_DATA)
                      }
                      username={item?.user_status === EUserStatus.REGISTERED
                        ? `@${item?.username}`
                        : item?.status === EUserStatus.PRE_REGISTERED
                          ? translate(TranslateConfig.USER_PRE_REGISTER)
                          : translate(TranslateConfig.EMPTY_USER_NO_RECOG)
                      }
                      birthday={item?.birthday}
                      verified={item?.user_status === EUserStatus.REGISTERED
                        ? item?.people?.verified
                        : false
                      }
                      level={item?.people?.level}
                      deleteIcon={
                        <ButtonDelete
                          color={Colors.danger}
                          onPress={() => {
                            handleRemoveItem(index);
                          }}
                        />
                      }
                    />

                  );
                })}
              </InvitePersonsView>

            </ContentPadderHorizontal>



            {list?.additionalGuestsNumber > people.length &&
              <ButtonSeeMore
                // disabled={!availableUser || people.length >= selectedReservation?.places[selectedPlaceIdx].limit}
                title={translate(TranslateConfig.ACTION_ADD_PEOPLE)}
                onPress={() => {
                  setShowAddPersonModal(true);
                }}
              />
            }

          </DetailsSectionView>

        </Content>


        {list?.additionalGuestsNumber >= people.length &&
          <FooterChoose
            cancelDisabled={loading}
            cancelColor={loading
              ? ArrayColors.arrayDisabled
              : ArrayColors.arrayCancel
            }
            cancelLabel={
              <ButtonText
                color={Colors.white}>
                Voltar
              </ButtonText>
            }
            cancelPress={() => {
              cancelButtonPress();
            }}

            okDisabled={loading}
            okColor={loading
              ? ArrayColors.arrayDisabled
              : ArrayColors.arrayOk
            }
            okLabel={
              <ButtonText
                color={Colors.white}>
                {loading
                  ? translate(TranslateConfig.LOADING)
                  : translate(TranslateConfig.ACTION_SAVE)
                }
              </ButtonText>
            }
            okPress={() => {
              confirmRevision();
            }}
          />
        }
        {list?.additionalGuestsNumber < people.length &&
          <FooterChoose
            hideCancelButton

            okColor={ArrayColors.arrayCancel}
            okLabel={
              <ButtonText
                color={Colors.white}>
                Voltar
              </ButtonText>
            }
            okPress={() => {
              cancelButtonPress();
            }}
          />
        }

      </Container>



      {showAddPersonModal && (
        <AddNewPersonModal
          visible={showAddPersonModal}
          verification={(userId: string) => CheckUserInList(userId, userListId)}
          onCancelPress={() => {
            setShowAddPersonModal(false);
          }}
          canAddGhost={false}
          onPress={(person: any) => {
            setPeople([
              ...people,
              person,
            ]);
          }}
        />
      )}

    </Screen>

  );
};



export default ListInviteScreen;
