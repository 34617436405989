import React,
{
  useState,
  useEffect,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
  useParams,
} from 'react-router-dom';

import {
  GetCompanyWaitLists,
} from '@noitada/axios-config/endpoints/wait-lists-company.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Images,
} from '@noitada/shared/constants';

import Colors from '@noitada/shared/constants/Colors';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatIsoDateWithPatternDayjs,
} from '@noitada/shared/utils/date.utils';

import {
  treatErrorAndReturnColor,
  treatErrorAndReturnMessage,
} from '@noitada/shared/utils/errors.utils';

import CardWaitListsAll from '../../../components/Cards/CardWaitLists/CardWaitListsAll';

import {
  Body,
  Screen,
  SubTitle,
  Title,
} from '../../../components/Composh/web';

import {
  ButtonBack,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../navigation/names';

import {
  FlatListListWaitList,
} from './styled';



const CompanyWaitListsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();

  const {
    company_username,
  } = useParams() as any;


  const [waitListArray, setWaitListArray] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getReservesFunction = async () => {
    setLoading(true);

    try {
      const response = await GetCompanyWaitLists(company_username);
      const responseData = response?.data;

      if (!responseData || responseData?.length <= 0) {
        console.error(response);
        setWaitListArray([]);
        return;
      }

      setWaitListArray(responseData);
    }
    catch (error: any) {
      console.error(error);
      setWaitListArray([]);

      const getMessageError = treatErrorAndReturnMessage(error);
      const messageError = translate(getMessageError);
      const colorError = treatErrorAndReturnColor(error);
      showSnackBarProps(colorError, messageError);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardWaitListsAll
        key={index}
        date={FormatIsoDateWithPatternDayjs(item?.forDate, 'DD/MM/YYYY')}
        description={item?.description}
        peopleInList={item?.peopleInList}
        openList={item?.startHour}
        closeList={item?.endHour}
        onPress={() => {
          history.push(NameRoutes.WaitListsUserScreen.replace(
            ':id_wait_list',
            item?.idShort,
          ));
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noReserveUpcoming}
        title={translate(TranslateConfig.EMPTY_WAIT_LIST_AVAILABLE)}
      />

    );
  }



  useEffect(() => {
    getReservesFunction();
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonBack
            color={Colors.white}
          />
        }
        centerContent={
          <Body>
            <Title
              color={Colors.white}>
              {translate(TranslateConfig.WAIT_LISTS)}
            </Title>

            <SubTitle
              color={Colors.gray}>
              {company_username ? `@${company_username}` : '-'}
            </SubTitle>
          </Body>
        }
      />



      <FlatListListWaitList
        loading={loading}
        data={waitListArray}
        renderItem={renderItem}
        listLoadingComponent={renderLoading()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Screen>

  );
};



export default CompanyWaitListsScreen;
