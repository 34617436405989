import NoitadaWS from '../index';



export const GetCompanyWaitLists = async (idShort: string) => {
  const url = `/app/wait-lists/company/${idShort}`;

  const response = await NoitadaWS.get(url);
  return response.data;
};
