import {
  Keys,
} from '@noitada/shared/constants';



export function openSite(siteToOpen: string | null | undefined) {
  if (!siteToOpen) {
    return;
  }

  window.open(siteToOpen, '_blank')?.focus();
};



export function openAppWeb(urlForBrowser: string) {
  window.open(urlForBrowser, '_blank')?.focus();
}



export function openPhone(phone: string) {
  window.open(`tel:${phone}`);

  // if (Platform.OS === 'android') {
  //   Communications.phonecall(phone, true);
  // }
  // else if (Platform.OS === 'ios') {
  //   Linking.openURL(`tel:${phone}`);
  // }
};



export function openStore(androidPackage: string, iosID: string) {
  // if (Platform.OS === 'android') {
  //   Linking.openURL(`market://details?id=${androidPackage}`);
  // }
  // else if (Platform.OS === 'ios') {
  //   Linking.openURL(`itms-apps://itunes.apple.com/us/app/id${iosID}?mt=8`);
  // }
};



export function openUber(
  name: string | null,
  latitude: number | null,
  longitude: number | null,
  street: string | null,
  number: string | null,
  district: string | null,
) {
  // Url For Browser
  const windowOpen: any = window.open(
    `https://m.uber.com/ul/?client_id=${Keys.UberKey}&action=setPickup&pickup=my_location&dropoff[latitude]=${latitude}&dropoff[longitude]=${longitude}&dropoff[nickname]=${name}&dropoff[formatted_address]=${street}, ${number} - ${district}`,
    '_blank',
  );
  if (windowOpen) {
    windowOpen.focus();
  };
};



export function openWaze(
  latitude: number | null,
  longitude: number | null,
) {
  const windowOpen: any = window.open(
    `https://www.waze.com/ul?ll=${latitude}%2C${longitude}&navigate=yes&zoom=17`,
    '_blank',
  );
  if (windowOpen) {
    windowOpen.focus();
  };
};



export function openCompanyWhatsApp(fullNumber: string) {
  const messageCompany = 'Ol%C3%A1%2C%20vi%20esse%20perfil%20na%20Noitada.%20Gostaria%20de%20ajuda%20pelo%20WhatsApp.';
  const linkWhatsAppCompany = `https://api.whatsapp.com/send?phone=${fullNumber}&text=${messageCompany}`;

  openSite(linkWhatsAppCompany);
};



export function manageCompanyWhatsApp(idShort: string, name: string) {
  let messageCompany = '';

  if (idShort && name) {
    const encodedMessage = `Olá, vi o perfil ${name} de ID ${idShort} na Noitada. Gostaria de ser o gerenciador desse perfil.`;
    messageCompany = encodeURIComponent(encodedMessage);
  }
  else {
    messageCompany = encodeURIComponent(Keys.whatsAppMessage);
  }

  const linkWhatsAppCompany = `https://api.whatsapp.com/send?phone=${Keys.whatsAppNumber}&text=${messageCompany}`;

  openSite(linkWhatsAppCompany);
};



export function openHelpWhatsApp(message?: string) {
  const encodedMessage = message || Keys.whatsAppMessage;
  const linkWhatsAppContact = `https://api.whatsapp.com/send?phone=${Keys.whatsAppNumber}&text=${encodeURIComponent(encodedMessage)}`;

  openSite(linkWhatsAppContact);
};



export function openHelpOrderWhatsApp() {
  openHelpWhatsApp('Olá, gostaria de ajuda com um pedido feito na Noitada');
};



export function openEmail(subject: string, message: string) {
  const emailSubject = subject;
  const emailBody = encodeURIComponent(message);
  const mailtoLink = `mailto:?subject=${emailSubject}&body=${emailBody}`;
  window.location.href = mailtoLink;
};
