import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  FormatMoney,
} from '../../../config/mask.config';

import {
  InstallmentItemContainer,
  InstallmentItemView,
  InstallmentItemTitle,
  InstallmentItemSubtitle,
  InstallmentItemClickText,
  InstallmentPriceView,
  InstallmentItemPrice,
  InstallmentItemMonth,
} from './styled';



export interface IProps {
  parcelNumber: number;      // Título principal
  fee: number;   // Subtítulo
  price: string | number; // Valor que será exibido ao lado
  onPress?: () => void; // Função opcional para clique
}



const InstallmentItem: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderParcelText() {
    if (props.parcelNumber === 1) {
      return translate(TranslateConfig.AT_SIGHT);
    }

    if (props.parcelNumber > 1) {
      return `${props.parcelNumber} ${translate(TranslateConfig.INSTALLMENT_TIME)}`;
    }

    return '-';
  }


  function renderFeeText() {
    if (props.fee > 1) {
      return `${translate(TranslateConfig.FEE_PROCESSING)}: ${props.fee}%`;
    }

    return '-';
  }



  return (

    <InstallmentItemContainer
      onClick={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <InstallmentItemView>

        <InstallmentItemTitle>
          {renderParcelText()}
        </InstallmentItemTitle>


        <InstallmentItemSubtitle>
          {renderFeeText()}
        </InstallmentItemSubtitle>


        <InstallmentItemClickText>
          {translate(TranslateConfig.ACTION_CLICK_SELECT)}
        </InstallmentItemClickText>

      </InstallmentItemView>


      <InstallmentPriceView>

        <InstallmentItemPrice>
          {FormatMoney(props.price)}
        </InstallmentItemPrice>


        <InstallmentItemMonth>
          {translate(TranslateConfig.PER_MONTH)}
        </InstallmentItemMonth>

      </InstallmentPriceView>

    </InstallmentItemContainer>

  );
};



export default InstallmentItem;
