import React from 'react';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  BannerContainer,
  EventPicContentView,
  BannerImageContainer,
  BannerBackground,
  BannerImageOverlay,
  BannerImage,
} from './styled';



export interface IProps {
  image?: string;
  onPress?: any;
}



const PictureEventActived: React.FC<IProps> = (props: IProps) => {
  const eventImage = validateString(props.image)
    ? props.image
    : Images.placeholderEvent;



  return (

    <BannerContainer
      backgroundColor={Colors.primaryDark}>

      <EventPicContentView>

        <BannerImageContainer>

          <BannerBackground
            alt={'picture'}
            src={eventImage}
          />


          <BannerImageOverlay />


          <BannerImage
            src={eventImage}
          />

        </BannerImageContainer>

      </EventPicContentView>

    </BannerContainer>

  );
};



export default PictureEventActived;
