import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  APP_MENU_SELECTED_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
} from '@noitada/shared/constants';

import {
  EDrawerMenu,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  Title,
  Screen,
  Container,
} from '../../../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
} from '../../../../../components/Controls';

import HeaderScreen from '../../../../../components/Headers/HeaderScreen';

import TabBarStyled from '../../../../../components/TabBar';

import TicketDetails from '../Modals';

import TabTicketsPast from './Tabs/TabPast';
import TabTicketsUpcomming from './Tabs/TabUpcomming';



const UserTicketsScreen: React.FC = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();


  const [stateIndex, setStateIndex] = useState(0);

  const [ticketModal, setTicketModal] = useState<boolean>(false);


  const screenTabs = [
    {
      name: 'TicketsUpcomming',
      title: translate(TranslateConfig.NEXT),
      component: <TabTicketsUpcomming />,
    },
    {
      name: 'TicketsPast',
      title: translate(TranslateConfig.PAST),
      component: <TabTicketsPast />,
    },
  ];



  useEffect(() => {
    dispatch({ type: APP_MENU_SELECTED_ACTION, payload: EDrawerMenu.TICKETS });
  }, []);



  return (

    <Screen
      backgroundColor={Colors.appBackground}>

      <Container>

        <HeaderScreen
          backgroundColor={Colors.toolbarBackground}
          subBackgroundColor={Colors.toolbarBackground}
          leftIcon={
            <ButtonDrawer
              color={Colors.white}
            />
          }
          centerContent={
            <Title color={Colors.white}>
              {translate(TranslateConfig.TICKETS_MY)}
            </Title>
          }
          rightIcon={
            <ButtonMore
              color={Colors.white}
            />
          }
          noSubToolbarPadding>


          <TabBarStyled
            tabs={screenTabs}
            selected={stateIndex}
            onPress={(tab) => {
              setStateIndex(tab?.index);
            }}
          />

        </HeaderScreen>



        {stateIndex === 0 && screenTabs[0].component}

        {stateIndex === 1 && screenTabs[1].component}

      </Container>



      <TicketDetails
        visible={false}
        address={null}
        onCancelPress={() => {
          setTicketModal(false);
        }}
      />

    </Screen>

  );
};



export default UserTicketsScreen;
