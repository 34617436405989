import styled from 'styled-components';

import {
  Colors,
} from '@noitada/shared/constants';



export interface IProps {
  backgroundColor?: string;
}



export const BagPlaceAddItemsButton = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 45px;
  min-height: 45px;
  background-color: ${(props: IProps) => props.backgroundColor || Colors.calendarColorLight};
`;

export const BagPlaceAddItemsText = styled.p`
  color: ${Colors.accent};
  font-size: 16px;
`;
