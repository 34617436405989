import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetAllCompanies,
} from '@noitada/axios-config/endpoints/company.endpoints';

import {
  RootState,
} from '@noitada/redux-config';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  companyTypeArray,
} from '@noitada/shared/arrays/company-type.array';

import {
  AllItems,
} from '@noitada/shared/arrays/general.array';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  ECompanyType,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CompanyOpenHourText,
} from '@noitada/shared/utils/company.utils';

import ButtonCityUser from '../../../components/Buttons/ButtonCitiesApp/CityUser';
import CardProfile from '../../../components/Cards/CardCompany/CardProfile';

import {
  ToolbarGroup,
  Container,
  Toggle,
} from '../../../components/Composh/web';

import {
  ButtonDrawer,
  ButtonMore,
  ButtonNotifications,
  ButtonSearch,
} from '../../../components/Controls';

import EmptyContent from '../../../components/Empty';
import HeaderScreen from '../../../components/Headers/HeaderScreen';

import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import {
  returnIconCompanyType,
} from '../../../config/icon-types-company.config';

import NameRoutes from '../../../navigation/names';

import {
  ProfileScrollCardsContent,
  ProfileScrollItemContent,
} from './styled';



const TabProfilesScreen: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const localities = useSelector((state: RootState) => state.localities.selected);


  const typeCompanyWithoutAll = [
    AllItems,
    ...companyTypeArray,
  ];

  const [loading, setLoading] = useState<boolean>(false);

  const [selectedType, setSelectedType] = useState('ALL');
  const [companiesList, setCompaniesList] = useState<Array<any>>([]);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION, payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getProfiles(values: string) {
    const payload = {
      ...localities,
      type: values,
    };

    try {
      setLoading(true);

      const data: Array<any> = await GetAllCompanies(payload);
      const treatedData = data?.filter((item: any) => {
        const allowedTypes = [ECompanyType.CARNIVAL_PRODUCER, ECompanyType.PARTY_PRODUCER, ECompanyType.GOVERN];

        const eventCardProducer = allowedTypes.includes(item?.type) && item?.modules?.events;
        if (values !== ECompanyType.EVENT_PRODUCER || (values === ECompanyType.EVENT_PRODUCER && eventCardProducer)) {
          return true;
        }
        return false;
      });


      if (treatedData) {
        setCompaniesList(treatedData);
      }
      else {
        setCompaniesList([]);
      }
    }
    catch (error: any) {
      console.error(error);
      setCompaniesList([]);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  }


  async function resetValues() {
    setSelectedType(AllItems.value);
    await getProfiles(AllItems.value);
  }


  function renderToggleTypeCompany(item: any, index: any) {
    return (

      <Toggle
        key={index}
        noToggle
        disabled={loading}
        selected={selectedType === item?.value}
        iconContent={returnIconCompanyType(item?.value, Colors.white, 24)}
        displayValue={translate(item?.label)}
        backgroundColor={Colors.cardBackground}
        backgroundTintColor={Colors.primary}
        borderRadius={50}
        textColor={Colors.white}
        textTintColor={Colors.white}
        onPress={() => {
          setSelectedType(item?.value);
          getProfiles(item?.value);
        }}
      />

    );
  }


  function renderItem(item: any, index: any) {
    return (

      <CardProfile
        key={index}
        companyId={item?.id}
        image={item?.picture}
        name={item?.name}
        username={item?.company_username}
        verified={item?.verified}
        type={item?.type}
        district={item?.address?.district}
        open={CompanyOpenHourText(item) || translate(TranslateConfig.CLOSED)}
        // rating={item?.rating}
        params={item?.company_params}

        onPress={() => {
          // dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.DetailsCompanyScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        menuDisable={item?.modules?.menuSheet}
        menuPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.MenuSheetScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        eventDisable={item?.modules?.events}
        eventPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.AllCompanyEventsScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        reserveDisable={item?.modules?.reserves}
        reservePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.CompanyReservesScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        waitListsDisable={item?.modules?.waitLists}
        waitListsPress={() => {
          history.push({
            pathname: NameRoutes.CompanyWaitListsScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        favoritePress={() => {
          resetValues();
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noBar}
        title={translate(TranslateConfig.EMPTY_HIGHLIGHTS_PARTNERS)}
      />

    );
  }



  useEffect(() => {
    resetValues();
  }, [localities]);



  return (

    <Container>

      <HeaderScreen
        backgroundColor={Colors.toolbarBackground}
        subBackgroundColor={Colors.toolbarBackground}
        leftIcon={
          <ButtonDrawer
            color={Colors.white}
          />
        }
        centerContent={
          <ButtonCityUser />
        }
        rightIcon={
          <ToolbarGroup>

            <ButtonSearch
              color={Colors.white}
            />


            <ButtonNotifications
              color={Colors.white}
            />


            <ButtonMore
              color={Colors.white}
            />

          </ToolbarGroup>
        }
        noSubToolbarPadding>


        <ProfileScrollItemContent>
          {typeCompanyWithoutAll.map((item: any, index: any) => (
            renderToggleTypeCompany(item, index)
          ))}
        </ProfileScrollItemContent>

      </HeaderScreen>



      <ProfileScrollCardsContent
        loading={loading}
        data={companiesList}
        renderItem={renderItem}
        // listHeaderComponent={listHeaderComponent()}
        listLoadingComponent={renderLoading()}
        listEmptyComponent={listEmptyComponent()}
      />

    </Container>

  );
};


export default TabProfilesScreen;
