import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetOrderUser,
} from '@noitada/axios-config/endpoints/order-sheet.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import EOrderUser from '@noitada/shared/enums/Orders/order-user.enum';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import CardHistoryPastOrder from '../../../../../components/Cards/CardOrders/CardHistoryPastOrder';

import EmptyContent from '../../../../../components/Empty';
import LoadingScreen from '../../../../../components/Loadings/LoadingScreen';

import {
  FormatMoney,
} from '../../../../../config/mask.config';

import NameRoutes from '../../../../../navigation/names';

import {
  OrderListFlat,
} from './styled';



const TabOrdersFinished: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const [ordersResponse, setOrdersResponse] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  async function getHistoryData() {
    try {
      setLoading(true);
      const response = await GetOrderUser(EOrderUser.CLOSED);

      if (response) {
        setOrdersResponse(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    const week = new Intl.DateTimeFormat('pt-BR', { weekday: 'short', timeZone: 'America/Sao_Paulo' }).format(new Date(item?.completedAt)).slice(0, -1);
    const Capitalize_week = week[0].toUpperCase() + week.slice(1);
    const full_day = new Intl.DateTimeFormat('pt-BR', { dateStyle: 'long', timeZone: 'America/Sao_Paulo' }).format(new Date(item?.completedAt));


    return (

      <CardHistoryPastOrder
        key={index}
        date={`${Capitalize_week}, ${full_day}`}
        name={item?.company?.name}
        id={`#${item?.id}`}
        image={item?.company?.picture}
        price={FormatMoney(item?.totalConsumed) || '-'}
        onPress={() => {
          history.push(NameRoutes.UserDetailsOrderScreen.replace(
            ':order_id',
            item?.id,
          ));
        }}
      />

    );
  };


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.ticketsEmpty}
        title={translate(TranslateConfig.EMPTY_TICKET)}
      />

    );
  }



  useEffect(() => {
    getHistoryData();
  }, []);



  return (

    <OrderListFlat
      loading={loading}
      data={ordersResponse}
      renderItem={renderItem}
      listLoadingComponent={renderLoading()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabOrdersFinished;
