import React,
{
  useEffect,
  useState,
} from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  useDispatch,
} from 'react-redux';

import {
  useHistory,
} from 'react-router-dom';

import {
  GetFavoriteCompanies,
} from '@noitada/axios-config/endpoints/favorites.endpoints';

import {
  APP_SNACKBAR_ACTION,
} from '@noitada/redux-config/reducers/app.store';

import {
  USER_SET_CURRENT_COMPANY,
} from '@noitada/redux-config/reducers/company.store';

import {
  Colors,
  Images,
} from '@noitada/shared/constants';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  CompanyOpenHourText,
} from '@noitada/shared/utils/company.utils';

import CardProfile from '../../../components/Cards/CardCompany/CardProfile';
import EmptyContent from '../../../components/Empty';
import LoadingScreen from '../../../components/Loadings/LoadingScreen';

import NameRoutes from '../../../navigation/names';

import {
  ProfileFavoritesScrollCardsContent,
} from './styled';



const TabsFavoritesProfiles: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { t: translate } = useTranslation();


  const [favoriteResponse, setFavoriteResponse] = useState<Array<any>>([]);

  const [loading, setLoading] = useState<boolean>(false);
  // const [refreshing, setRefreshing] = useState<boolean>(false);



  function showSnackBarProps(snackColor: string, snackText: string) {
    dispatch({
      type: APP_SNACKBAR_ACTION,
      payload: {
        visible: true,
        color: snackColor,
        text: snackText,
      },
    });
  }


  const getFavoritesProfilesFunction = async () => {
    try {
      setLoading(true);

      const response = await GetFavoriteCompanies();

      if (response) {
        setFavoriteResponse(response);
      }
    }
    catch (error: any) {
      console.error(error);

      const message = error?.hasError && error?.message && typeof error?.message === 'string'
        ? error?.message
        : translate(TranslateConfig.ERROR_HAD);
      showSnackBarProps(Colors.danger, message);
    }
    finally {
      setLoading(false);
    }
  };


  function renderItem(item: any, index: number) {
    return (

      <CardProfile
        key={index}
        companyId={item?.id}
        image={item?.picture}
        name={item?.name}
        username={item?.company_username}
        verified={item?.verified}
        type={item?.type}
        district={item?.address?.district}
        open={CompanyOpenHourText(item) || translate(TranslateConfig.CLOSED)}
        // rating={item?.rating}
        params={item?.company_params}

        onPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.DetailsCompanyScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        menuDisable={item?.modules?.menuSheet}
        menuPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.MenuSheetScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        eventDisable={item?.modules?.events}
        eventPress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.AllCompanyEventsScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        reserveDisable={item?.modules?.reserves}
        reservePress={() => {
          dispatch({ type: USER_SET_CURRENT_COMPANY, payload: item });
          history.push({
            pathname: NameRoutes.CompanyReservesScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        waitListsDisable={item?.modules?.waitLists}
        waitListsPress={() => {
          history.push({
            pathname: NameRoutes.CompanyWaitListsScreen.replace(
              ':company_username',
              item?.company_username,
            ),
          });
        }}

        favoritePress={() => {
          getFavoritesProfilesFunction();
        }}
      />

    );
  }


  function renderLoading() {
    return (

      <LoadingScreen
        type={'SCREEN'}
        color={Colors.primary}
      />

    );
  }


  function listEmptyComponent() {
    return (

      <EmptyContent
        image={Images.noFavoritesCompanies}
        title={translate(TranslateConfig.EMPTY_FAVORITES)}
      />

    );
  }



  useEffect(() => {
    getFavoritesProfilesFunction();
  }, []);



  return (

    <ProfileFavoritesScrollCardsContent
      loading={loading}
      data={favoriteResponse}
      renderItem={renderItem}
      listLoadingComponent={renderLoading()}
      listEmptyComponent={listEmptyComponent()}
    />

  );
};



export default TabsFavoritesProfiles;
