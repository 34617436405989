import {
  BadgeView,
  BadgeText,
} from './Badge';



export default {
  View: BadgeView,
  Text: BadgeText,
};
