import React from 'react';

import {
  useTranslation,
} from 'react-i18next';

import {
  Colors,
  Images,
  Sizes,
} from '@noitada/shared/constants';

import {
  EWaitListsStatus,
} from '@noitada/shared/enums';

import {
  TranslateConfig,
} from '@noitada/shared/translations';

import {
  validateString,
} from '@noitada/shared/utils/string.utils';

import {
  IconCalendarHour,
  IconText,
  IconVerified,
  IconWarning,
} from '../../../Icons';

import {
  CardWaitListsContainer,
  CardWaitListsDataView,
  CardWaitListsPositionView,
  CardWaitListsPositionText,
  CheckInAvatarContent,
  CardWaitListsImage,
  CardWaitListsInfoView,
  CardWaitListsHeaderTopView,
  CheckInInfoContent,
  CheckInSuperPartnerText,
  CheckInUsernameContent,
  CardWaitListsName,
  CardWaitListsDescription,
  CheckInVerifiedIcon,
  CardWaitListsPriorityView,
  CardWaitListsStatusText,
  CardWaitListsPriorityInfo,
  CardWaitListsPriorityTitle,
  CardWaitListsCountsView,
  CardWaitListsCountsChip,
  CardWaitListsCountsTitle,
} from './styled';



export interface IProps {
  position?: number;

  image?: string;
  name?: string;
  description?: string;
  partner?: boolean;
  verified?: boolean;
  time?: string;
  observations?: string;
  priority?: boolean;
  status?: string;

  onPress?: any;
}



const CardWaitListsCompanyActive: React.FC<IProps> = (props: IProps) => {
  const { t: translate } = useTranslation();



  function renderBackgroundPosition() {
    if (!props.position) {
      return Colors.disabled;
    }

    if (props.position === 1) {
      return Colors.primary;
    }

    if (props.position === 2) {
      return Colors.sketch;
    }

    if (props.position === 3) {
      return Colors.attentionDark;
    }

    if (props.position > 3) {
      return Colors.primaryDark;
    }

    return Colors.disabled;
  }


  function renderStatusText() {
    const propsStatus = props.status;

    if (!propsStatus) {
      return '-';
    }
    switch (propsStatus) {
      case EWaitListsStatus.WAITING:
        return translate(TranslateConfig.WAITING);

      case EWaitListsStatus.CANCELED:
      case EWaitListsStatus.USER_CANCELED:
        return translate(TranslateConfig.CANCELED);

      case EWaitListsStatus.FINISHED:
        return translate(TranslateConfig.FINISHED);

      default:
        return '-';
    }
  }


  function renderStatusColor() {
    const propsStatus = props.status;

    if (!propsStatus) {
      return '-';
    }
    switch (propsStatus) {
      case EWaitListsStatus.WAITING:
        return Colors.attentionDark;

      case EWaitListsStatus.CANCELED:
      case EWaitListsStatus.USER_CANCELED:
        return Colors.danger;

      case EWaitListsStatus.FINISHED:
        return Colors.accept;

      default:
        return '-';
    }
  }



  return (

    <CardWaitListsContainer
      backgroundColor={Colors.cardBackground}
      borderRadius={Sizes.cardRadius}
      onPress={() => {
        if (props.onPress) {
          props.onPress();
        }
      }}>

      <CardWaitListsDataView>

        <CardWaitListsPositionView
          backgroundColor={renderBackgroundPosition()}>

          <CardWaitListsPositionText>
            {props.position || '-'}
          </CardWaitListsPositionText>

        </CardWaitListsPositionView>



        <CheckInAvatarContent>
          <CardWaitListsImage
            alt={'ImageAvatarUser'}
            src={validateString(props.image)
              ? props.image
              : Images.placeholderAvatarUser
            }
          />

          <CheckInSuperPartnerText>
            {props.partner
              ? translate(TranslateConfig.SUPER_PARTNER)
              : '-'
            }
          </CheckInSuperPartnerText>

        </CheckInAvatarContent>



        <CardWaitListsInfoView>

          <CardWaitListsHeaderTopView>

            <CheckInInfoContent>

              <CardWaitListsName>
                {props.name || '-'}
              </CardWaitListsName>


              <CheckInUsernameContent>
                <CardWaitListsDescription>
                  {props.description || '-'}
                </CardWaitListsDescription>

                {props.verified && (
                  <CheckInVerifiedIcon>
                    <IconVerified
                      color={Colors.accent}
                      size={14}
                    />
                  </CheckInVerifiedIcon>
                )}
              </CheckInUsernameContent>

            </CheckInInfoContent>



            <CardWaitListsPriorityView>

              <CardWaitListsStatusText
                color={renderStatusColor()}>
                {renderStatusText()}
              </CardWaitListsStatusText>



              {props.priority && (
                <CardWaitListsPriorityInfo>

                  <IconWarning
                    color={Colors.attention}
                    size={20}
                  />


                  <CardWaitListsPriorityTitle>
                    {translate(TranslateConfig.PRIORITY)}
                  </CardWaitListsPriorityTitle>

                </CardWaitListsPriorityInfo>
              )}

            </CardWaitListsPriorityView>

          </CardWaitListsHeaderTopView>



          <CardWaitListsCountsView>

            {/* Tempo */}
            <CardWaitListsCountsChip>
              <IconCalendarHour
                color={Colors.primary}
                size={18}
              />

              <CardWaitListsCountsTitle>
                {props.time || '-'}
              </CardWaitListsCountsTitle>
            </CardWaitListsCountsChip>



            {/* Observações */}
            {props.observations && (
              <CardWaitListsCountsChip>
                <IconText
                  color={Colors.primary}
                  size={16}
                />

                <CardWaitListsCountsTitle>
                  {translate(TranslateConfig.OBSERVATIONS)}
                </CardWaitListsCountsTitle>
              </CardWaitListsCountsChip>
            )}

          </CardWaitListsCountsView>

        </CardWaitListsInfoView>

      </CardWaitListsDataView>

    </CardWaitListsContainer>

  );
};



export default CardWaitListsCompanyActive;
